<template>
  <div id="app">
    <div id="nav">
      <!--<router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>-->
    </div>
    <router-view />
    <BackTop></BackTop>
  </div>
</template>
<script>
import BackTop from "./views/home/layout/backTop.vue";
export default {
  name: "Admin",
  components: {
    BackTop,
  },
  data: function () {
    return {};
  },
  mounted: function () {
    //重新加载回到页面顶部
    // window.addEventListener("beforeunload", (e) => {
    //   window.scroll(0, 0);
    // });
  },
  methods: {},
};
</script>

<style lang="stylus">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align center
  color: #2c3e50;
  margin-top: 0px;
}

html, body, #app {
  height: 100%;
}

html {
  Font-size: 62.5% !important;
}

.page {
  height: 100%;
}
</style>
