const _TUTOR = {
  imageBig: function (){
    $(".swiper-slide").hover(function(){
          $(this).children("a").children(".lpf-image-content").css({"height":"100%",'bottom':"0","top":"auto","transition": "all 0.6s ease 0s"});
      },function(){
          $(this).children("a").children(".lpf-image-content").css({"height":"0",'bottom':"auto","top":"0","transition": "all 0.6s ease 0s"});
      });
  },
  initSwiper: function (Swiper,number) {
    let effect = 1;
    let slidesPerView = number;
    var swiper = new Swiper(".lpf-swiper-container-tutor", {
      loop: true, //开启循环
      speed: 500, //动画时间
      grabCursor: true, //鼠标变成手形状
      centeredSlides: true,
      slidesPerView: slidesPerView, //显示数量
      spaceBetween: 20,
      watchSlidesProgress: true,
      on: {
        setTranslate: function () {
          let slides = this.slides;
          for (var i = 0; i < slides.length; i++) {
            let slide = slides.eq(i);
            let progress = slides[i].progress;
            //slide.html(progress.toFixed(2)); 看清楚progress是怎么变化的
            slide.css({ opacity: "", background: "" });
            slide.transform(""); //清除样式

            if (effect == 1) {
              slide.transform("scale(" + (1 - Math.abs(progress) / 8) + ")");
              slide.css("opacity", 1 - Math.abs(progress) / slidesPerView);
            } else if (effect == 2) {
              slide.css("opacity", 1 - Math.abs(progress) / slidesPerView);
              slide.transform(
                "translate3d(0," + Math.abs(progress) * 20 + "px, 0)"
              );
            } else if (effect == 3) {
              slide.transform("rotate(" + progress * 30 + "deg)");
            } else if (effect == 4) {
              slide.css(
                "background",
                "rgba(" +
                  (255 - Math.abs(progress) * 20) +
                  "," +
                  (127 + progress * 32) +
                  "," +
                  Math.abs(progress) * 64 +
                  ")"
              );
            }
          }
        },
        setTransition: function (transition) {
          for (var i = 0; i < this.slides.length; i++) {
            var slide = this.slides.eq(i);
            slide.transition(transition);
          }
        },
      },
      autoplay: {
        disableOnInteraction: false, //用户操作swiper之后，是否禁止autoplay
      },
      // 如果需要分页器
      pagination: {
        el: ".lpf-swiper-pagination-tutor",
        type: "progressbar",
      },
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  },
}
export { _TUTOR };
