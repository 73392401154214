<template>
<div class="block">
            <div class="lpf-jazz-certificate">
              <div class="row lpf-jazz-certificate-title">
                <div class="col-xs-12 lpf">
                  <div class="lpf-title-left">权威的证书认证</div>
                  <router-link to="/jazz/coach" class="lpf-link-a"
                    >咨询更多 ></router-link
                  >
                </div>
              </div>
              <div class="row lpf-jazz-certificate-content">
                <div class="col-xs-12 lpf">
                  <div
                    class="swiper-container lpf-swiper-container-certificate"
                  >
                    <div class="swiper-wrapper">
                      <div
                        class="swiper-slide lpf-swiper-slide"
                        v-for="(item, index) in lpf_certificates"
                        :key="index"
                      >
                        <router-link
                          class="lpf-link-a"
                          to="http://www.baidu.com"
                        >
                          <img class="lpf-image" :src="item.image_url" />
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <!-- 如果需要导航按钮 -->
                  <div class="swiper-button-prev lpf-swiper-button-prev"></div>
                  <div class="swiper-button-next lpf-swiper-button-next"></div>
                </div>
              </div>
              <div class="row lpf-jazz-certificate-remark">
                <div class="col-xs-12 lpf">
                  <div class="lpf-txt">
                    含金量高·社会认可·唯一编码·全网可查<br />
                    所有学员成绩合格者可获得注册舞蹈教师证书，<br />
                    该证书隶属于中国舞蹈家协会，经国家获批。
                  </div>
                </div>
              </div>
            </div>
          </div>
</template>

<script>
export default {
  name: "lpfCertificate",
  data() {
    return {
      lpf_number: 0, //展示滑动块的个数
      lpf_certificates: [
        {
          name: "严鑫雨",
          dance_type: "中国舞",
          image_url: "	https://www.dansewudao.com/skin/img/wz/wz_cer1.jpg",
        },
        {
          name: "潘婷",
          dance_type: "流行舞",
          image_url: "	https://www.dansewudao.com/skin/img/wz/wz_cer1.jpg",
        },
        {
          name: "沈鑫",
          dance_type: "流行舞",
          image_url: "	https://www.dansewudao.com/skin/img/wz/wz_cer1.jpg",
        },
        {
          name: "诗然",
          dance_type: "流行舞",
          image_url: "	https://www.dansewudao.com/skin/img/wz/wz_cer1.jpg",
        },
        {
          name: "孙艺",
          dance_type: "流行舞",
          image_url: "	https://www.dansewudao.com/skin/img/wz/wz_cer1.jpg",
        },
      ],
    };
  },
  props: {},
  mounted() {},
  methods: {
    initSwiper: function (Swiper,number) {
    let effect = 1;
    let slidesPerView = number;
    var swiper = new Swiper(".swiper-container", {
      loop: true, //开启循环
      // grabCursor: true, //鼠标变成手形状
      slidesPerView: slidesPerView,
      spaceBetween: 0,
	  centeredSlides: true,
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  }
  },
};
</script>

<style lang="scss">
.lpf-jazz-certificate {
  background-color: $brand-background-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-jazz-certificate > .lpf-jazz-certificate-title {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 3rem auto 2.5rem;
  overflow: hidden;
}
.lpf-jazz-certificate > .lpf-jazz-certificate-title > .lpf > .lpf-title-left {
  font-size: 4rem;
  color: $footer-background-color;
  float: left;
  text-align: center;
}
.lpf-jazz-certificate > .lpf-jazz-certificate-title > .lpf > .lpf-link-a {
  font-size: 3rem;
  color: $edu-color-a;
  float: right;
}
.lpf-jazz-certificate > .lpf-jazz-certificate-content {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 0 auto 2.5rem;
  overflow: hidden;
}
.lpf-jazz-certificate
  > .lpf-jazz-certificate-content
  > .lpf
  > .swiper-container {
  height: 44.2rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 5rem;
}
.lpf-swiper-container-certificate > .swiper-wrapper > .lpf-swiper-slide {
  background: $brand-background-color;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
  transform: scale(0.8);
  opacity: 0.7;
}
.lpf-swiper-container-certificate>.swiper-wrapper>.swiper-slide-active,
.lpf-swiper-container-certificate>.swiper-wrapper>.swiper-slide-duplicate-active  {
  transform: scale(1.5) !important;
  opacity: 1 !important;
  z-index: 999;
}
.lpf-swiper-container-tutor
  > .swiper-wrapper
  > .lpf-swiper-slide
  > .lpf-link-a {
  width: 100%;
  height: 100%;
}
.lpf-swiper-container-certificate
  > .swiper-wrapper
  > .lpf-swiper-slide
  > .lpf-link-a
  > .lpf-image {
  width: 100%;
  height: auto;
}
.lpf-jazz-certificate
  > .lpf-jazz-certificate-content
  > .lpf
  > .lpf-swiper-button-prev,
.lpf-jazz-certificate
  > .lpf-jazz-certificate-content
  > .lpf
  > .lpf-swiper-button-next {
  background-color: $background-opacity;
  width: 3rem !important;
  height: 3rem !important;
  border-radius: 1.5rem !important;
}
.lpf-jazz-certificate
  > .lpf-jazz-certificate-content
  > .lpf
  > .lpf-swiper-button-prev:after,
.lpf-jazz-certificate
  > .lpf-jazz-certificate-content
  > .lpf
  > .lpf-swiper-button-next:after {
  font-size: 1.5rem !important;
  color: $sidebar-background-color;
}
.lpf-jazz-certificate > .lpf-jazz-certificate-remark {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 0 auto 2.5rem;
  overflow: hidden;
}
.lpf-jazz-certificate > .lpf-jazz-certificate-remark > .lpf > .lpf-txt {
  color: $edu-color-span;
  font-size: 2.8rem;
  line-height: 5rem;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 4rem;
}
@media (max-width: 870px) {
 .lpf-swiper-container-certificate>.swiper-wrapper>.swiper-slide-active,
.lpf-swiper-container-certificate>.swiper-wrapper>.swiper-slide-duplicate-active  {
  transform: scale(1) !important;
}
}
</style>