<template>
  <div class="lpf-header">
    <div class="row lpf-main-header">
       <div class="wow slideInLeft col-xs-12 col-sm-6 lpf-left">
            <img
              class="lpf-home-logo"
              src="../../../static/imgs/home/logo.png"
            />
          </div>
          <div class="wow slideInRight col-xs-12 col-sm-6 lpf-right">
            <img
              class="lpf-home-logo-right"
              src="../../../static/imgs/home/tel.png"
            />
          </div>
    </div>
  </div>
</template>
<script>
import { _data, _methods } from "../../../common/public";
export default {
  name: "Header",
  props: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped>
.lpf-header{
  width: 100%;
  display: flex;
  justify-content: center;
}
.lpf-main-header {
  width: 100%;
  max-width: 1200px;
}
.lpf-home-logo,.lpf-home-logo-right {
  margin: 1rem 0;
  height: 8rem;
}
.lpf-home-logo-right{
  float: right;
}
.lpf-left,.lpf-right{
  padding: 0;
}
@media (max-width: 768px) {
  .lpf-left,.lpf-right {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 768px) {
  .lpf-home-logo,.lpf-home-logo-right {
    float:auto;
  }
}
</style>
