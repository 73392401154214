<template>
<div class="block">
            <div class="lpf-list-1 lpf-honor-qualification">
              <div class="row lpf-list-1-title lpf-honor-title">
                <div class="wow bounceIn lpf-title">
                  <h1>荣誉资质</h1>
                  <p></p>
                </div>
              </div>
              <div class="row lpf-list-1-content lpf-honor-content">
                <ul class="lpf-ul">
                  <li
                    class="wow bounceInUp lpf-ul-li"
                    v-for="(item, index) in lpf_honor_type"
                    :key="index"
                    :data-wow-delay="index*0.1+'s'"
                  >
                    <router-link class="lpf-link-a" to="">
                      <fr-image
                        class="lpf-image"
                        :src="item.img_url"
                        mode=""
                        loading-ing-img="two-balls"
                        :loading-error-img="
                          require('@/static/imgs/home/loading_error.png')
                        "
                      />
                      <p class="lpf-item-title">{{ item.name }}</p>
                    </router-link>
                  </li>
                  <li class="lpf-honor-more">
                    <router-link class="lpf-link-a" to="">查看更多</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
</template>
<script>
export default {
  name: "HonorQualification",
  data: function () {
    return {
      lpf_honor_type: [
        {
          name: "北京街舞职业学院战略授权机构",
          img_url:
            "https://www.dansewudao.com/uploads/allimg/200701/8-200F11109330-L.jpg",
        },
        {
          name: "CEFA全国星级优秀艺术教育机构",
          img_url:
            "https://www.dansewudao.com/uploads/allimg/190327/3-1Z32G55Z50-L.jpg",
        },
        {
          name: "中国舞蹈家协会5星级指定考级单位",
          img_url:
            "https://www.dansewudao.com/uploads/190426/3-1Z4261GJ2a4.jpg",
        },
      ],
    };
  },
  mounted: function () {
  },
  methods: {
    lpf_window_onresize() {
      let str_honor = ".lpf-honor-content>.lpf-ul>.lpf-ul-li>a>.lpf-image";
      let lpf_image_width_honor = $(str_honor).width();
      let lpf_image_height_honor = (lpf_image_width_honor * 255) / 384;
      $(str_honor).css({ height: lpf_image_height_honor + "px" });
    },
  },
};
</script>
<style lang="scss">
// .lpf-list-1 {
//   background-color: $edu-background-color;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// }
// .lpf-list-1 > .lpf-list-1-title,
// .lpf-list-1 > .lpf-list-1-content {
//   width: 100%;
//   max-width: $body-width-content;
//   text-align: center;
//   overflow: hidden;
// }
// .lpf-list-1 > .lpf-list-1-title {
//   margin-bottom: 1rem;
// }
// .lpf-list-1 > .lpf-list-1-title > .lpf-title > h1 {
//   color: $edu-color-h1;
//   font-size: 4rem;
//   margin-top: 4rem;
// }
.lpf-education-intro > .lpf-education-title > .lpf-title > p {
  color: $edu-color;
  font-size: 1.8rem;
  margin-top: 1rem;
}
.lpf-honor-content > .lpf-ul {
  list-style: none; /* 将默认的列表符号去掉 */
}
.lpf-honor-content > .lpf-ul > .lpf-ul-li {
  float: left; /* 往左浮动 */
  display: block;
  width: 32%;
  margin-right: 2%;
  margin-bottom: 10px;
}
.lpf-honor-content > .lpf-ul > .lpf-honor-more {
  float: left; /* 往左浮动 */
  display: block;
  width: 100%;
  margin-bottom: 2rem;
}
.lpf-honor-content > .lpf-ul > .lpf-honor-more > .lpf-link-a {
  display: block;
  color: $white-color;
  font-size: 2.5rem;
  font-weight: bold;
  background: $sidebar-background-color;
  width: 32rem;
  margin: 0 auto;
  line-height: 6rem;
  border-radius: 3rem;
  text-align: center;
  margin-top: 3rem;
  text-decoration: none;
}
.lpf-honor-content > .lpf-ul > .lpf-ul-li:nth-child(3n) {
  margin-right: 0;
}
.lpf-honor-content > .lpf-ul > .lpf-ul-li > .lpf-link-a > .lpf-image {
  width: 100%;
  height: auto;
  vertical-align: middle;
  overflow: hidden;
}
.lpf-honor-content > .lpf-ul > .lpf-ul-li > .lpf-link-a > .lpf-image > .fr_img_content {
  left: 0 !important;
  top: 0 !important;
}
.lpf-honor-content > .lpf-ul > .lpf-ul-li > .lpf-link-a > .lpf-item-title {
  margin-top: 1.2rem;
  margin-bottom: 1rem;
  font-size: 2.4rem;
  color: $edu-color-span;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lpf-honor-content > .lpf-ul > .lpf-ul-li > .lpf-link-a {
  cursor: pointer;
  text-decoration: none;
}
@media (max-width: 992px) {
  .lpf-honor-content > .lpf-ul > .lpf-ul-li {
    width: 48%;
    margin-right: 2%;
  }
  .lpf-honor-content > .lpf-ul > .lpf-ul-li:nth-child(3n) {
    margin-right: 2%;
  }
  .lpf-honor-content > .lpf-ul > .lpf-ul-li:nth-child(1n) {
    margin-right: 2%;
  }
  .lpf-honor-content > .lpf-ul > .lpf-ul-li:nth-child(2n) {
    margin-right: 0;
  }
  .lpf-honor-content > .lpf-ul > .lpf-honor-more {
    width: 48%;
  }
  .lpf-honor-content > .lpf-ul > .lpf-honor-more > .lpf-link-a {
    cursor: pointer;
  }
}
@media (max-width: 650px) {
  .lpf-honor-content > .lpf-ul > .lpf-ul-li {
    width: 100%;
    margin-right: 2%;
  }
  .lpf-honor-content > .lpf-ul > .lpf-ul-li:nth-child(3n) {
    margin-right: 0;
  }
  .lpf-honor-content > .lpf-ul > .lpf-ul-li:nth-child(2n) {
    margin-right: 0;
  }
  .lpf-honor-content > .lpf-ul > .lpf-ul-li:nth-child(1n) {
    margin-right: 0;
  }
  .lpf-honor-content > .lpf-ul > .lpf-honor-more {
    width: 100%;
  }
  .lpf-honor-content > .lpf-ul > .lpf-honor-more > .lpf-link-a {
    cursor: pointer;
  }
}
@media (max-width: 1254px) {
  .lpf-honor-content {
    padding: 0 2rem;
  }
}
</style>
