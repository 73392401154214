const _JAZZ = {
  lpf_image_bili:1920/725,//图片比例
  lpf_image_bili1:386/252,//图片比例
  lpf_image_bili2:1200/466,//图片比例
  lpf_image_bili3:385/375,//图片比例
  lpf_video_bili:393/262,//视频比例
  lpf_window_onresize: function (paramater) {
    let str = ".lpf-jazz-banner > .lpf-jazz-banner-content>.lpf-link-a>.lpf-image";
    let height = $(str).width()/this.lpf_image_bili;
    $(str).css({ height: height + "px" });
    let str1 = ".lpf-jazz-teach > .lpf-jazz-teach-content>.lpf>.lpf-ul>.lpf-li>.lpf-img>.lpf-image";
    let height1 = $(str1).width()/this.lpf_image_bili1;
    $(str1).css({ height: height1 + "px" });
    let str_v = ".lpf-jazz-video>.lpf-jazz-video-content>.lpf>.lpf-video>.lpf-li>video";
    let height_v = $(str_v).width()/this.lpf_video_bili;
    $(str_v).css({ height: height_v + "px" });
    
    let str2 = ".lpf-jazz-condition > .lpf-jazz-condition-image>.lpf>.lpf-image";
    let height2 = $(str2).width()/this.lpf_image_bili2;
    $(str2).css({ height: height2 + "px" });

    let str3 = ".lpf-jazz-tutor > .lpf-jazz-tutor-content>.lpf>.lpf-ul>.lpf-li>.lpf-image";
    let height3 = $(str3).width()/this.lpf_image_bili3;
    $(str3).css({ height: height3 + "px" });

  },
  lpf_li_hover: function () {
    $(".lpf-jazz-tutor > .lpf-jazz-tutor-content>.lpf>.lpf-ul>.lpf-li").hover(function(){
      $(this).children(".lpf-black").css({"display":"inline"});
      $(this).children(".lpf-txt").css({"display":"inline"});
    },function(){
      $(this).children(".lpf-black").css({"display":"none"});
      $(this).children(".lpf-txt").css({"display":"none"});
    });
  },
  initSwiper: function (Swiper,number) {
    let effect = 1;
    let slidesPerView = number;
    var swiper = new Swiper(".swiper-container", {
      loop: true, //开启循环
      // grabCursor: true, //鼠标变成手形状
      slidesPerView: slidesPerView,
      spaceBetween: 0,
	  centeredSlides: true,
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  }
}
export { _JAZZ }
