<template>
  <div class="page">
    <div class="lpf-content">
      <div class="container-fluid lpf-container-fluid">
        <vHeader></vHeader>
        <vSidebar @lpf_window_onresize="lpf_window_onresize"></vSidebar>
        <div class="lpf-content-main lpf-about">
          <div class="block">
            <div class="lpf-about-info">
              <div class="row lpf-about-info-content">
                <div
                  class="col-md-12 col-lg-7 lpf-about-info-left wow slideInLeft"
                >
                  <h3>蓝风铃舞蹈</h3>
                  <div class="lpf-txt">
                    <p>
                      <span id="lpf-company">蓝风铃舞蹈</span
                      >，全称“单色国际（连锁）舞蹈培训”，中国舞蹈培训行业知名的全国连锁品牌。<span
                        class="lpf-nianxian"
                      ></span
                      >年专注舞蹈艺术教育，培养了一大批舞动人才和艺术工作者。单色舞蹈是“中国舞蹈家协会指定考级单位”，“中国舞蹈家协会5星级考级单位”，同时也是“CEFA中国艺术职业教育学会指定考级考证单位”。
                    </p>
                    <p>
                      旗下设有<span class="lpf-fenguannum"></span
                      >家直营连锁培训场馆（无任何加盟），培训项目涵盖中国舞、流行舞、拉丁舞、东方舞、街舞、瑜伽等，开设的各类课程均按照舞蹈教学标准，由国内外知名的科班出身舞蹈老师亲自执教。
                    </p>
                    <p>
                      课程包括三大板块：职业教练班培训、成人舞蹈培训、少儿舞蹈培训。每个版块课程均拥有原创的知识产权和独特的授课模式，并与全国各高校和电视台开展长期战略合作，每年有数万名舞蹈学生从单色舞蹈毕业。单色舞蹈人性化的学员管理和教学口碑，成就了单色舞蹈在中国舞蹈教育行业的标杆地位。
                    </p>
                  </div>
                </div>
                <div
                  class="
                    col-md-12 col-lg-5
                    lpf-about-info-right
                    wow
                    slideInRight
                  "
                >
                  <video
                    class="lpf-video"
                    preload="none"
                    width="100%"
                    controls="controls"
                    poster="https://m.dansewudao.com/uploads/allimg/200119/8-2001191435560-L.jpg"
                    src="https://p.dansewudao.com/baa8d499cfc64d8f82fa9ee93ca1014b/8c18aba286b14e0a8b70a997e808e51c-ddf5320205a04fed18ae8ce43ac76f49-sd.mp4"
                  ></video>
                </div>
              </div>
            </div>
          </div>
          <div class="block">
            <div class="lpf-about-changguan">
              <div class="row lpf-about-changguan-content">
                <div class="lpf-main row">
                  <div class="lpf-main-item col-xs-12 col-sm-6 col-lg-4">
                    <p class="lpf_fenguannum">28</p>
                    <span>家</span>
                    <p class="lpf-title">直营连锁舞蹈培训场馆</p>
                  </div>
                  <div class="lpf-main-item col-xs-12 col-sm-6 col-lg-4">
                    <p class="lpf_fenguannum">28</p>
                    <span>家</span>
                    <p class="lpf-title">直营连锁舞蹈培训场馆</p>
                  </div>
                  <div class="lpf-main-item col-xs-12 col-sm-6 col-lg-4">
                    <p class="lpf_fenguannum">28</p>
                    <span>家</span>
                    <p class="lpf-title">直营连锁舞蹈培训场馆</p>
                  </div>
                  <div class="lpf-main-item col-xs-12 col-sm-6 col-lg-4">
                    <p class="lpf_fenguannum">28</p>
                    <span>家</span>
                    <p class="lpf-title">直营连锁舞蹈培训场馆</p>
                  </div>
                  <div class="lpf-main-item col-xs-12 col-sm-6 col-lg-4">
                    <p class="lpf_fenguannum">28</p>
                    <span>家</span>
                    <p class="lpf-title">直营连锁舞蹈培训场馆</p>
                  </div>
                  <div class="lpf-main-item col-xs-12 col-sm-6 col-lg-4">
                    <p class="lpf_fenguannum">28</p>
                    <span>家</span>
                    <p class="lpf-title">直营连锁舞蹈培训场馆</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="block">
            <div class="lpf-about-company-whts">
              <div class="row lpf-about-qywh-title">
                <div class="col-xs-12 lpf-about-company-title">
                <div class="lpf-title">企业文化</div>
              </div>
              </div>
              <div class="row lpf-about-qywh">
                <div class="lpf-about-qywh-content row">
                  <div
                    class="
                      lpf-about-qywh-content-item
                      col-xs-12 col-sm-6 col-lg-4
                    "
                  >
                    <div class="item">
                      <fr-image
                        class="lpf-image"
                        src="https://www.dansewudao.com/skin/img/about/danse/wh1.jpg"
                        mode=""
                        loading-ing-img="two-balls"
                        :loading-error-img="
                          require('@/static/imgs/home/loading_error.png')
                        "
                      />
                      <span
                        ><fr-image
                          class="lpf-image"
                          :src="require('@/static/imgs/home/about/wh_num1.jpg')"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                      /></span>
                      <font>使命</font>
                      <i class="lpf-line"></i>
                      <p>让更多中国人享受舞蹈</p>
                    </div>
                  </div>
                  <div
                    class="
                      lpf-about-qywh-content-item
                      col-xs-12 col-sm-6 col-lg-4
                    "
                  >
                    <div class="item">
                      <fr-image
                        class="lpf-image"
                        src="https://www.dansewudao.com/skin/img/about/danse/wh3.jpg"
                        mode=""
                        loading-ing-img="two-balls"
                        :loading-error-img="
                          require('@/static/imgs/home/loading_error.png')
                        "
                      />
                      <span
                        ><fr-image
                          class="lpf-image"
                          :src="require('@/static/imgs/home/about/wh_num2.jpg')"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                      /></span>
                      <font>愿景</font>
                      <i class="lpf-line"></i>
                      <p>打造中国舞蹈文化名片<br />成为世界知名舞蹈公司</p>
                    </div>
                  </div>
                  <div
                    class="
                      lpf-about-qywh-content-item
                      col-xs-12 col-sm-6 col-lg-4
                    "
                  >
                    <div class="item">
                      <fr-image
                        class="lpf-image"
                        src="https://www.dansewudao.com/skin/img/about/danse/wh2.jpg"
                        mode=""
                        loading-ing-img="two-balls"
                        :loading-error-img="
                          require('@/static/imgs/home/loading_error.png')
                        "
                      />
                      <span
                        ><fr-image
                          class="lpf-image"
                          :src="require('@/static/imgs/home/about/wh_num3.jpg')"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                      /></span>
                      <font>价值观</font>
                      <i class="lpf-line"></i>
                      <p>
                        1.
                        学员第一、主动服务、解决学员遇到的问题，帮助学员成长。<br />
                        2. 诚实正直、信守承诺。<br />
                        3. 虚心学习、放低姿态、永不自满、每天进步一点。<br />
                        4. 快乐、友爱、互助、共享共担。<br />
                        5. 积极努力、乐观向上、共同创业、实现梦想。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="block">
           <div class="lpf-about-course">
              <div class="row lpf-about-course-content">
              <div class="col-xs-12 lpf-about-course-title">
                <div class="lpf-title">课程特色</div>
              </div>
              <ul class="col-xs-12 lpf-ul">
                <li>
                  <div class="lpf-top">
                    <div class="lpf-left">
                      <fr-image
                          class="lpf-image"
                          :src="require('@/static/imgs/home/about/ts1.jpg')"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                      />
                    </div>
                    <div class="lpf-right">
                     <div class="lpf-content">
                        <h3>专属服务</h3>
                      <i></i>
                     </div>
                    </div>
                  </div>
                  <div class="lpf-txt">
                    教练班的每一位学员都配有“入学前预习课”、“学习手册”、“专属一对一舞蹈顾问”
                  </div>
                </li>
                <li>
                  <div class="lpf-top">
                    <div class="lpf-left">
                      <fr-image
                          class="lpf-image"
                          :src="require('@/static/imgs/home/about/ts2.jpg')"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                      />
                    </div>
                    <div class="lpf-right">
                      <div class="lpf-content">
                        <h3>学院式管理</h3>
                      <i></i>
                      </div>
                    </div>
                  </div>
                  <div class="lpf-txt">
                    统一开班、统一结业、统一教学进度；每一阶段实行学习考核，邀请资深评委老师进行考核打分，从不同角度给学员更直观的点评和建议
                  </div>
                </li>
                <li>
                  <div class="lpf-top">
                    <div class="lpf-left">
                      <fr-image
                          class="lpf-image"
                          :src="require('@/static/imgs/home/about/ts3.jpg')"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                      />
                    </div>
                    <div class="lpf-right">
                      <div class="lpf-content">
                        <h3>舞蹈MV</h3>
                      <i></i>
                      </div>
                    </div>
                  </div>
                  <div class="lpf-txt">
                    毕业由老师自主编舞、专业摄影团队为学员拍摄“毕业舞蹈MV”
                  </div>
                </li>
                <li>
                  <div class="lpf-top">
                    <div class="lpf-left">
                      <fr-image
                          class="lpf-image"
                          :src="require('@/static/imgs/home/about/ts4.jpg')"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                      />
                    </div>
                    <div class="lpf-right">
                      <div class="lpf-content">
                        <h3>全国连锁</h3>
                      <i></i>
                      </div>
                    </div>
                  </div>
                  <div class="lpf-txt">座落各大商圈，地理位置方便，随到随学</div>
                </li>
                <li>
                  <div class="lpf-top">
                    <div class="lpf-left">
                      <fr-image
                          class="lpf-image"
                          :src="require('@/static/imgs/home/about/ts5.jpg')"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                      />
                    </div>
                    <div class="lpf-right">
                     <div class="lpf-content">
                        <h3>安排住宿</h3>
                      <i></i>
                     </div>
                    </div>
                  </div>
                  <div class="lpf-txt">
                    面向“全国招生安排住宿”，学员可自行选择单色舞蹈提供的住宿服务
                  </div>
                </li>
                <li>
                  <div class="lpf-top">
                    <div class="lpf-left">
                      <fr-image
                          class="lpf-image"
                          :src="require('@/static/imgs/home/about/ts6.jpg')"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                      />
                    </div>
                    <div class="lpf-right">
                      <div class="lpf-content">
                        <h3>科班老师任教</h3>
                      <i></i>
                      </div>
                    </div>
                  </div>
                  <div class="lpf-txt">
                    单色舞蹈是中国舞蹈家协会指定五星级考级单位和CEFA中国艺术职业教育学会考级考证单位，以上两个考试单位单色舞蹈提供考点，是否报名参加考试学员可自行选择
                  </div>
                </li>
              </ul>
            </div>
           </div>
          </div>
        </div>
        <vFooter></vFooter>
      </div>
    </div>
  </div>
</template>
<script>
import { vHeader, vFooter, vSidebar } from "../layout/index.js";
import { _ABOUT } from "../src/js/about/index.js";
export default {
  name: "ABOUT",
  components: {
    vHeader,
    vFooter,
    vSidebar,
  },
  data: function () {
    return {};
  },
  created() {
    this._CONFIG.colorNavc = "color";
  },
  mounted: function () {
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new this.WOW({});
      wow.init();
    });
  },
  methods: {
    lpf_window_onresize(paramater) {
      _ABOUT.lpf_window_onresize(paramater);
    },
  },
};
</script>
<style lang="scss">
.lpf-about-info {
  // margin-top: 4rem !important;
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-about-info > .lpf-about-info-content {
  width: 100%;
  max-width: $body-width-content3;
  height: auto;
  margin: 3rem auto;
  overflow: hidden;
}
.lpf-about-info-content > .lpf-about-info-left > h3 {
  color: $edu-color-h4;
  font-size: 2.4rem;
  margin-top: 1rem;
}
.lpf-about-info-content > .lpf-about-info-left > .lpf-txt {
  margin-top: 2rem;
}
.lpf-about-info-content > .lpf-about-info-left > .lpf-txt > p {
  color: $edu-color-span1;
  line-height: 3rem;
  font-size: 1.3rem;
  text-indent: 2.7rem;
}
.lpf-about-info-content
  > .lpf-about-info-left
  > .lpf-txt
  > p
  > .lpf-nianxian::before {
  content: "16";
}
.lpf-about-info-content
  > .lpf-about-info-left
  > .lpf-txt
  > p
  > .lpf-fenguannum::before {
  content: "12";
}
.lpf-about-info-content > .lpf-about-info-right > .lpf-video {
  width: 100%;
  height: 100%;
  object-fit: fill;
  outline: none;
}
.lpf-about-changguan {
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-about-changguan > .lpf-about-changguan-content {
  width: 100%;
  margin: 0 0 2rem 0;
  height: auto;
  overflow: hidden;
  background: url(https://www.dansewudao.com/skin/img/about/danse/js_bg_2.jpg)
    center center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lpf-about-changguan-content > .lpf-main {
  width: 100%;
  max-width: $body-width-content3;
}
.lpf-about-changguan-content > .lpf-main > .lpf-main-item {
  margin: 4rem 0;
  text-align: center;
  font-size: 2.4rem;
  color: $white-color;
}
.lpf-about-changguan-content > .lpf-main > .lpf-main-item > .lpf_fenguannum {
  font-size: 12rem;
  line-height: 9rem;
  display: inline-block;
}
.lpf-about-changguan-content > .lpf-main > .lpf-main-item > span {
  display: inline-block;
  width: 4rem;
  height: 4rem;
  background: #fff;
  border-radius: 50%;
  color: #000;
  font-size: 3rem;
  vertical-align: text-bottom;
  line-height: 4rem;
}
.lpf-about-changguan-content > .lpf-main > .lpf-main-item > .lpf-title {
  margin: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lpf-about-company-whts {
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-about-company-whts > .lpf-about-qywh-title {
  width: 100%;

  margin: 0 0 2rem 0;
  height: auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lpf-about-company-whts > .lpf-about-qywh-title>.lpf-about-company-title>.lpf-title{
    margin: 3rem auto 0;
    width: 17.5rem;
    height: 6rem;
    line-height: 6rem;
    border: 0.3rem solid $sidebar-background-color;
    text-align: center;
    font-size: 3.6rem;
    font-weight: bold;
    color: $edu-color-h1;
    position: relative;
}
.lpf-about-company-whts > .lpf-about-qywh-title>.lpf-about-company-title>.lpf-title:before {
    content: "";
    width: 10rem;
    height: 0.3rem;
    background: $sidebar-background-color;
    display: block;
    position: absolute;
    left: -10.3rem;
    top: 2.8rem;
}
.lpf-about-company-whts > .lpf-about-qywh-title>.lpf-about-company-title>.lpf-title::after {
    content: "";
    width: 10rem;
    height: 0.3rem;
    background: $sidebar-background-color;
    display: block;
    position: absolute;
    right: -10.3rem;
    top: 2.8rem;
}
.lpf-about-company-whts > .lpf-about-qywh {
  width: 100%;

  margin: 0 0 2rem 0;
  height: auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lpf-about-company-whts > .lpf-about-qywh > .lpf-about-qywh-content {
  width: 100%;
  max-width: $body-width-content3;
}
.lpf-about-company-whts
  > .lpf-about-qywh
  > .lpf-about-qywh-content
  > .lpf-about-qywh-content-item {
  text-align: center;
}
.lpf-about-company-whts
  > .lpf-about-qywh
  > .lpf-about-qywh-content
  > .lpf-about-qywh-content-item
  > .item {
  background: $edu-background-color1;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  min-height: 61.5rem;
}
.lpf-about-company-whts
  > .lpf-about-qywh
  > .lpf-about-qywh-content
  > .lpf-about-qywh-content-item:nth-child(2)
  > .item {
  background: $sidebar-background-color;
  color: $white-color;
}
.lpf-about-company-whts
  > .lpf-about-qywh
  > .lpf-about-qywh-content
  > .lpf-about-qywh-content-item
  > .item
  > .lpf-image {
  width: 100%;
}
.lpf-about-company-whts
  > .lpf-about-qywh
  > .lpf-about-qywh-content
  > .lpf-about-qywh-content-item
  > .item
  > .lpf-image
  > img {
  left: 0;
}
.lpf-about-company-whts
  > .lpf-about-qywh
  > .lpf-about-qywh-content
  > .lpf-about-qywh-content-item
  > .item
  > span {
  display: flex;
  justify-content: center;
  line-height: 6rem;
  font-size: 4rem;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}
.lpf-about-company-whts
  > .lpf-about-qywh
  > .lpf-about-qywh-content
  > .lpf-about-qywh-content-item
  > .item
  > span
  > .lpf-image {
  width: 3.1rem;
  height: 5rem;
}
.lpf-about-company-whts
  > .lpf-about-qywh
  > .lpf-about-qywh-content
  > .lpf-about-qywh-content-item
  > .item
  > span
  > .lpf-image
  > .fr_img_content {
  left: 0;
}
.lpf-about-company-whts
  > .lpf-about-qywh
  > .lpf-about-qywh-content
  > .lpf-about-qywh-content-item
  > .item
  > font {
  display: block;
  font-size: 2.6rem;
  text-align: center;
  line-height: 2.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 1rem;
}
.lpf-about-company-whts
  > .lpf-about-qywh
  > .lpf-about-qywh-content
  > .lpf-about-qywh-content-item
  > .item
  > .lpf-line {
  display: block;
  width: 7rem;
  background: $black-color;
  height: 0.2rem;
  margin: 3rem auto 2rem;
}
.lpf-about-company-whts
  > .lpf-about-qywh
  > .lpf-about-qywh-content
  > .lpf-about-qywh-content-item:nth-child(2)
  > .item
  > .lpf-line {
  background: $white-color;
}
.lpf-about-company-whts
  > .lpf-about-qywh
  > .lpf-about-qywh-content
  > .lpf-about-qywh-content-item
  > .item
  > p {
  margin: 0 1rem;
  text-align: center;
  color: $edu-color-h1;
  line-height: 3rem;
  font-size: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.lpf-about-company-whts
  > .lpf-about-qywh
  > .lpf-about-qywh-content
  > .lpf-about-qywh-content-item:nth-child(2)
  > .item
  > p {
  color: $white-color;
}
.lpf-about-company-whts
  > .lpf-about-qywh
  > .lpf-about-qywh-content
  > .lpf-about-qywh-content-item:nth-child(3)
  > .item
  > p {
  width: 90%;
  text-align: left;
  font-size: 1.4rem;
  line-height: 2.2rem;
  -webkit-line-clamp: 7;
}
.lpf-about-course {
  // margin-top: 4rem !important;
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-about-course > .lpf-about-course-content {
  width: 100%;
  max-width: $body-width-content3;
  height: auto;
  margin: 1rem auto 0;
  overflow: hidden;
}
.lpf-about-course > .lpf-about-course-content>.lpf-about-course-title>.lpf-title{
    margin: 3rem auto 0;
    width: 17.5rem;
    height: 6rem;
    line-height: 6rem;
    border: 0.3rem solid $sidebar-background-color;
    text-align: center;
    font-size: 3.6rem;
    font-weight: bold;
    color: $edu-color-h1;
    position: relative;
}
.lpf-about-course > .lpf-about-course-content>.lpf-about-course-title>.lpf-title:before {
    content: "";
    width: 10rem;
    height: 0.3rem;
    background: $sidebar-background-color;
    display: block;
    position: absolute;
    left: -10.3rem;
    top: 2.8rem;
}
.lpf-about-course > .lpf-about-course-content>.lpf-about-course-title>.lpf-title:after {
    content: "";
    width: 10rem;
    height: 0.3rem;
    background: $sidebar-background-color;
    display: block;
    position: absolute;
    right: -10.3rem;
    top: 2.8rem;
}
.lpf-about-course > .lpf-about-course-content>.lpf-ul{
margin: 5rem auto 4rem;
}
.lpf-about-course > .lpf-about-course-content>.lpf-ul>li{
    float: left;
    width: 31%!important;
    margin-bottom: 3rem;
    margin-right: 3.5%!important;
    position: relative;
}
.lpf-about-course > .lpf-about-course-content>.lpf-ul>li:nth-child(3n){
  margin-right: 0!important;
}
.lpf-about-course > .lpf-about-course-content>.lpf-ul>li>.lpf-top{
    overflow: hidden;
    display: flex;
    align-items: center;
}
.lpf-about-course > .lpf-about-course-content>.lpf-ul>li>.lpf-top>.lpf-left{

}
.lpf-about-course > .lpf-about-course-content>.lpf-ul>li>.lpf-top>.lpf-left>.lpf-image{
  width: 13rem;
  height: 13rem;
}
.lpf-about-course > .lpf-about-course-content>.lpf-ul>li>.lpf-top>.lpf-right{
        margin: 0 0 0 2rem;
        display: flex;
        justify-content: center;
}
.lpf-about-course > .lpf-about-course-content>.lpf-ul>li>.lpf-top>.lpf-right>.lpf-content{

}
.lpf-about-course > .lpf-about-course-content>.lpf-ul>li>.lpf-top>.lpf-right>.lpf-content>h3{
    margin: 0!important;
    padding: 0!important;
    list-style: none;
    font-weight: bold;
        font-size: 2rem;
}
.lpf-about-course > .lpf-about-course-content>.lpf-ul>li>.lpf-top>.lpf-right>.lpf-content>i{
display: block;
    width: 100%;
    height: 0.1rem;
    background: $black-color;
    margin: 1rem auto;
}
.lpf-about-course > .lpf-about-course-content>.lpf-ul>li>.lpf-txt{
color: $edu-color-h1;
    line-height: 20px;
    font-size: 1.4rem;
    padding: 0 1rem;
    margin-top: 1rem;
}
@media (min-width: 1200px) {
.lpf-about-company-whts
  > .lpf-about-qywh
  > .lpf-about-qywh-content
  > .lpf-about-qywh-content-item
  > .item {
  min-height: 61.5rem!important;
}
}
@media (min-width: 976px) {
.lpf-about-company-whts
  > .lpf-about-qywh
  > .lpf-about-qywh-content
  > .lpf-about-qywh-content-item
  > .item {
  min-height: 66.1rem;
}
}
@media (max-width: 925px) {
.lpf-about-course > .lpf-about-course-content>.lpf-ul>li{
    width: 48%!important;
    margin-right: 4%!important;
}
.lpf-about-course > .lpf-about-course-content>.lpf-ul>li:nth-child(3n){
  margin-right: 4%!important;
}
.lpf-about-course > .lpf-about-course-content>.lpf-ul>li:nth-child(2n){
  margin-right: 0!important;
}

}
@media (max-width: 620px) {
.lpf-about-course > .lpf-about-course-content>.lpf-ul>li{
    width: 100%!important;
    margin-right: 0%!important;
}
.lpf-about-course > .lpf-about-course-content>.lpf-ul>li:nth-child(3n){
  margin-right: 0%!important;
}
.lpf-about-course > .lpf-about-course-content>.lpf-ul>li:nth-child(2n){
  margin-right: 0!important;
}

}
</style>
