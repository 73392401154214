<template>
  <div class="login-main">
    <div class="login-header">
      <img src="../../static/imgs/admin/logo.png" />
    </div>
    <div class="slogan">后台管理系统</div>
    <form action="" class="login-form">
      <ul>
        <li class="from-group">
          <div class="controls">
            <input
              class="tinput"
              type="text"
              name="username"
              value
              placeholder="用户名"
            />
          </div>
        </li>
        <li class="from-group">
          <div class="controls">
            <input
              class="tinput"
              type="password"
              name="username"
              value
              placeholder="密码"
            />
          </div>
        </li>
        <li class="from-group">
          <div class="controls code-input-box">
            <input
              class="tinput"
              id="yzm"
              type="text"
              name="username"
              value
              placeholder="验证码"
            />
          </div>
          <span class="code-img-box">
            <div id="v_container" class="code-img" title="点击切换"></div>
          </span>
        </li>
        <li class="from-group">
          <div class="controls">
            <button class="login" type="button" @click="login">登录</button>
          </div>
        </li>
        <li class="from-group">
          <div class="controls">
            <button class="regist" type="button" @click="regist">没有账号？去登录</button>
          </div>
        </li>
      </ul>
    </form>
  </div>
</template>
<script>
import { GVerify } from "../../utils/verifyCode";
export default {
  name: "Login",
  data: function () {
    return {
      verifyCode: "",
    };
  },
  mounted: function () {
    let that = this;
    this.$nextTick(function () {
      that.verifyCode = new GVerify("v_container");
    });
  },
  created() {

  },
  methods: {
    // 登录
    login() {
      this.verifyCodeYZ()
    },
    // 注册
    regist(){

    },
    // 验证验证码
    verifyCodeYZ() {
      var that = this;
      let code = $("#yzm").val();
      // 获取验证码
      var verifyFlag = this.verifyCode.validate(code);
      if (!verifyFlag) {
        this.layer.msg("验证码错误",{icon:5});
        return;
      }
      this.$router.push('/admin');
    },
  },
};
</script>
<style lang="scss">
.login-main {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: url("../../static/imgs/admin/background.jpg") no-repeat; // url('../../static/imgs/background.jpg') center 0 no-repeat
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.login-header img {
  width: 7.5rem;
  height: 7.5rem;
  margin-bottom: 1rem;
}
.slogan {
  color: white;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}
ul {
  list-style-type: none;
  padding-left: 0;
}
.from-group {
  width: 38rem;
  // background-color: red;
  height: 4.6rem;
  margin-bottom: 2rem;
  display: flex;
}
.controls {
  width: 100%;
  height: 100%;
  display: flex;
}
.code-input-box {
  flex: 1;
}
.code-img-box {
  width: 11.5rem;
  height: 100%;
  margin-left: 0.6rem;
  display: flex;
}
.code-img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.tinput {
  width: 100%;
  height: 100%;
  padding: 0 1rem;
  border: white solid 0.1rem;
}
.login,
.regist {
  width: 100%;
  height: 100%;
  padding: 0;
  border-width: 0.1rem;
  font-size: 1.5rem;
  cursor: pointer;
}
.login {
  background-color: #eb7351;
  border-color: #eb7351;
  color: white;
}
.regist {
  background-color: #ddd;
  border-color: #ddd;
}
</style>
