<template>
  <div class="page">
    <div class="lpf-content">
      <div class="container-fluid lpf-container-fluid">
        <vHeader></vHeader>
        <vSidebar @lpf_window_onresize="lpf_window_onresize"></vSidebar>
        <div class="lpf-content-main lpf-about">
          <div class="block">
            <div class="lpf-about-info">
              <div class="row lpf-about-info-content">
                <div class="col-md-12 lpf-about-info-content-title">
                  <span class="iconfont">&#xe616;</span>
                  <p>联系我们</p>
                  <div class="lpf-botton-line"></div>
                </div>
                <div class="col-md-12 lpf-about-info-content-item">
                  <div class="row lpf-list-banner-content">
                    <div class="lpf-banner-left wow slideInLeft">
                      <router-link class="lpf-link-a" to="">
                        <div class="lpf-map" id="allmap"></div>
                      </router-link>
                    </div>
                    <div class="lpf-banner-right wow slideInRight">
                      <router-link class="lpf-link-a" to="">
                        <fr-image
                          class="lpf-image lpf-image1"
                          src="	https://www.dansewudao.com/skin/img/about/contact/lxds.jpg"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                        />
                      </router-link>
                      <router-link class="lpf-link-a" to="">
                        <div class="lpf-contact">
                          总部办公地址：河南省新乡市延津县三里庄桥<br />
                          招生热线：400-027-5828<br />
                          客服部：027-65668615<br />
                          行政部：027-65668745<br />
                          人事部：dansewudao@126.com<br />
                          广告合作：15717185070<br />
                          投诉与建议：dansewudao@126.com<br />
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <vFooter></vFooter>
      </div>
    </div>
  </div>
</template>
<script>
import { vHeader, vFooter, vSidebar } from "../layout/index.js";
import { _CONTACT } from "../src/js/about/contact.js";
export default {
  name: "QUALIFICATIONS",
  components: {
    vHeader,
    vFooter,
    vSidebar,
  },
  data: function () {
    return {
      position_NKYYGS: {
        Name: "蓝风铃舞蹈培训学校",
        address: "新乡市延津县建设街三里河桥南20米",
        phoneNumber: "(0373)88888888",
        bdLNG: 114.210567,
        bdLAT: 35.160341,
      },
      timer: "",
    };
  },
  created() {
    this._CONFIG.colorNavc = "color";
  },
  mounted: function () {
    let that = this;
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new this.WOW({});
      wow.init();
      this.timer = setTimeout(() => {
        that.baiduMap(); //设置延长时间，不然标点不居中
      }, 500);
    });
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods: {
    lpf_window_onresize(paramater) {
      _CONTACT.lpf_window_onresize(paramater);
    },
    baiduMap() {
      var map = new BMap.Map("allmap"); // 创建地图实例
      map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
      map.addControl(new BMap.NavigationControl());
      map.addControl(new BMap.ScaleControl());
      map.addControl(new BMap.OverviewMapControl());
      map.addControl(new BMap.MapTypeControl());
      // map.setMapStyle({ style: 'midnight' }) //地图风格

      map.clearOverlays();
      //将数据遍历
      //自定义信息窗口内容
      var sContent =
        '<div style="width:300px;"><p style="font-size:16px;font-weight:bold;margin-top: 10px;color:#D07852">' +
        this.position_NKYYGS.Name +
        '</p><p style="font-size:13px;margin: 5px 0;">地址：' +
        this.position_NKYYGS.address +
        '</p><p style="font-size:13px;margin: 5px 0;">电话：' +
        this.position_NKYYGS.phoneNumber +
        '</p><p style="font-size:13px;margin: 5px 0;">坐标：' +
        this.position_NKYYGS.bdLNG +
        "," +
        this.position_NKYYGS.bdLAT;
      ("</div>");
      var point = new BMap.Point(
        this.position_NKYYGS.bdLNG,
        this.position_NKYYGS.bdLAT
      ); // 创建点坐标
      var marker = new BMap.Marker(point);
      map.addOverlay(marker);
      map.centerAndZoom(point, 18); // 初始化地图，设置中心点坐标和地图级别
      // //点击图标时候调用信息窗口
      var infoWindow = new BMap.InfoWindow(sContent);
      marker.openInfoWindow(infoWindow);//不用点击标点直接出现
      //下面是点击标点出现的
    //   marker.addEventListener("click", function () {
    //     this.openInfoWindow(infoWindow);
    //   });

    // 处理百度地图未放在标准文档流中，会出现放大或缩小后,中心点偏移(中心点不是在放大前的点)
      // 临时存储地图中心点经度和纬度
      let center_lng = 0;
      let center_lat = 0;
      // 监听地图缩放开始事件 lng表示经度，lat表示纬度
      map.addEventListener("zoomstart", function (e) {
        center_lng = map.getCenter().lng;
        center_lat = map.getCenter().lat;
      });
      // 监听地图缩放结束事件 lng表示经度，lat表示纬度
      map.addEventListener("zoomend", function (e) {
        map.centerAndZoom(
          new BMap.Point(center_lng, center_lat),
          map.getZoom()
        );
      });
    },
  },
};
</script>
<style lang="scss">
.lpf-about-info {
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-about-info > .lpf-about-info-content {
  width: 100%;
  max-width: $body-width-content3;
  height: auto;
  margin: 1rem auto 2rem;
  overflow: hidden;
}
.lpf-about-info > .lpf-about-info-content > .lpf-about-info-content-title {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  // border-bottom: 0.1rem solid $sidebar-background-color;
}
.lpf-about-info
  > .lpf-about-info-content
  > .lpf-about-info-content-title
  > span {
  color: $sidebar-background-color;
  font-size: 2.8rem;
  display: inline;
  vertical-align: middle;
  margin-right: 1rem;
}
.lpf-about-info > .lpf-about-info-content > .lpf-about-info-content-title > p {
  color: $sidebar-background-color;
  font-size: 1.8rem;
  font-weight: bold;
  display: inline;
  vertical-align: middle;
}
.lpf-about-info
  > .lpf-about-info-content
  > .lpf-about-info-content-title
  > .lpf-botton-line {
  width: 100%;
  height: 0.1rem;
  background-color: $sidebar-background-color;
}
.lpf-about-info > .lpf-about-info-content > .lpf-about-info-content-item {
  display: flex;
  justify-content: center;
}
.lpf-about-info
  > .lpf-about-info-content
  > .lpf-about-info-content-item
  > .lpf-list-banner-content {
  width: 100%;
}
.lpf-about-info
  > .lpf-about-info-content
  > .lpf-about-info-content-item
  > .lpf-list-banner-content
  > .lpf-banner-left {
  padding-left: 0 !important;
}
.lpf-about-info
  > .lpf-about-info-content
  > .lpf-about-info-content-item
  > .lpf-list-banner-content
  > .lpf-banner-left
  > a
  > .lpf-map {
  background-color: $sidebar-background-color;
}
.lpf-about-info
  > .lpf-about-info-content
  > .lpf-about-info-content-item
  > .lpf-list-banner-content
  > .lpf-banner-right {
  padding-right: 0 !important;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}
.lpf-about-info
  > .lpf-about-info-content
  > .lpf-about-info-content-item
  > .lpf-list-banner-content
  > .lpf-banner-right
  > a {
  width: 100%;
  text-decoration: none;
}
.lpf-about-info
  > .lpf-about-info-content
  > .lpf-about-info-content-item
  > .lpf-list-banner-content
  > .lpf-banner-right
  > a:first-child {
  margin-bottom: 1rem;
}
.lpf-about-info
  > .lpf-about-info-content
  > .lpf-about-info-content-item
  > .lpf-list-banner-content
  > .lpf-banner-right
  > a
  > .lpf-contact {
  background-color: $sidebar-background-color;
  color: $white-color;
  font-size: 1.4rem;
  line-height: 2.4rem;
  padding: 4.8rem 3rem;
}
@media (min-width: 768px) {
  .lpf-about-info
    > .lpf-about-info-content
    > .lpf-about-info-content-item
    > .lpf-list-banner-content {
    display: flex;
  }
  .lpf-about-info
    > .lpf-about-info-content
    > .lpf-about-info-content-item
    > .lpf-list-banner-content
    > .lpf-banner-left {
    width: 65%;
    margin-right: 1rem;
  }
  .lpf-about-info
    > .lpf-about-info-content
    > .lpf-about-info-content-item
    > .lpf-list-banner-content
    > .lpf-banner-right {
    width: 35%;
  }
}
@media (max-width: 767px) {
  .lpf-about-info
    > .lpf-about-info-content
    > .lpf-about-info-content-item
    > .lpf-list-banner-content {
    display: block;
  }
  .lpf-about-info
    > .lpf-about-info-content
    > .lpf-about-info-content-item
    > .lpf-list-banner-content
    > .lpf-banner-left {
    width: 100%;
  }
  .lpf-about-info
    > .lpf-about-info-content
    > .lpf-about-info-content-item
    > .lpf-list-banner-content
    > .lpf-banner-right {
    width: 100%;
    margin: 1rem 0;
  }
}
</style>
