<template>
  <div class="page">
    <div class="lpf-content">
      <div class="container-fluid lpf-container-fluid">
        <vHeader></vHeader>
        <vSidebar @lpf_window_onresize="lpf_window_onresize"></vSidebar>
        <div class="lpf-content-main lpf-new-list">
          <div class="block">
            <div class="lpf-new-list-banner">
              <div class="row lpf-new-list-banner-content">
                <div class="lpf-banner-left col-xs-12 col-sm-8 wow slideInLeft">
                  <router-link class="lpf-link-a" to="">
                    <fr-image
                      class="lpf-image"
                      src="https://www.dansewudao.com/skin/img/news/bg1.jpg"
                      mode=""
                      loading-ing-img="two-balls"
                      :loading-error-img="
                        require('@/static/imgs/home/loading_error.png')
                      "
                    />
                  </router-link>
                </div>
                <div class="lpf-banner-right col-xs-12 col-sm-4 wow slideInRight">
                  <router-link class="lpf-link-a" to="">
                    <fr-image
                      class="lpf-image"
                      src="https://www.dansewudao.com/skin/img/news/bg2.jpg"
                      mode=""
                      loading-ing-img="two-balls"
                      :loading-error-img="
                        require('@/static/imgs/home/loading_error.png')
                      "
                    />
                  </router-link>
                  <router-link class="lpf-link-a" to="">
                    <fr-image
                      class="lpf-image"
                      src="https://www.dansewudao.com/skin/img/news/bg3.jpg"
                      mode=""
                      loading-ing-img="two-balls"
                      :loading-error-img="
                        require('@/static/imgs/home/loading_error.png')
                      "
                    />
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="block">
            <div class="lpf-new-list-list">
              <div class="row lpf-new-list-list-content">
                <div class="lpf-left col-xs-12 col-sm-8 wow slideInLeft">
                  <div class="lpf-list">
                    <router-link
                      class="lpf-link-a wow bounceInUp"
                      data-wow-delay="0s"
                      to="/information/news/30291.html"
                    >
                      <div class="lpf-img">
                        <fr-image
                          class="lpf-image"
                          src="https://m.dansewudao.com/uploadImg/1614838770290DSC04414.jpg"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                        />
                      </div>
                      <div class="lpf-fr-nr">
                        <h2>首届“单色杯”国标舞友谊赛成功举办！</h2>
                        <p>
                          2019年12月17日，由单色舞蹈主办的首届单色杯国标舞友谊赛于汉商银座知音站台...
                        </p>
                        <div>
                          <div class="lpf-fl">
                            <img src="/favicon.ico" />
                            <p>蓝风铃舞蹈</p>
                          </div>
                          <p class="lpf-fr">2019-12-19</p>
                        </div>
                      </div>
                    </router-link>
                    <router-link
                      class="lpf-link-a wow bounceInUp"
                      data-wow-delay="0.3s"
                      to="/information/news/30291.html"
                    >
                      <div class="lpf-img">
                        <fr-image
                          class="lpf-image"
                          src="https://m.dansewudao.com/uploadImg/1614838770290DSC04414.jpg"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                        />
                      </div>
                      <div class="lpf-fr-nr">
                        <h2>首届“单色杯”国标舞友谊赛成功举办！</h2>
                        <p>
                          2019年12月17日，由单色舞蹈主办的首届单色杯国标舞友谊赛于汉商银座知音站台...
                        </p>
                        <div>
                          <div class="lpf-fl">
                            <img src="/favicon.ico" />
                            <p>蓝风铃舞蹈</p>
                          </div>
                          <p class="lpf-fr">2019-12-19</p>
                        </div>
                      </div>
                    </router-link>
                    <div class="lpf-page">
                      <ul>
                        <li><router-link class="lpf-link-a" to="/information/news/index.html">上一页</router-link></li>
                        <li>
                          <router-link class="lpf-link-a" to="/information/news/87_2_page_index.html"
                            >下一页</router-link
                          >
                        </li>
                        <li>
                          <span class="lpf-pageinfo"
                            >共
                            <strong>21</strong>页<strong>124</strong>条</span
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lpf-right col-xs-12 col-sm-4 wow slideInRight">
                  <div class="lpf-right-title">热门文章</div>
                  <ul class="lpf-right-content">
                    <li>
                      <router-link class="lpf-link-a wow bounceInUp"
                      data-wow-delay="0s" to="">
                        <div class="top"></div>
                        <fr-image
                          class="lpf-image"
                          src="https://www.dansewudao.com/uploads/allimg/190713/9-1ZG3105200.jpg"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                        />
                        <p>长沙产后修复瑜伽培训班哪家好</p>
                      </router-link>
                    </li>
                    <li>
                      <router-link class="lpf-link-a wow bounceInUp"
                      data-wow-delay="0.3s" to="">
                        <div class="top"></div>
                        <fr-image
                          class="lpf-image"
                          src="https://www.dansewudao.com/uploads/allimg/190713/9-1ZG3105200.jpg"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                        />
                        <p>长沙产后修复瑜伽培训班哪家好</p>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <vFooter></vFooter>
      </div>
    </div>
  </div>
</template>
<script>
import { vHeader, vFooter, vSidebar } from "../layout/index.js";
import { _INFORMATION } from "../src/js/information/index.js";
export default {
  name: "Information",
  components: {
    vHeader,
    vFooter,
    vSidebar,
  },
  data: function () {
    return {};
  },
  created() {},
  mounted: function () {
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new this.WOW({});
      wow.init();
      _INFORMATION.lpf_new_list_hovr(); //高亮操作
    });
  },
  methods: {
    lpf_window_onresize(paramater) {
      _INFORMATION.lpf_window_onresize(paramater);
    },
  },
};
</script>
<style lang="scss">
.lpf-new-list-banner {
  background-color: $edu-background-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-new-list-banner-content {
  width: 100%;
  max-width: $body-width-content1;
  height: auto;
  margin: 3rem auto;
  overflow: hidden;
}
.lpf-new-list-banner > .lpf-new-list-banner-content > .lpf-banner-right {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}
.lpf-new-list-banner > .lpf-new-list-banner-content > .lpf-banner-right > a {
  width: 100%;
}
.lpf-new-list-banner
  > .lpf-new-list-banner-content
  > .lpf-banner-left
  > a
  > .lpf-image,
.lpf-new-list-banner
  > .lpf-new-list-banner-content
  > .lpf-banner-right
  > a
  > .lpf-image {
  border-radius: 0.5rem;
  overflow: hidden;
}
.lpf-new-list-list {
  background-color: $edu-background-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-new-list-list-content {
  width: 100%;
  max-width: 1000px;
  height: auto;
  margin: 0 3rem 3rem;
  overflow: hidden;
}
.lpf-new-list-list > .lpf-new-list-list-content > .lpf-left > .lpf-list {
  border-radius: 0.4rem;
  background: $white-color;
  overflow: hidden;
}
.lpf-new-list-list
  > .lpf-new-list-list-content
  > .lpf-left
  > .lpf-list
  > .lpf-link-a {
  display: block;
  padding: 3rem;
  border-bottom: 0.1rem $edu-color-h3 solid;
  transition: all 0.5s;
  overflow: hidden;
  color: $edu-color-h1;
  text-decoration: none;
}
.lpf-new-list-list
  > .lpf-new-list-list-content
  > .lpf-left
  > .lpf-list
  > .lpf-link-a:hover {
  box-shadow: 0 5px 5px $background-opacity;
}
.lpf-new-list-list
  > .lpf-new-list-list-content
  > .lpf-left
  > .lpf-list
  > .lpf-link-a
  > .lpf-img {
  width: 20rem;
  height: 12.5rem;
  overflow: hidden;
  float: left;
}
.lpf-new-list-list
  > .lpf-new-list-list-content
  > .lpf-left
  > .lpf-list
  > .lpf-link-a
  > .lpf-img
  > .lpf-image {
  width: 100%;
  height: 100%;
  transition: all 0.5s;
  border-radius: 0.5rem;
  overflow: hidden;
}
.lpf-new-list-list
  > .lpf-new-list-list-content
  > .lpf-left
  > .lpf-list
  > .lpf-link-a
  > .lpf-fr-nr {
  height: 12.5rem;
  width: calc(100% - 23rem);
  overflow: hidden;
  float: right;
  position: relative;
}
.lpf-new-list-list
  > .lpf-new-list-list-content
  > .lpf-left
  > .lpf-list
  > .lpf-link-a
  > .lpf-fr-nr
  > div {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.lpf-new-list-list
  > .lpf-new-list-list-content
  > .lpf-left
  > .lpf-list
  > .lpf-link-a
  > .lpf-fr-nr
  > div
  > .lpf-fl {
  float: left;
  width: 40%;
  display: flex;
  align-items: center;
}
.lpf-new-list-list
  > .lpf-new-list-list-content
  > .lpf-left
  > .lpf-list
  > .lpf-link-a
  > .lpf-fr-nr
  > div
  > .lpf-fl
  > img {
  width: 2rem;
  margin-right: 1rem;
}
.lpf-new-list-list
  > .lpf-new-list-list-content
  > .lpf-left
  > .lpf-list
  > .lpf-link-a
  > .lpf-fr-nr
  > div
  > .lpf-fl
  > p {
  margin: 0 !important;
  color: $font-color-1;
  line-height: 2rem;
  font-size: 1.2rem;
}
.lpf-new-list-list
  > .lpf-new-list-list-content
  > .lpf-left
  > .lpf-list
  > .lpf-link-a
  > .lpf-fr-nr
  > div
  > .lpf-fr {
  float: left;
  width: 50%;
  text-align: right;
  line-height: 2rem;
  font-size: 1.2rem;
  margin: 0 !important;
  color: $font-color-1;
}
.lpf-new-list-list
  > .lpf-new-list-list-content
  > .lpf-left
  > .lpf-list
  > .lpf-link-a
  > .lpf-fr-nr
  > h2 {
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: $edu-color-h2;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-bottom: 0.7rem;
  font-weight: bold;
}
.lpf-new-list-list
  > .lpf-new-list-list-content
  > .lpf-left
  > .lpf-list
  > .lpf-link-a
  > .lpf-fr-nr
  > p {
  font-size: 1.4rem;
  line-height: 2rem;
  color: $font-color-1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.lpf-new-list-list
  > .lpf-new-list-list-content
  > .lpf-right
  > .lpf-right-title {
  background: $white-color;
  font-size: 1.6rem;
  color: $edu-color-h2;
  line-height: 5.6rem;
  border-bottom: 0.1rem $line-color solid;
  padding: 0 2rem;
  font-weight: bold;
}
.lpf-new-list-list
  > .lpf-new-list-list-content
  > .lpf-right
  > .lpf-right-content {
  overflow: hidden;
  background: $white-color;
  list-style: none;
}
.lpf-new-list-list
  > .lpf-new-list-list-content
  > .lpf-right
  > .lpf-right-content
  > li {
  padding: 2rem 2rem 0 2rem;
  overflow: hidden;
}
.lpf-new-list-list
  > .lpf-new-list-list-content
  > .lpf-right
  > .lpf-right-content
  > li:last-child {
  padding-bottom: 2rem;
}
.lpf-new-list-list
  > .lpf-new-list-list-content
  > .lpf-right
  > .lpf-right-content
  > li
  > .lpf-link-a {
  display: block;
  position: relative;
  color: $edu-color-h1;
  text-decoration: none;
}
.lpf-new-list-list
  > .lpf-new-list-list-content
  > .lpf-right
  > .lpf-right-content
  > li
  > .lpf-link-a
  > .lpf-image {
  display: block;
  width: 9rem;
  height: 5.6rem;
  border-radius: 0.3rem;
  float: left;
  overflow: hidden;
}
.lpf-new-list-list
  > .lpf-new-list-list-content
  > .lpf-right
  > .lpf-right-content
  > li
  > .lpf-link-a
  > p {
  font-size: 1.4rem;
  color: $edu-color-h2;
  line-height: 2rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  float: right;
  width: calc(100% - 10rem);
  margin-left: 1rem;
}
.lpf-new-list-list
  > .lpf-new-list-list-content
  > .lpf-left
  > .lpf-list > .lpf-page {
  margin-top: 5rem;
  margin-bottom: 5rem;
  overflow: hidden;
}
.lpf-new-list-list
  > .lpf-new-list-list-content
  > .lpf-left
  > .lpf-list
  > .lpf-page
  > ul {
  margin-left: 150px;
}
.lpf-new-list-list
  > .lpf-new-list-list-content
  > .lpf-left
  > .lpf-list
  > .lpf-page
  > ul
  > li {
  overflow: hidden;
  line-height: 3rem;
  padding: 0.5rem 1rem;
  border: 0.1rem solid #afabab;
  border-radius: 0.5rem;
  float: left;
  margin-right: 1rem;
}
.lpf-new-list-list
  > .lpf-new-list-list-content
  > .lpf-left
  > .lpf-list>.lpf-page
  > ul
  > li
  > .lpf-link-a {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  color: $edu-color-h1;
  text-decoration: none;
  font-size: 1.7rem;
}
.lpf-new-list-list
  > .lpf-new-list-list-content
  > .lpf-left
  > .lpf-list>.lpf-page
  > ul
  > li>.lpf-pageinfo{
    font-size: 1.7rem;
  }
.lpf-new-list-list
  > .lpf-new-list-list-content
  > .lpf-left
  > .lpf-list>.lpf-page
  > ul
  > li>.lpf-pageinfo>strong{
        font-weight: bold;
  }
  .lpf-new-list-list
  > .lpf-new-list-list-content
  > .lpf-left
  > .lpf-list>.lpf-page
  > ul
  > li:hover{
    background-color: $sidebar-background-color;
    border: 0.1rem solid $sidebar-background-color;
    color: $white-color!important;
  }

@media (max-width: 767px) {
  .lpf-new-list-banner > .lpf-new-list-banner-content > .lpf-banner-right > a {
    margin-top: 2rem;
  }
}
</style>
