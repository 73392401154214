<template>
  <div class="block">
    <div class="lpf-jazz-video">
      <div class="row lpf-jazz-video-title">
        <div class="col-xs-12 lpf">
          <div class="lpf-title-left">专业的课程研发</div>
          <router-link to="/video" class="lpf-link-a">更多视频 ></router-link>
        </div>
      </div>
      <div class="row lpf-jazz-video-content">
        <div class="col-xs-12 lpf">
          <ul class="lpf-video">
            <li class="lpf-li">
              <video
                width="100%"
                controls="controls"
                poster="https://m.dansewudao.com/uploadImg/1611385941412.jpg"
                src="https://p.dansewudao.com/74d7369136e546e9a59a74da5d55eca3/9c4021df998d4159b80da7a935908262-c2b860f2d17dc9d0ead703e6cd0d3558-sd.mp4"
                type="video/mp4"
              ></video>
              <p><span>爵士舞</span>爵士舞教练班学员练习室作品</p>
            </li>
            <li class="lpf-li">
              <video
                width="100%"
                controls="controls"
                poster="https://m.dansewudao.com/uploadImg/16152691280981.jpg"
                src="https://p.dansewudao.com/0483937fbd4740fca2955569930df05b/405f942fcea64f778389018b30d8a768-0982b1a728a9532777672df03a39ab35-sd.mp4"
                type="video/mp4"
              ></video>
              <p><span>爵士舞</span>《Rewards》爵士舞教学视频</p>
            </li>
            <li class="lpf-li">
              <video
                width="100%"
                controls="controls"
                poster="https://m.dansewudao.com/uploadImg/16112091594251.jpg"
                src="https://p.dansewudao.com/b9e0176b0f724961934ef6d1a51443cb/26a1b7adb92445cd8b173d97583e8489-f5cb0256c934f377f283a34483aa5efc-sd.mp4"
                type="video/mp4"
              ></video>
              <p><span>爵士舞</span>《maria》女团多人爵士舞教学</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="row lpf-jazz-video-dh">
        <div class="col-xs-12 lpf">
          <ul class="lpf-dh">
            <li class="lpf-li">百人资深<br />教研团队</li>
            <li class="lpf-li">全国统一<br />教学大纲</li>
            <li class="lpf-li">国际教学<br />体系认证</li>
            <li class="lpf-li">教研全程<br />课程督导</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "lpfVideo",
  data() {
    return {
      lpf_video_bili: 393 / 262, //视频比例
    };
  },
  props: {},
  mounted() {},
  methods: {
    lpf_window_onresize: function (paramater) {
      let str_v =
        ".lpf-jazz-video>.lpf-jazz-video-content>.lpf>.lpf-video>.lpf-li>video";
      let height_v = $(str_v).width() / this.lpf_video_bili;
      $(str_v).css({ height: height_v + "px" });
    },
  },
};
</script>

<style lang="scss">
.lpf-jazz-video {
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-jazz-video > .lpf-jazz-video-title {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 3rem auto 2.5rem;
  overflow: hidden;
}
.lpf-jazz-video > .lpf-jazz-video-title > .lpf > .lpf-title-left {
  font-size: 4rem;
  color: $footer-background-color;
  float: left;
  text-align: center;
}
.lpf-jazz-video > .lpf-jazz-video-title > .lpf > .lpf-link-a {
  font-size: 3rem;
  color: $edu-color-a;
  float: right;
}
.lpf-jazz-video > .lpf-jazz-video-content {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 0 auto 1rem;
  overflow: hidden;
}
.lpf-jazz-video > .lpf-jazz-video-content > .lpf > .lpf-video {
  width: 100%;
}
.lpf-jazz-video > .lpf-jazz-video-content > .lpf > .lpf-video > .lpf-li {
  width: 32%;
  float: left;
  margin-right: 2%;
  padding-bottom: 0.5rem;
}
.lpf-jazz-video
  > .lpf-jazz-video-content
  > .lpf
  > .lpf-video
  > .lpf-li:nth-child(3n) {
  margin-right: 0;
}
.lpf-jazz-video
  > .lpf-jazz-video-content
  > .lpf
  > .lpf-video
  > .lpf-li
  > video {
  width: 100%;
  height: 100%;
  object-fit: fill;
  outline: none;
}
.lpf-jazz-video > .lpf-jazz-video-content > .lpf > .lpf-video > .lpf-li > p {
  font-size: 1.5rem;
  color: $footer-background-color;
  line-height: 5rem;
  margin-top: -0.5rem;
  box-shadow: 0 0 0.6rem 0 #a9a3a3;
  border-radius: 0 0 1rem 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lpf-jazz-video
  > .lpf-jazz-video-content
  > .lpf
  > .lpf-video
  > .lpf-li
  > p
  > span {
  width: 4rem;
  height: 2rem;
  padding: 0.2rem 0.5rem;
  line-height: 3rem;
  background: #d5d6e7;
  color: $sidebar-background-color;
  text-align: center;
  font-size: 1.2rem;
  margin: 0 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lpf-jazz-video > .lpf-jazz-video-dh {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 1rem auto 2.5rem;
  overflow: hidden;
}
.lpf-jazz-video > .lpf-jazz-video-dh > .lpf > .lpf-dh {
  padding: 0.6rem;
  overflow: hidden;
}
.lpf-jazz-video > .lpf-jazz-video-dh > .lpf > .lpf-dh > .lpf-li {
  width: 23.5%;
  float: left;
  box-shadow: 0 0 0.6rem 0 #a9a3a3;
  border-radius: 1rem;
  font-size: 3rem;
  color: $edu-color-span;
  font-weight: bold;
  text-align: center;
  padding: 2rem 0;
  margin-right: 2%;
  margin-bottom: 1rem;
}
.lpf-jazz-video > .lpf-jazz-video-dh > .lpf > .lpf-dh > .lpf-li:nth-child(4n) {
  margin-right: 0;
}
@media (max-width: 890px) {
  .lpf-jazz-video > .lpf-jazz-video-content > .lpf > .lpf-video > .lpf-li {
    width: 49%;
    margin-right: 2%;
  }
  .lpf-jazz-video
    > .lpf-jazz-video-content
    > .lpf
    > .lpf-video
    > .lpf-li:nth-child(2n) {
    margin-right: 0;
  }
  .lpf-jazz-video > .lpf-jazz-video-dh > .lpf > .lpf-dh > .lpf-li {
    width: 49%;
    margin-right: 2%;
  }
  .lpf-jazz-video
    > .lpf-jazz-video-dh
    > .lpf
    > .lpf-dh
    > .lpf-li:nth-child(2n) {
    margin-right: 0;
  }
}
@media (max-width: 590px) {
  .lpf-jazz-video > .lpf-jazz-video-content > .lpf > .lpf-video > .lpf-li {
    width: 100%;
    margin-right: 0;
  }
  .lpf-jazz-video > .lpf-jazz-video-dh > .lpf > .lpf-dh > .lpf-li {
    width: 100%;
    margin-right: 0;
  }
}
</style>