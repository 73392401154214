<template>
  <div class="lpf-footer">
    <div class="row lpf-main-footer">
      <div class="wow bounceInLeft col-xs-12 col-md-3 lpf-left">
        <div class="row lpf-footer-ul">
          <div class="lpf-footer-ul-item col-xs-12 col-sm-6 col-md-12">
            <div class="lpf-image">
              <i class="iconfont lpf-iconfont">&#xe60b;</i>
            </div>
            <div class="lpf-title">
              <p>全国客服</p>
              <h2>13122679851</h2>
            </div>
          </div>
          <div class="lpf-footer-ul-item col-xs-12 col-sm-6 col-md-12">
            <div class="lpf-image">
              <i class="iconfont lpf-iconfont">&#xe605;</i>
            </div>
            <div class="lpf-title">
              <p>总机</p>
              <h2>13122679851</h2>
            </div>
          </div>
          <div class="lpf-footer-ul-item col-xs-12 col-sm-6 col-md-12">
            <div class="lpf-image">
              <i class="iconfont lpf-iconfont">&#xe63e;</i>
            </div>
            <div class="lpf-title">
              <p>总部地址</p>
              <h2 class="lpf-address">河南省新乡市延津县窗外网吧楼上</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="wow bounceIn col-xs-12 col-md-6 lpf-center">
        <div class="lpf-center-main">
          <ul class="lpf-dh">
            <li v-for="(item,index) in lpf_sidebar" :key="index">
              <router-link class="lpf-link-a" to="/about/danse">{{item.title}}</router-link >
            </li>
          </ul>
          <div class="lpf-link">
            <p>友情链接</p>
            <div class="lpf-a">
               <p v-for="(item,index) in lpf_link" :key="index">
              <a class="lpf-link-a" href="https://www.psoneart.com/" target="_blank">{{item.title}}</a>
            </p>
            </div>
          </div>
          <div class="lpf-fenxiao">
            <p>分校地址</p>
            <div class="lpf-a">
              <p v-for="(item,index) in lpf_fenxiao" :key="index">
                <a class="lpf-link-a" href="https://www.psoneart.com/" target="_blank">{{item.title}}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="wow bounceInRight col-xs-12 col-md-3 lpf-right">
        <div class="lpf-right-main">
          <img
            src="../../../static/imgs/home/ewm.png"
            alt="官方微信"
          />
          <p>关注蓝风铃官方微信</p>
        </div>
      </div>
    </div>
    <div class="wow slideInUp row lpf-warning">
      <div class="col-xs-12 lpf-copy">
        <p class="wow slideInUp" data-wow-delay="0.01s">
          <span class="lpf-company">蓝风铃舞蹈培训学校</span
          >设有爵士舞培训，肚皮舞培训，拉丁舞培训，中国舞培训，街舞培训，瑜伽培训，少儿舞蹈培训，舞蹈教练培训等，并承接私教，MV成品舞，各类商业演出。
        </p>
        <p class="wow slideInUp" data-wow-delay="0.02s">
          <span class="lpf-domain">www.lanfenglingdance.com</span
          >已在“中国版权保护中心”备案，
          任何模仿、抄袭、转载行为均被视为侵权并依法追究。
        </p>
        <p class="wow slideInUp" data-wow-delay="0.03s">
          <span class="lpf-beian"
            ><a class="lpf-link-a" href="https://beian.miit.gov.cn/" target="_blank">豫ICP备123456789号-1</a></span
          ><span class="lpf-beian-ga"
            >
            <img src="../../../static/imgs/home/gongan.png"/>
            <a class="lpf-link-a" href="">豫公网安备412345678910号</a>
            </span
          >
          <span class="lpf-beian-bz"
            >Copyright © 2021.name All rights reserved</span
          >
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { _ADMIN_CONFIG } from "../config/config";
import { _data, _methods } from "../../../common/public";
export default {
  name: "Footer",
  props: {},
  data() {
    return {
      lpf_sidebar: [
        { title: "蓝风铃品牌" },
        { title: "全国分馆" },
        { title: "商业合作" },
        { title: "荣誉资质" },
        { title: "联系我们" },
        { title: "蓝风铃咨询" },
      ],
      lpf_link: [
        { title: "proe培训" },
        { title: "美国艺术留学" },
        { title: "沈阳雅思培训" },
        { title: "艺术留学中介" },
        { title: "函授本科报名时间" },
        { title: "自考报名时间" },
        { title: "重庆演讲培训" },
        { title: "上海国际学校" },
        { title: "儿童美术加盟" },
        { title: "函授报名" },
        { title: "北京培训机构" },
        { title: "瑜伽培训" },
        { title: "瑜伽教学视频" },
      ],
      lpf_fenxiao: [
        { title: "延津县" },
        { title: "胙城乡" },
        { title: "郑州市" },
        { title: "武汉市" },
        { title: "北京市" },
        { title: "南京市" },
      ],
    };
  },
  mounted: function () {

  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.lpf-footer {
  width: 100%;
  background-color: $footer-background-color;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.lpf-main-footer {
  width: 100%;
  max-width: 1300px;
  border-bottom: 1px solid $footer-line-color;
  margin: 2rem 0;
}
.lpf-left,
.lpf-center,
.lpf-right {
  display: flex;
  justify-content: center;
  align-items: center;
}
.lpf-center {
  box-sizing:border-box;
  border-left: 1px solid $footer-line-color;
  border-right: 1px solid $footer-line-color;
  padding: 0 2rem;
}
.lpf-center>.lpf-center-main{
  width: 100%;
}
.lpf-center>.lpf-center-main>.lpf-dh{
  list-style-type: none;
  overflow: hidden;
  padding-left: 0;
}
.lpf-center>.lpf-center-main>.lpf-dh>li{
  float: left;
  font-size: 1.8rem;
      border-right: 1px solid $white-color;
    padding: 0 0.8rem;

}
.lpf-center>.lpf-center-main>.lpf-dh>li:last-child{
  border-right:none;
}
.lpf-center>.lpf-center-main>.lpf-dh>li>.lpf-link-a{
color: $white-color;
}
.lpf-center>.lpf-center-main>.lpf-dh>li>.lpf-link-a:hover{
  color: $sidebar-background-color;
}
.lpf-center>.lpf-center-main>.lpf-link>p{
  color: $white-color;
  font-size: 1.8rem;
}
.lpf-center>.lpf-center-main>.lpf-link>.lpf-a{
  overflow: hidden;
}
.lpf-center>.lpf-center-main>.lpf-link>.lpf-a>p{
float: left;
cursor: pointer;
}
.lpf-center>.lpf-center-main>.lpf-link>.lpf-a>p>.lpf-link-a{
  color: $footer-link-color;
  font-size: 1.2rem;
  margin-right: 1.2rem;
}
.lpf-center>.lpf-center-main>.lpf-link>.lpf-a>p>.lpf-link-a:hover {
  color: $sidebar-background-color;
}
.lpf-center>.lpf-center-main>.lpf-fenxiao>p{
color: $white-color;
  font-size: 1.8rem;
}
.lpf-center>.lpf-center-main>.lpf-fenxiao>.lpf-a>p{
float: left;
cursor: pointer;
}
.lpf-center>.lpf-center-main>.lpf-fenxiao>.lpf-a{
  overflow: hidden;
}
.lpf-center>.lpf-center-main>.lpf-fenxiao>.lpf-a>p>.lpf-link-a{
  color: $footer-link-color;
  font-size: 1.2rem;
  margin-right: 1.2rem;
}
.lpf-center>.lpf-center-main>.lpf-fenxiao>.lpf-a>p>.lpf-link-a:hover {
  color: $sidebar-background-color;
}
.lpf-dh,.lpf-link,.lpf-fenxiao{
  display: block;
  margin-bottom: 2rem;
  padding-left: 0.8rem;
}
.lpf-right>.lpf-right-main>p{
color: $white-color;
margin-top: 0.6rem;
font-size: 1.2rem;
text-align: center;
}
.lpf-right>.lpf-right-main>img{
  width: 13rem;
  height: auto;
}
.lpf-image {
  float: left;
  margin: 0 1rem;
}
.lpf-title {
  color: $white-color;
}
.lpf-title > p {
  color: $title-color;
  text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
}
.lpf-title > h2 {
  margin-top: 0 !important;
  text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
}
.lpf-title > .lpf-address {
  font-size: 1.5rem;
}
.lpf-warning{
  width: 100%;
}
.lpf-copy > p {
  width: 100%;
  font-size: 1.2rem;
  color: $footer-color;
  text-align: center;
  line-height: 2rem;
}
.lpf-copy > p > .lpf-company{
  margin-right: 1rem;
}
.lpf-copy > p > span > .lpf-link-a {
  color: $footer-color;
}
.lpf-copy > p > span > .lpf-link-a:hover {
  color: $sidebar-background-color;
}
.lpf-copy > p > .lpf-domain{
  color: $sidebar-background-color;
}
.lpf-copy > p > .lpf-beian,.lpf-copy > p > .lpf-beian-ga{
  display: block;
}
.lpf-copy > p > .lpf-beian-ga>img{
margin-right: 0.5rem;
width: 1.4rem;
height: auto;
}
.lpf-iconfont {
  color: $sidebar-background-color;
  font-size: 4.4rem;
}
@media (max-width: 	970px){
  .lpf-left,.lpf-center{
    border-bottom: 1px solid $footer-line-color;
  }
  .lpf-center,.lpf-right{
    margin-top: 2rem;
  }
}
@media (max-width: 767px){
  .lpf-footer-ul-item{
  display: flex;
    align-items: center;
    justify-content: center;
}
}
</style>
