const _CONTACT = {
  lpf_image_bili:646/498,//图片比例
  lpf_image_bili1:360/220,//图片比例
  lpf_image_bili2:360/343,//图片比例
  lpf_window_onresize: function (paramater) {
    let str_left = ".lpf-about-info > .lpf-about-info-content > .lpf-about-info-content-item>.lpf-list-banner-content>.lpf-banner-left>a>.lpf-map";
    let height_left = $(str_left).width()/this.lpf_image_bili;
    $(str_left).css({ height: height_left + "px" });
    let str_right1 = ".lpf-about-info > .lpf-about-info-content > .lpf-about-info-content-item>.lpf-list-banner-content>.lpf-banner-right>a>.lpf-image1";
    let height_right1 = $(str_right1).width()/this.lpf_image_bili1;
    $(str_right1).css({ height: height_right1 + "px" });
    let str_right2 = ".lpf-about-info > .lpf-about-info-content > .lpf-about-info-content-item>.lpf-list-banner-content>.lpf-banner-right>a>.lpf-contact";
    let height_right2 = $(str_right2).width()/this.lpf_image_bili2;
    $(str_right2).css({ height: height_right2 + "px" });
  },
  
}
export { _CONTACT }
