<template>
  <div class="page">
    <div class="lpf-content">
      <div class="container-fluid lpf-container-fluid">
        <vHeader></vHeader>
        <vSidebar @lpf_window_onresize="lpf_window_onresize"></vSidebar>
        <div class="lpf-content-main lpf-about">
          <div class="block">
            <div class="lpf-about-info">
              <div class="row lpf-about-info-content">
                <div class="col-md-12 lpf-about-info-content-title">
                  <span class="iconfont">&#xe616;</span>
                  <p>荣誉资质</p>
                  <div class="lpf-botton-line"></div>
                </div>
                <div class="col-md-12 lpf-about-info-content-item">
                  <ul class="lpf-ul">
                    <li>
                      <router-link
                        class="lpf-link-a"
                        to="/about/qualifications/40404.html"
                      >
                        <fr-image
                          class="lpf-image"
                          src="https://m.dansewudao.com/uploadImg/16185423916301_600x400.jpg"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                        />
                        <p>年度优秀节目选送单位</p>
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        class="lpf-link-a"
                        to="/about/qualifications/40404.html"
                      >
                        <fr-image
                          class="lpf-image"
                          src="https://m.dansewudao.com/uploadImg/16185423916301_600x400.jpg"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                        />
                        <p>年度优秀节目选送单位</p>
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        class="lpf-link-a"
                        to="/about/qualifications/40404.html"
                      >
                        <fr-image
                          class="lpf-image"
                          src="https://m.dansewudao.com/uploadImg/16185423916301_600x400.jpg"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                        />
                        <p>年度优秀节目选送单位</p>
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        class="lpf-link-a"
                        to="/about/qualifications/40404.html"
                      >
                        <fr-image
                          class="lpf-image"
                          src="https://m.dansewudao.com/uploadImg/16185423916301_600x400.jpg"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                        />
                        <p>年度优秀节目选送单位</p>
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        class="lpf-link-a"
                        to="/about/qualifications/40404.html"
                      >
                        <fr-image
                          class="lpf-image"
                          src="https://m.dansewudao.com/uploadImg/16185423916301_600x400.jpg"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                        />
                        <p>年度优秀节目选送单位</p>
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        class="lpf-link-a"
                        to="/about/qualifications/40404.html"
                      >
                        <fr-image
                          class="lpf-image"
                          src="https://m.dansewudao.com/uploadImg/16185423916301_600x400.jpg"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                        />
                        <p>年度优秀节目选送单位</p>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <vFooter></vFooter>
      </div>
    </div>
  </div>
</template>
<script>
import { vHeader, vFooter, vSidebar } from "../layout/index.js";
import { _QUALIFICATIONS } from "../src/js/about/qualifications.js";
export default {
  name: "QUALIFICATIONS",
  components: {
    vHeader,
    vFooter,
    vSidebar,
  },
  data: function () {
    return {};
  },
  created() {
    this._CONFIG.colorNavc = "color";
  },
  mounted: function () {
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new this.WOW({});
      wow.init();
    });
  },
  methods: {
    lpf_window_onresize(paramater) {
      _QUALIFICATIONS.lpf_window_onresize(paramater);
    },
  },
};
</script>
<style lang="scss">
.lpf-about-info {
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-about-info > .lpf-about-info-content {
  width: 100%;
  max-width: $body-width-content3;
  height: auto;
  margin: 1rem auto 2rem;
  overflow: hidden;
}
.lpf-about-info > .lpf-about-info-content > .lpf-about-info-content-title {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  // border-bottom: 0.1rem solid $sidebar-background-color;
}
.lpf-about-info
  > .lpf-about-info-content
  > .lpf-about-info-content-title
  > span {
  color: $sidebar-background-color;
  font-size: 2.8rem;
  display: inline;
  vertical-align: middle;
  margin-right: 1rem;
}
.lpf-about-info > .lpf-about-info-content > .lpf-about-info-content-title > p {
  color: $sidebar-background-color;
  font-size: 1.8rem;
  font-weight: bold;
  display: inline;
  vertical-align: middle;
}
.lpf-about-info
  > .lpf-about-info-content
  > .lpf-about-info-content-title
  > .lpf-botton-line {
  width: 100%;
  height: 0.1rem;
  background-color: $sidebar-background-color;
}
.lpf-about-info
  > .lpf-about-info-content
  > .lpf-about-info-content-item
  > .lpf-ul {
  overflow: hidden;
}
.lpf-about-info
  > .lpf-about-info-content
  > .lpf-about-info-content-item
  > .lpf-ul
  > li {
  width: 23.5%;
  margin-right: 2%;
  margin-bottom: 2rem;
  float: left;
  overflow: hidden;
}
.lpf-about-info
  > .lpf-about-info-content
  > .lpf-about-info-content-item
  > .lpf-ul
  > li:nth-child(4n) {
  margin-right: 0;
}
.lpf-about-info
  > .lpf-about-info-content
  > .lpf-about-info-content-item
  > .lpf-ul
  > li
  > .lpf-link-a {
  color: $edu-color-h1;
  text-decoration: none;
  display: block;
  text-align: center;
}
.lpf-about-info
  > .lpf-about-info-content
  > .lpf-about-info-content-item
  > .lpf-ul
  > li
  > .lpf-link-a
  > .lpf-image {
  border: 0.1rem solid #bfbfbf;
}
.lpf-about-info
  > .lpf-about-info-content
  > .lpf-about-info-content-item
  > .lpf-ul
  > li
  > .lpf-link-a
  > .lpf-image
  > .fr_img_content {
  left: 0;
  top: 0;
  padding: 2rem;
}
.lpf-about-info
  > .lpf-about-info-content
  > .lpf-about-info-content-item
  > .lpf-ul
  > li
  > .lpf-link-a
  > p {
  font-size: 1.4rem;
  color: $edu-color-span1;
  line-height: 4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (max-width: 992px) {
.lpf-about-info
  > .lpf-about-info-content
  > .lpf-about-info-content-item
  > .lpf-ul
  > li {
    width: 32%;
    margin-right: 2%;
  }
.lpf-about-info
  > .lpf-about-info-content
  > .lpf-about-info-content-item
  > .lpf-ul
  > li:nth-child(4n) {
    margin-right: 2%;
  }
.lpf-about-info
  > .lpf-about-info-content
  > .lpf-about-info-content-item
  > .lpf-ul
  > li:nth-child(2n) {
    margin-right: 2%;
  }
.lpf-about-info
  > .lpf-about-info-content
  > .lpf-about-info-content-item
  > .lpf-ul
  > li:nth-child(3n) {
    margin-right: 0;
  }
}
@media (max-width: 768px) {
.lpf-about-info
  > .lpf-about-info-content
  > .lpf-about-info-content-item
  > .lpf-ul
  > li{
    width: 47%;
    margin-right: 6%;
  }
.lpf-about-info
  > .lpf-about-info-content
  > .lpf-about-info-content-item
  > .lpf-ul
  > li:nth-child(3n) {
    margin-right: 6%;
  }
.lpf-about-info
  > .lpf-about-info-content
  > .lpf-about-info-content-item
  > .lpf-ul
  > li:nth-child(4n) {
    margin-right: 6%;
  }
.lpf-about-info
  > .lpf-about-info-content
  > .lpf-about-info-content-item
  > .lpf-ul
  > li:nth-child(2n) {
    margin-right: 0;
  }
}
</style>
