<template>
  <div class="page">
    <div class="lpf-content">
      <div class="container-fluid lpf-container-fluid">
        <vHeader></vHeader>
        <vSidebar @lpf_window_onresize="lpf_window_onresize"></vSidebar>
        <div class="lpf-content-main lpf-campus">
          <div class="block">
            <div class="lpf-campus-info">
              <div class="row lpf-campus-info-content">
                <div class="col-xs-12 lpf-all">
                  <span class="iconfont">&#xe616;</span>
                  <p>全国分馆</p>
                </div>
                <div class="col-xs-12 lpf-address">
                  <router-link class="lpf-link-a active" to="">
                    河南
                  </router-link>
                  <router-link class="lpf-link-a" to=""> 北京 </router-link>
                  <router-link class="lpf-link-a" to=""> 上海 </router-link>
                  <router-link class="lpf-link-a" to=""> 湖北 </router-link>
                </div>
                <div class="col-xs-12 lpf-guan">
                  <div class="col-sm-12 col-lg-2 lpf-guan-left">
                    <router-link class="lpf-link-a" to="">
                      <span class="active"> 新乡街道馆 </span>
                      <i id="jiantou" class="active"></i>
                    </router-link>
                    <router-link class="lpf-link-a" to="">
                      <span> 新乡街道馆 </span>
                      <i id="jiantou"></i>
                    </router-link>
                  </div>
                  <div class="col-sm-12 col-lg-10 lpf-guan-right">
                    <div class="col-sm-12 lpf-guan-text">
                      <div class="col-xs-12 col-sm-7 lpf-guan-text-left">
                        <div class="lpf-guan-text-left-bt">
                          <span></span>武昌徐东馆
                        </div>
                        <p>
                          单色国际（连锁）舞蹈培训徐东馆位于徐东繁华的商圈，交通便利，紧邻新世界百货、方便会员上下课的出行。课后之余也能享受各种积聚地方特色的小吃及高档餐厅，齐全的娱乐设施和高端品牌商场等，休闲娱乐非常方便。会所内提供干净舒适的休闲区、免费wifi上网、更衣室、茶水提供、独立洗手间、露天阳台。让会员在一个轻松的环境中尽情的享受舞蹈。个性、明亮、宽敞的教室。让会员尽情的发挥自己的舞蹈技能，享受舞蹈带来的乐趣。
                        </p>
                        <router-link class="lpf-link-a" to="" target="_blank"
                          >立即预约</router-link
                        >
                      </div>
                      <div class="col-xs-12 col-sm-5 lpf-guan-text-right">
                        <b>热线电话：</b>18627824896<br />
                        <b>客服微信：</b>danse226<br />
                        <b>地址：</b
                        >武汉市武昌区徐东一路新世界百货3楼单色舞蹈<br />
                        <b>乘车路线：</b
                        >汉口火车站、武昌火车站、武汉火车站、天河机场乘车方式详询课程顾问；武汉市区可乘坐公交车702、534、709、577、315、402、530路等到“徐东大街地铁徐东站”下车
                      </div>
                    </div>
                    <div class="col-sm-12 lpf-guan-img">
                      <fr-image
                          class="lpf-image"
                          src="https://www.dansewudao.com/uploads/allimg/190713/9-1ZG3105200.jpg"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                        />
                        <fr-image
                          class="lpf-image"
                          src="https://www.dansewudao.com/uploads/allimg/190713/9-1ZG3105200.jpg"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                        />
                        <fr-image
                          class="lpf-image"
                          src="https://www.dansewudao.com/uploads/allimg/190713/9-1ZG3105200.jpg"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                        />
                        <fr-image
                          class="lpf-image"
                          src="https://www.dansewudao.com/uploads/allimg/190713/9-1ZG3105200.jpg"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                        />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <vFooter></vFooter>
      </div>
    </div>
  </div>
</template>
<script>
import { vHeader, vFooter, vSidebar } from "../layout/index.js";
import { _CAMPUS } from "../src/js/campus/index.js";
export default {
  name: "CAMPUS",
  components: {
    vHeader,
    vFooter,
    vSidebar,
  },
  data: function () {
    return {};
  },
  created() {},
  mounted: function () {
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new this.WOW({});
      wow.init();
    });
    _CAMPUS.lpf_address_link_click();
    _CAMPUS.lpf_address_link_left_click();
  },
  methods: {
    lpf_window_onresize(paramater) {
      _CAMPUS.lpf_window_onresize(paramater);
    },
  },
};
</script>
<style lang="scss">
.lpf-campus-info {
  //   margin-top: 4rem !important;
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-campus-info > .lpf-campus-info-content {
  width: 100%;
  max-width: $body-width-content3;
  height: auto;
  margin: 3rem auto;
  overflow: hidden;
}
.lpf-campus-info > .lpf-campus-info-content > .lpf-all >span{
  color: $sidebar-background-color;
  font-size: 2.8rem;
  display: inline;
  vertical-align:middle;
  margin-right: 1rem;
}
.lpf-campus-info > .lpf-campus-info-content > .lpf-all > p {
  color: $sidebar-background-color;
  font-size: 1.8rem;
  font-weight: bold;
  vertical-align: middle;
  display: inline;
}
.lpf-campus-info > .lpf-campus-info-content > .lpf-address {
  margin: 1rem 0;
}
.lpf-campus-info > .lpf-campus-info-content > .lpf-address > a.active {
  background: $sidebar-background-color;
}
.lpf-campus-info > .lpf-campus-info-content > .lpf-address > a {
  display: block;
  float: left;
  width: 9rem;
  height: 4rem;
  background: $edu-color-a1;
  text-align: center;
  line-height: 4rem;
  color: $white-color;
  font-size: 1.6rem;
  font-weight: bold;
  margin-right: 0.5rem;
  text-decoration: none;
}
.lpf-campus-info > .lpf-campus-info-content > .lpf-address > a:hover {
  color: $red-color;
}
.lpf-campus-info > .lpf-campus-info-content > .lpf-guan > .lpf-guan-left,
.lpf-campus-info > .lpf-campus-info-content > .lpf-guan > .lpf-guan-right {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.lpf-campus-info
  > .lpf-campus-info-content
  > .lpf-guan
  > .lpf-guan-left
  > .lpf-link-a {
  display: inline-block;
  width: 16.66rem;
  margin-bottom: 0.8rem;
  position: relative;
  text-decoration: none;
}
.lpf-campus-info
  > .lpf-campus-info-content
  > .lpf-guan
  > .lpf-guan-left
  > .lpf-link-a
  > span {
  display: block;
  height: 4.2rem;
  background: $edu-color-a2;
  padding-left: 1.2rem;
  margin-right: 1rem;
  line-height: 4.2rem;
  font-size: 1.4rem;
  color: $black-color;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lpf-campus-info
  > .lpf-campus-info-content
  > .lpf-guan
  > .lpf-guan-left
  > .lpf-link-a
  > i {
  display: none;
  width: 0;
  height: 0;
  border-width: 0.6rem;
  border-style: solid;
  border-color: transparent transparent transparent #33ccff;
  position: absolute;
  right: calc(1rem - 0.6rem - 0.6rem);
  top: calc(2.1rem - 0.6rem);
}
.lpf-campus-info
  > .lpf-campus-info-content
  > .lpf-guan
  > .lpf-guan-left
  > .lpf-link-a
  > i.active {
  display: block;
}
.lpf-campus-info
  > .lpf-campus-info-content
  > .lpf-guan
  > .lpf-guan-left
  > .lpf-link-a
  > span:hover {
  color: $red-color;
}
.lpf-campus-info
  > .lpf-campus-info-content
  > .lpf-guan
  > .lpf-guan-left
  > .lpf-link-a
  > span.active {
  background: $sidebar-background-color;
  color: $white-color;
}
.lpf-campus-info
  > .lpf-campus-info-content
  > .lpf-guan
  > .lpf-guan-right>.lpf-guan-text{
    background-color: $sidebar-background-color;
    height: auto;
    padding: 2rem 1.6rem;
    overflow: hidden;
    color: $white-color;
  }
  .lpf-campus-info
  > .lpf-campus-info-content
  > .lpf-guan
  > .lpf-guan-right>.lpf-guan-text>.lpf-guan-text-left>.lpf-guan-text-left-bt{
    font-size: 1.6rem;
    font-weight: bold;
  }
    .lpf-campus-info
  > .lpf-campus-info-content
  > .lpf-guan
  > .lpf-guan-right>.lpf-guan-text>.lpf-guan-text-left>p{
        font-size: 1.4rem;
    line-height: 179%;
    padding-top: 1.2rem;
  }
  .lpf-campus-info
  > .lpf-campus-info-content
  > .lpf-guan
  > .lpf-guan-right>.lpf-guan-text>.lpf-guan-text-left>.lpf-link-a{
    display: block;
    width: 13rem;
    height: 4rem;
    background: $white-color;
    border-radius: 0.5rem;
    color: $sidebar-background-color;
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 4rem;
    text-align: center;
    margin: 1.4rem 0;
  }

  .lpf-campus-info
  > .lpf-campus-info-content
  > .lpf-guan
  > .lpf-guan-right>.lpf-guan-text>.lpf-guan-text-right{
    height: auto;
    font-size: 1.4rem;
    color: $white-color;
    line-height: 171%;
    border-left: 0.1rem solid $edu-color-h3;
    padding-top: 0.8rem;
  }
  .lpf-campus-info
  > .lpf-campus-info-content
  > .lpf-guan
  > .lpf-guan-right>.lpf-guan-img{
    background-color: $white-color;
    height: auto;
    overflow: hidden;
    color: $white-color;
    margin-top: 1rem;
    padding-left: 0!important;
    padding-right: 0!important;
  }
  .lpf-campus-info
  > .lpf-campus-info-content
  > .lpf-guan
  > .lpf-guan-right>.lpf-guan-img>.lpf-image{
    width: calc((100% - 1rem)/2.0)!important;
    float: left;
    margin-bottom: 1rem;
  }
  .lpf-campus-info
  > .lpf-campus-info-content
  > .lpf-guan
  > .lpf-guan-right>.lpf-guan-img>.lpf-image:nth-child(2n+1){
    margin-right: 1rem!important;
  }
  @media (max-width: 767px) {
    .lpf-campus-info
  > .lpf-campus-info-content
  > .lpf-guan
  > .lpf-guan-right>.lpf-guan-text>.lpf-guan-text-right{
    border-left: none;
    border-top: 0.1rem solid $edu-color-h3;
  }
  .lpf-campus-info
  > .lpf-campus-info-content
  > .lpf-guan
  > .lpf-guan-right>.lpf-guan-img>.lpf-image{
    margin-right: 0!important;
    width: 100%!important;
  }
}
</style>
