const _INFORMATION = {
  lpf_image_bili:960/640,//图片比例
  lpf_window_onresize: function (paramater) {
    let str_left = ".lpf-new-list-banner>.lpf-new-list-banner-content>.lpf-banner-left>a>.lpf-image";
    let height_left = $(str_left).width()/this.lpf_image_bili;
    $(str_left).css({ height: height_left + "px" });
    let str_right = ".lpf-new-list-banner>.lpf-new-list-banner-content>.lpf-banner-right>a>.lpf-image";
    let height_right = $(str_right).width()/this.lpf_image_bili;
    $(str_right).css({ height: height_right + "px" });
    if(paramater.wid>767){
$(".lpf-new-list-banner>.lpf-new-list-banner-content>.lpf-banner-right").css({ height: height_left + "px" });
    }
    else{
      $(".lpf-new-list-banner>.lpf-new-list-banner-content>.lpf-banner-right").css({ height: "100%" });
    }

  },
  lpf_new_list_hovr:function (){
    $(".lpf-new-list-list> .lpf-new-list-list-content> .lpf-left> .lpf-list> .lpf-link-a").hover(function(){
      $(this).children('.lpf-img').children('.lpf-image').css({"transform":"scale(2)","transition": "all 0.6s ease 0s"});
  },function(){
    $(this).children('.lpf-img').children('.lpf-image').css({"transform":"scale(1)","transition": "all 0.6s ease 0s"});
  });
  $(".lpf-new-list-list> .lpf-new-list-list-content> .lpf-left> .lpf-list>.lpf-page> ul> li").hover(function(){
    $(this).children('.lpf-link-a').css({"color":"#ffffff"});
},function(){
  $(this).children('.lpf-link-a').css({"color":"#3a3a3a"});
});
  }
}
export { _INFORMATION }
