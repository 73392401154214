<template>
  <div class="page">
    <div class="lpf-content">
      <div class="container-fluid lpf-container-fluid">
        <vHeader></vHeader>
        <vSidebar @lpf_window_onresize="lpf_window_onresize"></vSidebar>
        <div class="lpf-content-main">
          <Slideshow ref="slideshow"></Slideshow>
          <EducationIntro ref="educationIntro"></EducationIntro>
          <DanceType ref="danceType"></DanceType>
          <HonorQualification ref="honorQualification"></HonorQualification>
          <BrandAdvantage ref="brandAdvantage"></BrandAdvantage>
          <TutorQualification  ref="tutorQualification"></TutorQualification>
          <TeachingResults></TeachingResults>
          <InformationMsg></InformationMsg>
        </div>
        <vFooter></vFooter>
      </div>
    </div>
  </div>
</template>
<script>
import { vHeader, vFooter, vSidebar } from "../layout/index.js";
import Slideshow from "./slideshow.vue";
import EducationIntro from "./education_intro.vue";
import DanceType from "./dance_type.vue";
import HonorQualification from "./honor_qualification.vue";
import BrandAdvantage from "./brand_advantage.vue";
import TutorQualification from "./tutor_qualification.vue";
import TeachingResults from "./teaching_results.vue";
import InformationMsg from "./information_msg.vue";
export default {
  name: "Admin",
  components: {
    vHeader,
    vFooter,
    vSidebar,
    Slideshow,
    EducationIntro,
    DanceType,
    HonorQualification,
    BrandAdvantage,
    TutorQualification,
    TeachingResults,
    InformationMsg,
  },
  data: function () {
    return {};
  },
  created(){
    this._CONFIG.colorNavc = 'color';
    },
  mounted: function () {
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new this.WOW({});
      wow.init();
    });
    // let that = this;
    // //窗口缩放触发的函数
    // window.onresize = function () {
    //   that.lpf_window_onresize();
    // };
    // //窗口第一次加载完成触发的函数
    // $(document).ready(function () {
    //   that.lpf_window_onresize();
    // });
  },
  methods: {
    lpf_window_onresize(paramater) {
      this.$refs.slideshow.lpf_window_onresize();
      this.$refs.educationIntro.lpf_window_onresize();
      this.$refs.danceType.lpf_window_onresize();
      this.$refs.honorQualification.lpf_window_onresize();
      this.$refs.brandAdvantage.lpf_window_onresize();
      this.$refs.tutorQualification.lpf_window_onresize(paramater);
    },
  },
};
</script>
<style lang="scss">
.lpf-list-1 {
  background-color: $edu-background-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-list-2 {
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-list-1 > .lpf-list-1-title,
.lpf-list-1 > .lpf-list-1-content,
.lpf-list-2 > .lpf-list-2-title,
.lpf-list-2 > .lpf-list-2-content {
  width: 100%;
  max-width: $body-width-content;
  text-align: center;
  overflow: hidden;
}
.lpf-list-1 > .lpf-list-1-title,
.lpf-list-2 > .lpf-list-2-title {
  margin-bottom: 1rem;
}
.lpf-list-1 > .lpf-list-1-title > .lpf-title > h1,
.lpf-list-2 > .lpf-list-2-title > .lpf-title > h1 {
  color: $edu-color-h1;
  font-size: 4rem;
  margin-top: 4rem;
}
</style>
