<template>
  <div class="page">
    <vSidebar></vSidebar>
    <!--右侧内容区域-->
    <div class="lpf-content">
      <div class="container-fluid lpf-container-fluid">
        <vHeader></vHeader>
      </div>
    </div>
  </div>
</template>
<script>
import { vHeader, vFooter, vSidebar } from "../layout/index.js";
export default {
  name: "Admin",
  components: {
    vHeader,
    vFooter,
    vSidebar,
  },
  data: function () {
    return {
      lpf_type: 1,
    };
  },
   watch: {
    $route(to, from) {
      //监听路由参数变化
      if (this.$route.query.type) {
        // 参数变化 复用 mounted里面的方法
        this.lpf_reload_data();
      }
    },
  },
  mounted: function () {
    this.lpf_reload_data();
  },
  methods: {
    lpf_reload_data() {
      this.lpf_type = this.$route.query.type;
      document.title =
        this.lpf_type == 1
          ? document.title + "--付费"
          : document.title + "--免费";
    },
  },
};
</script>
<style lang="scss">
</style>
