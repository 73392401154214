/*
 *
 * @description: 公用的 data 和 methods v0.01
 * @author: https://www.51xuediannao.com/
 * @update: 2020-02-28 18:02
 */
import { util } from '../utils/util.js';
import Service from '../services/services.js'
import { _CONFIG } from '../config/config.js';
let _data = {
  showPop: false,
  showNoData: false,
  listData: [],
  page: 1,
  pageSize: 20,
  pageCount: 1,
  currentPage: 1,
  loadingPages: true,
  loadMoreLoading: true,
  dataInit: true,
  pageEnd: false,
  isLoad: true,
  Loading: false
};

let _methods = {
  getCate(ids) {
    this.listData = localStorage.getItem('list') ? JSON.parse(localStorage.getItem('list')) : []
    let array = []
    ids.split(',').forEach(item => {
      let current = this.listData.find(i => {
        return item == i.id
      })
      if (current != undefined) {
        array.push(current)
      }

    });
    return array
  },
  getApp(key) {
    let obj = this.GlobalData;
    if (key && typeof obj[key] === 'undefined') {
      return null
    }
    return key ? obj[key] : obj;
  },
  setApp(obj = {}) {
    this.GlobalData = util._extends(true, this.GlobalData, obj);
    return this;
  },
  downloadFile(data, type = '') {
    this.Loading = true
    let tid = 0;
    let id = 0;
    if (type === 'app') {
      tid = data.category;
      id = data.Id;
    } else {
      tid = data.typeid;
      id = data.Id;
    }
    Service.getDownUrl({ id: id }).then(res => {
      this.Loading = false
      if (res.Flag) {
        console.log(res.data[0].file_url)
        if (res.data[0].file_url === '') {
          this.$Modal.confirm({
            title: '温馨提示',
            content: '资源走丢了~如需要请联系管理员',
            cancelText: '取消',
          });
          return
        }
        window.location.href = _CONFIG.apiPath + res.data[0].file_url;
      } else {
        this.$Modal.confirm({
          title: '温馨提示',
          content: res.Content,
          cancelText: '取消',
        });
      }
    })
  },
  //判断字符是否为空的方法
  isEmpty: function (obj) {
    if (typeof obj == "undefined" || obj == null || obj == "" || obj == '') {
      return true;
    } else {
      return false;
    }
  },
};

export {
  _data,
  _methods,
}
