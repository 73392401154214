const _ABOUT = {
  lpf_image_bili:310/248,//图片比例
  lpf_window_onresize: function (paramater) {
    let str = ".lpf-about-company-whts > .lpf-about-qywh > .lpf-about-qywh-content > .lpf-about-qywh-content-item>.item>.lpf-image";
    let height = $(str).width()/this.lpf_image_bili;
    $(str).css({ height: height + "px" });

  },
  
}
export { _ABOUT }
