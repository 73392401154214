<template>
<div class="block">
            <div class="lpf-jazz-condition">
              <div class="row lpf-jazz-condition-title">
                <div class="col-xs-12 lpf">
                  <div class="lpf-title-left">零基础可学·无限制条件</div>
                </div>
              </div>
              <div class="row lpf-jazz-condition-image">
                <div class="col-xs-12 lpf">
                  <fr-image
                    class="lpf-image"
                    src="https://www.dansewudao.com/skin/img/wz/jazz/ljc.jpg"
                    mode=""
                    loading-ing-img="two-balls"
                    :loading-error-img="
                      require('@/static/imgs/home/loading_error.png')
                    "
                  />
                </div>
              </div>
            </div>
          </div>
</template>

<script>
export default {
  name: "lpfCondition",
  data() {
    return {
      lpf_image_bili2:1200/466,//图片比例
    };
  },
  props: {},
  mounted() {},
  methods: {
    lpf_window_onresize: function (paramater) {
     let str2 = ".lpf-jazz-condition > .lpf-jazz-condition-image>.lpf>.lpf-image";
    let height2 = $(str2).width()/this.lpf_image_bili2;
    $(str2).css({ height: height2 + "px" });
    },
  },
};
</script>

<style lang="scss">
.lpf-jazz-condition {
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-jazz-condition > .lpf-jazz-condition-title {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 3rem auto 2.5rem;
  overflow: hidden;
}
.lpf-jazz-condition > .lpf-jazz-condition-title > .lpf > .lpf-title-left {
  font-size: 4rem;
  color: $footer-background-color;
}
.lpf-jazz-condition > .lpf-jazz-condition-image {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 0 auto 2.5rem;
  overflow: hidden;
}
.lpf-jazz-condition > .lpf-jazz-condition-image > .lpf > .lpf-image {
  width: 100%;
}
</style>