import axios from 'axios';

// 构建axios实例
const $http = axios.create({
    // baseURL: process.env.BASE_API,  // 该处url会根据开发环境进行变化（开发/发布）
    // timeout: 10000  // 设置请求超时连接时间
});
//请求发送后端之前
$http.interceptors.request.use(
  config => {
      //console.log(config);
      config.headers['content-type'] = 'application/x-www-form-urlencoded';
      // config.headers['Cache-Control'] = 'no-cache';
      config.headers.Authorization = localStorage.getItem('TOKEN') || 'head';
      return config  //对config处理完后返回，下一步将向后端发送请求
  },
  error => {
      //当发生错误时，执行该部分代码
      console.log('request-error',error);
      return Promise.reject(error)
  }
);
//数据返回后，具体页面加载之前
$http.interceptors.response.use(
  response => {
      //console.log('response',response);
      const res = response.data;
      if ('正常情况') {
          return response  // 该处将结果返回，下一步可用于前端页面渲染用
      } else {
          alert('该处异常');
          return Promise.reject('error')
      }
  },
  error => {
      //console.log('response-error',error.response);
      if(error.response.status===401){
         localStorage.clear();
         location.reload();
      }
      return Promise.reject(error)
  }
);

export {
  $http
}
