<template>
 <div class="block">
            <div class="lpf-jazz-teach">
              <div class="row lpf-jazz-teach-title">
                <div class="col-xs-12 lpf">
                  <div class="lpf-title-left">丰富的教学内容</div>
                  <router-link to="/jazz/coach" class="lpf-link-a"
                    >查看完整课程 ></router-link
                  >
                </div>
              </div>
              <div class="row lpf-jazz-teach-content">
                <div class="col-xs-12 lpf">
                  <ul class="lpf-ul">
                    <li class="lpf-li">
                      <div class="lpf-img">
                        <fr-image
                          class="lpf-image"
                          src="https://www.dansewudao.com//skin/img/wz/jazz/jx1.jpg"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                        />
                      </div>
                      <div class="lpf-txt">
                        <h3>第一阶段</h3>
                        <p>
                          舞者素质培训／舞蹈理论知识<br />
                          教室基本职业素养培训<br />
                          初级自主扣舞技能训练／初级自主编舞能力培训<br />
                          初级教学模拟<br />
                          8个HIPHOP固定元素教学<br />
                          HIPHOP基本律动训练<br />
                          ............
                        </p>
                        <router-link class="lpf-link-a" to="/jazz/coach"
                          >阅读更多</router-link
                        >
                      </div>
                    </li>
                    <li class="lpf-li">
                      <div class="lpf-img">
                        <fr-image
                          class="lpf-image"
                          src="https://www.dansewudao.com//skin/img/wz/jazz/jx2.jpg"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                        />
                      </div>
                      <div class="lpf-txt">
                        <h3>第二阶段</h3>
                        <p>
                          舞蹈文化理论知识教学<br />
                          课堂设计理论知识模拟演练<br />
                          深入教学方法演练／教学模拟<br />
                          深入精细自主扣舞方法总结<br />
                          编舞思维发散引导<br />
                          流行舞形体训练／软开度要求／强化学员身体素质／基本功运用<br />
                          ............
                        </p>
                        <router-link class="lpf-link-a" to="/jazz/coach"
                          >阅读更多</router-link
                        >
                      </div>
                    </li>
                    <li class="lpf-li">
                      <div class="lpf-img">
                        <fr-image
                          class="lpf-image"
                          src="https://www.dansewudao.com//skin/img/wz/jazz/jx3.jpg"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                        />
                      </div>
                      <div class="lpf-txt">
                        <h3>第三阶段</h3>
                        <p>
                          舞蹈文化知识扩散训练<br />
                          HIPHOP／JAZZ基本功高阶强化<br />
                          软开度要求<br />
                          身体素质及身体质感挖掘开发<br />
                          力量转换及融合训练／<br />
                          复合基本功组合混合训练<br />
                          设定主题／音乐／情节的编舞训练<br />
                          ............
                        </p>
                        <router-link class="lpf-link-a" to="/jazz/coach"
                          >阅读更多</router-link
                        >
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
</template>

<script>
export default {
  name: "lpfTeach",
  data() {
    return {
      lpf_image_bili1:386/252,//图片比例
    };
  },
  props: {},
  mounted() {},
  methods: {
    lpf_window_onresize: function (paramater) {
      let str1 = ".lpf-jazz-teach > .lpf-jazz-teach-content>.lpf>.lpf-ul>.lpf-li>.lpf-img>.lpf-image";
    let height1 = $(str1).width()/this.lpf_image_bili1;
    $(str1).css({ height: height1 + "px" });
    },
  },
};
</script>

<style lang="scss">
.lpf-jazz-teach {
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-jazz-teach > .lpf-jazz-teach-title {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 3rem auto 2.5rem;
  overflow: hidden;
}
.lpf-jazz-teach > .lpf-jazz-teach-title > .lpf > .lpf-title-left {
  font-size: 4rem;
  color: $footer-background-color;
  float: left;
  text-align: center;
}
.lpf-jazz-teach > .lpf-jazz-teach-title > .lpf > .lpf-link-a {
  font-size: 3rem;
  color: $edu-color-a;
  float: right;
}
.lpf-jazz-teach > .lpf-jazz-teach-content {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 0 auto 2.5rem;
  overflow: hidden;
}
.lpf-jazz-teach > .lpf-jazz-teach-content > .lpf > .lpf-ul {
  overflow: hidden;
}
.lpf-jazz-teach > .lpf-jazz-teach-content > .lpf > .lpf-ul > .lpf-li {
  width: 32%;
  float: left;
  margin-right: 2%;
  padding-bottom: 0.5rem;
}
.lpf-jazz-teach
  > .lpf-jazz-teach-content
  > .lpf
  > .lpf-ul
  > .lpf-li:nth-child(3n) {
  margin-right: 0;
}
.lpf-jazz-teach
  > .lpf-jazz-teach-content
  > .lpf
  > .lpf-ul
  > .lpf-li
  > .lpf-img {
  width: 100%;
}
.lpf-jazz-teach
  > .lpf-jazz-teach-content
  > .lpf
  > .lpf-ul
  > .lpf-li
  > .lpf-img
  > .lpf-image {
  width: 100%;
  border: none;
  vertical-align: middle;
}
.lpf-jazz-teach
  > .lpf-jazz-teach-content
  > .lpf
  > .lpf-ul
  > .lpf-li
  > .lpf-txt {
  width: 90%;
  margin: 1.5rem auto;
}
.lpf-jazz-teach
  > .lpf-jazz-teach-content
  > .lpf
  > .lpf-ul
  > .lpf-li
  > .lpf-txt
  > h3 {
  font-size: 2.4rem;
  color: $sidebar-background-jazz;
}
.lpf-jazz-teach
  > .lpf-jazz-teach-content
  > .lpf
  > .lpf-ul
  > .lpf-li
  > .lpf-txt
  > p {
  font-size: 2rem;
  color: $edu-color-h6;
  line-height: 3.5rem;
  margin-top: 1rem;
}
.lpf-jazz-teach
  > .lpf-jazz-teach-content
  > .lpf
  > .lpf-ul
  > .lpf-li
  > .lpf-txt
  > .lpf-link-a {
  display: block;
  width: 13.3rem;
  height: 3.6rem;
  line-height: 3.6rem;
  background: $sidebar-background-color;
  color: $white-color;
  font-size: 1.8rem;
  border-radius: 2rem;
  text-align: center;
  margin-top: 2.5rem;
}
@media (max-width: 1090px) {
  .lpf-jazz-teach > .lpf-jazz-teach-content > .lpf > .lpf-ul > .lpf-li {
    width: 49%;
    margin-right: 2%;
  }
  .lpf-jazz-teach
    > .lpf-jazz-teach-content
    > .lpf
    > .lpf-ul
    > .lpf-li:nth-child(2n) {
    margin-right: 0;
  }
}
@media (max-width: 760px) {
  .lpf-jazz-teach > .lpf-jazz-teach-content > .lpf > .lpf-ul > .lpf-li {
    width: 100%;
    margin-right: 2%;
  }
}
</style>