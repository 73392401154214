<template>
 <div class="lpf-sidebar-backview">
    <div class="lpf-sidebar">
    <div class="row lpf-main-sidebar">
      <ul class="lpf-navc">
        <li v-for="(item, index) in lpf_sidebar" :key="index">
          <router-link class="lpf-link-a" :to="item.href">{{ item.title }}</router-link>
          <div class="lpf_about_view" v-if="item.chirlds">
            <router-link class="lpf-link-a" :to="item_sub.href" v-for="(item_sub, index_sub) in item.chirlds" :key="index_sub">
              {{ item_sub.title }}
            </router-link>
          </div>
        </li>
      </ul>
    </div>
  </div>
 </div>
</template>
<script>
import { _SIDEBAR } from "../src/js/layout/sidebar";
export default {
  name: "Sidebar",
  props: {},
  data() {
    return {
      lpf_sidebar: [
        { title: "首页" ,href: "/"},
        {
          title: "蓝风铃",
          href: "/about",
          chirlds: [
            { title: "品牌简介" ,href: "/about/danse"},
            { title: "全国分馆" ,href: "/campus"},
            { title: "媒体报道" ,href: "/information/news"},
            { title: "荣誉资质" ,href: "/about/qualifications"},
            { title: "联系我们" ,href: "/about/contact"},
          ],
        },
        { title: "爵士舞" ,href: "/jazz"},
        { title: "肚皮舞" ,href: "/belly"},
        { title: "拉丁舞" ,href: "/latin"},
        { title: "中国舞" ,href: "/china"},
        { title: "街舞" ,href: "/hop"},
        { title: "瑜伽" ,href: "/yoga"},
        { title: "少儿舞" ,href: "/children"},
        { title: "教练班" ,href: "/coach"},
        { title: "商业合作" ,href: "/coopera"},
        { title: "新闻资讯" ,href: "/information/news"},
      ],
    };
  },
  mounted: function () {
    _SIDEBAR.lpf_select_li(this._CONFIG.colorNavc);
    _SIDEBAR.lpf_scrollview();
    let that = this;
    _SIDEBAR.lpf_window_onresize((paramater)=>{
      that.$emit('lpf_window_onresize',paramater);
    });
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
// @import '../src/css/style.scss'; 单独引用scss
.lpf-sidebar {
  background-color: $sidebar-background-color;
  display: flex;
  justify-content: center;
  z-index: 100;
}
.lpf-main-sidebar {
  width: 100%;
  max-width: 1200px;
  position: relative;
}
.lpf-navc {
  min-height: 8rem;
  list-style: none;
  align-items: center;
  margin: 0 !important;
}
.lpf-navc>li {
  display: flex;
  float: left;
  width: 8rem;
  height: 8rem;
  line-height: 8rem;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
}
.lpf-navc li>.lpf-link-a {
  color: $white-color;
  font-size: 1.8rem;
  width: 100%;
  height: 100%;
}
.lpf_about_view{
  position: absolute;
  top: 8rem;
  left: 0;
  background-color: #0bb5ee;
  display: none;
  z-index: 100;
}
.lpf_about_view>.lpf-link-a{
  line-height: 4.2rem;
  padding: 0 1rem;
  font-size: 1.4rem;
  float: left;
  color: $white-color;
}
@media (min-width: 922px) {
  .lpf-navc>li {
    width: 8.333%;
  }
}
</style>
