import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import login from '../views/login/index'

import home from '../views/home/index/index'
import about from '../views/home/about/index'
import qualifications from '../views/home/about/qualifications'
import contact from '../views/home/about/contact'
import jazz from '../views/home/jazz/index'
import belly from '../views/home/belly/index'
import latin from '../views/home/latin/index'
import china from '../views/home/china/index'
import hop from '../views/home/hop/index'
import yoga from '../views/home/yoga/index'
import children from '../views/home/children/index'
import coach from '../views/home/coach/index'
import coopera from '../views/home/coopera/index'
import information from '../views/home/information/index'
import campus from '../views/home/campus/index'

import admin from '../views/admin/index/index'
import students from '../views/admin/student/index.vue'
import teachers from '../views/admin/teacher/index.vue'
import links from '../views/admin/link/index.vue'
import system from '../views/admin/system/index.vue'


import member from '../views/member/index/index'

Vue.use(VueRouter)

const routes_home = [
  {
    path: '/',
    name: 'Home',
    component: home,
    meta: {
      title: '蓝风铃舞蹈',
      requireAuth: false// 判断是否需要登录
    }
  },
  {
    path: '/about',
    name: 'About',
    component: about,
    meta: {
      title: '蓝风铃简介',
      requireAuth: false// 判断是否需要登录
    }
  },
  {
    path: '/about/danse',
    name: 'About_danse',
    component: about,
    meta: {
      title: '品牌简介-蓝风铃舞蹈',
      requireAuth: false// 判断是否需要登录
    }
  },
  {
    path: '/about/qualifications',
    name: 'About_qualifications',
    component: qualifications,
    meta: {
      title: '荣誉资质-蓝风铃舞蹈',
      requireAuth: false// 判断是否需要登录
    }
  },
  {
    path: '/about/contact',
    name: 'About_contact',
    component: contact,
    meta: {
      title: '联系我们-蓝风铃舞蹈',
      requireAuth: false// 判断是否需要登录
    }
  },
  {
    path: '/campus',
    name: 'Campus',
    component: campus,
    meta: {
      title: '全国分馆-蓝风铃舞蹈',
      requireAuth: false// 判断是否需要登录
    }
  },
  {
    path: '/jazz',
    name: 'Jazz',
    component: jazz,
    meta: {
      title: '爵士舞-蓝风铃舞蹈',
      requireAuth: false// 判断是否需要登录
    }
  },
  {
    path: '/belly',
    name: 'Belly',
    component: belly,
    meta: {
      title: '肚皮舞-蓝风铃舞蹈',
      requireAuth: false// 判断是否需要登录
    }
  },
  {
    path: '/latin',
    name: 'Latin',
    component: latin,
    meta: {
      title: '拉丁舞-蓝风铃舞蹈',
      requireAuth: false// 判断是否需要登录
    }
  },
  {
    path: '/china',
    name: 'China',
    component: china,
    meta: {
      title: '中国舞-蓝风铃舞蹈',
      requireAuth: false// 判断是否需要登录
    }
  },
  {
    path: '/hop',
    name: 'Hop',
    component: hop,
    meta: {
      title: '街舞-蓝风铃舞蹈',
      requireAuth: false// 判断是否需要登录
    }
  },
  {
    path: '/yoga',
    name: 'Yoga',
    component: yoga,
    meta: {
      title: '瑜伽-蓝风铃舞蹈',
      requireAuth: false// 判断是否需要登录
    }
  },
  {
    path: '/children',
    name: 'Children',
    component: children,
    meta: {
      title: '少儿舞-蓝风铃舞蹈',
      requireAuth: false// 判断是否需要登录
    }
  },
  {
    path: '/coach',
    name: 'Coach',
    component: coach,
    meta: {
      title: '教练班-蓝风铃舞蹈',
      requireAuth: false// 判断是否需要登录
    }
  },
  {
    path: '/coopera',
    name: 'Coopera',
    component: coopera,
    meta: {
      title: '商业合作-蓝风铃舞蹈',
      requireAuth: false// 判断是否需要登录
    }
  },
  {
    path: '/information/news',
    name: 'Information',
    component: information,
    meta: {
      title: '新闻资讯-蓝风铃舞蹈',
      requireAuth: false// 判断是否需要登录
    }
  },
  {
    path: '/information/news',
    name: 'Information_newa',
    component: information,
    meta: {
      title: '媒体报道-蓝风铃舞蹈',
      requireAuth: false// 判断是否需要登录
    }
  }
]

const routes_admin = [
  {
    path: '/login',
    name: 'Login',
    component: login,
    meta: {
      title: '登录'
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: admin,
    meta: {
      title: '管理员-首页',
      requireAuth: true// 判断是否需要登录
    }
  },
  {
    path: '/students',
    name: 'Students',
    component: students,
    meta: {
      title: '学生信息'
    }
  },
  {
    path: '/teachers',
    name: 'Teachers',
    component: teachers,
    meta: {
      title: '教师信息'
    }
  },
  {
    path: '/links',
    name: 'Links',
    component: links,
    meta: {
      title: '超级链接'
    }
  },
  {
    path: '/system',
    name: 'System',
    component: system,
    meta: {
      title: '系统设置'
    }
  }
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]
const routes_member = [
  {
    path: '/member',
    name: 'Member',
    component: member,
    meta: {
      title: '会员-首页',
      requireAuth: true// 判断是否需要登录
    }
  }
]

// mode: 'history', 加上这个可以不出现#
const router = new VueRouter({
  base: process.env.BASE_URL,
  routes: routes_home
})

export default router
