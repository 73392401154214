<template>
  <div class="block">
    <div class="lpf-list-2 lpf-informat-msg">
      <div class="row lpf-list-2-title lpf-info-title">
        <div class="wow bounceIn lpf-title">
          <h1></h1>
          <p></p>
        </div>
      </div>
      <div class="row lpf-list-2-content lpf-info-content">
        <div class="wow bounceInLeft col-md-12 col-lg-8 lpf-info-left">
          <div class="lpf-info-left-title">
            <div class="lpf-info-left-title-left">
              <span class="iconfont">&#xe61e;</span>
              新闻资讯
            </div>
            <div class="lpf-info-left-title-right">
              <router-link class="lpf-link-a" to="">更多》</router-link>
            </div>
          </div>
          <div class="row lpf-info-left-content">
            <div class="col-sm-12 col-md-6 lpf-info-left-content-left">
              <router-link class="lpf-link-a" to="">
                <fr-image
                  class="lpf-image"
                  src="https://www.dansewudao.com/uploads/190814/8-1ZQ4141115313.jpg"
                  mode=""
                  loading-ing-img="two-balls"
                  :loading-error-img="
                    require('@/static/imgs/home/loading_error.png')
                  "
                />
                <h2>喜报丨蓝风铃少儿拉丁舞学员国际锦标赛再创佳绩</h2>
              </router-link>
            </div>
            <div class="col-sm-12 col-md-6 lpf-info-left-content-right">
              <div class="des">
                单色学子凌云志，硕果飘香又一年！
                日前，在长沙举办的2019国际标准舞长沙邀请赛暨第29届国际标准舞锦标赛比赛结果
              </div>
              <router-link class="lpf-link-a" to="">
                <h3>首届“单色杯”国标舞友谊赛成功举</h3>
                <p>
                  2019年12月17日，由单色舞蹈主办的首届单色杯国标舞友谊赛于汉商银座知音站台成功举办。
                </p>
              </router-link>
              <router-link class="lpf-link-a" to="">
                <h3>单色舞蹈冬季双馆联合少儿汇演圆满</h3>
                <p>
                  近日，单色舞蹈武汉地区第8、9场少儿汇演在光谷客艺剧场成功举办。汇演分为上下午两场，上午场
                </p>
              </router-link>
              <router-link class="lpf-link-a" to="">
                <h3>单色瑜伽学员在省瑜伽锦标赛上斩获</h3>
                <p>
                  2019年11月9日，湖北省瑜伽锦标赛暨首届健身瑜伽马拉松活动圆满结束，单色瑜伽的学员们在
                </p>
              </router-link>
            </div>
          </div>
        </div>
        <div class="wow bounceInRight col-md-12 col-lg-4 lpf-info-right">
          <div class="lpf-info-right-title">
            <div class="lpf-info-right-title-left">
              <span class="iconfont">&#xe620;</span>
              舞蹈常识
            </div>
            <div class="lpf-info-right-title-right">
              <router-link class="lpf-link-a" to="">更多》</router-link>
            </div>
          </div>
          <div class="lpf-info-right-content">
            <div>
              <router-link class="lpf-link-a" to="">
              <h2>成都哪里有培训舞蹈教练的</h2>
              <div class="des">
                舞蹈是越来越流行的娱乐文化，在成都也有不少人想通过学习舞蹈增加自己的生活乐趣，并且舞蹈还能够成为一种
              </div>
            </router-link>
            </div>
            <div>
              <router-link class="lpf-link-a" to="">
              <h2>长沙产后修复瑜伽培训班哪家好</h2>
              <div class="des">
                瑜伽是十分健康的运动，但是自己在家学瑜伽容易不到位，并且一些动作可能会对身体造成伤害。那么在专业培训
              </div>
            </router-link>
            </div>
            <div>
              <router-link class="lpf-link-a" to="">
              <h2>成都有单色舞蹈吗?成都单色舞蹈靠</h2>
              <div class="des">
                单色舞蹈是全国连锁的舞蹈品牌，很多舞蹈爱好者都选择在这里学习舞蹈。那么在成都有单色舞蹈学校吗？成都单
              </div>
            </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "InformationMsg",
  data: function () {
    return {};
  },
  mounted: function () {},
  methods: {
    lpf_window_onresize() {},
  },
};
</script>
<style lang="scss">
// .lpf-list-1 {
//   background-color: $edu-background-color;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// }
// .lpf-list-1 > .lpf-list-1-title,
// .lpf-list-1 > .lpf-list-1-content {
//   width: 100%;
//   max-width: $body-width-content;
//   text-align: center;
//   overflow: hidden;
// }
// .lpf-list-1 > .lpf-list-1-title {
//   margin-bottom: 1rem;
// }
// .lpf-list-1 > .lpf-list-1-title > .lpf-title > h1 {
//   color: $edu-color-h1;
//   font-size: 4rem;
//   margin-top: 4rem;
// }
.lpf-info-content {
  box-sizing: border-box;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 2rem;
}
.lpf-info-content > div {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.lpf-info-content > .lpf-info-left {
  padding-right: 2rem !important;
}
.lpf-info-content > div > .lpf-info-left-title,
.lpf-info-content > div > .lpf-info-right-title {
  padding-bottom: 1.5rem;
  border-bottom: 0.2rem solid $sidebar-background-color;
  line-height: 3rem;
  overflow: hidden;
}
.lpf-info-content > div > .lpf-info-left-content > .lpf-info-left-content-left,
.lpf-info-content
  > div
  > .lpf-info-left-content
  > .lpf-info-left-content-right {
  overflow: hidden;
  margin-top: 15px;
}
.lpf-info-content
  > div
  > .lpf-info-left-content
  > .lpf-info-left-content-left
  > a {
  width: 100%;
  cursor: pointer;
  text-decoration: none;
}
.lpf-info-content
  > div
  > .lpf-info-left-content
  > .lpf-info-left-content-left:hover {
  background-color: $background1-opacity;
}
.lpf-info-content
  > div
  > .lpf-info-left-content
  > .lpf-info-left-content-left
  > .lpf-link-a
  > .lpf-image {
  width: 100%;
  height: 24.8rem;
  vertical-align: middle;
}
.lpf-info-content
  > div
  > .lpf-info-left-content
  > .lpf-info-left-content-left
  > .lpf-link-a
  > .lpf-image
  > .fr_img_content {
  left: 0;
  top: 0;
}
.lpf-info-content
  > div
  > .lpf-info-left-content
  > .lpf-info-left-content-left
  > .lpf-link-a
  > h2 {
  color: $sidebar-background-color;
  font-size: 2.1rem;
  line-height: 3rem;
  margin-top: 1rem;
  text-align: left !important;
}
.lpf-info-content
  > div
  > .lpf-info-left-content
  > .lpf-info-left-content-right
  > .des {
  color: $edu-color;
  font-size: 1.6rem;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.lpf-info-content
  > div
  > .lpf-info-left-content
  > .lpf-info-left-content-right
  > .lpf-link-a {
  width: 100%;
  display: block;
  padding-top: 2.5rem;
  text-decoration: none;
  cursor: pointer;
}
.lpf-info-content
  > div
  > .lpf-info-left-content
  > .lpf-info-left-content-right
  > .lpf-link-a:hover {
  background-color: $background1-opacity;
}
.lpf-info-content
  > div
  > .lpf-info-left-content
  > .lpf-info-left-content-right
  > .lpf-link-a
  > h3 {
  color: $edu-color-span;
  font-size: 1.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
  text-align: left;
  margin-top: 0!important;
}
.lpf-info-content
  > div
  > .lpf-info-left-content
  > .lpf-info-left-content-right
  > .lpf-link-a
  > p {
    text-align: left;
  color: $edu-color;
  font-size: 1.5rem;
  margin-top: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.lpf-info-content > div > .lpf-info-left-title > .lpf-info-left-title-left,
.lpf-info-content > div > .lpf-info-right-title > .lpf-info-right-title-left {
  float: left;
  color: $sidebar-background-color;
  font-size: 2rem;
  font-weight: bold;
}
.lpf-info-content
  > div
  > .lpf-info-left-title
  > .lpf-info-left-title-left
  > span,
.lpf-info-content
  > div
  > .lpf-info-right-title
  > .lpf-info-right-title-left
  > span {
  font-size: 2.1rem;
}
.lpf-info-content > div > .lpf-info-right-title > .lpf-info-right-title-left {
  color: $edu-color-h1 !important;
}
.lpf-info-content
  > div
  > .lpf-info-right-title
  > .lpf-info-right-title-left
  > span {
  color: $sidebar-background-color !important;
}
.lpf-info-content > div > .lpf-info-left-title > .lpf-info-left-title-right,
.lpf-info-content > div > .lpf-info-right-title > .lpf-info-right-title-right {
  float: right;
}
.lpf-info-content > div > .lpf-info-left-title > .lpf-info-left-title-right > .lpf-link-a,
.lpf-info-content
  > div
  > .lpf-info-right-title
  > .lpf-info-right-title-right
  > div> .lpf-link-a {
  color: $edu-color-a;
  font-size: 1.6rem;
  margin-top: 0;
  text-decoration: none;
  cursor: pointer;
}
.lpf-info-content > div >.lpf-info-right-content>div>.lpf-link-a{
width: 100%;
text-decoration: none;
cursor: pointer;
}
.lpf-info-content > div >.lpf-info-right-content>div:hover {
  background-color: $background1-opacity;
}
.lpf-info-content > div >.lpf-info-right-content>div>.lpf-link-a>h2{
font-size: 18px;
    margin-bottom: 1.5rem;
color: $sidebar-background-color;
    line-height: 3rem;
    margin-top: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
}
.lpf-info-content > div >.lpf-info-right-content{
  overflow: hidden;
}
.lpf-info-content > div >.lpf-info-right-content>div>.lpf-link-a>.des{
color: $edu-color;
    font-size: 1.6rem;
    overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: left;
}
@media (max-width: 1199px) {
  .lpf-info-content > .lpf-info-left {
    padding-right: 0 !important;
  }
}
@media (max-width: 1254px) {
  .lpf-info-content {
    padding: 0 2rem;
  }
}
</style>
