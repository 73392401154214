import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { _CONFIG } from './config/config.js'
import { util } from './utils/util.js'
import { getToken, removeToken } from './utils/auth'
import service from './services/services'
// 引入wow
import 'animate.css'// 版本不能太高，不然没有效果
import { WOW } from 'wowjs'
// 引入echarts
// import echarts from 'echarts'
import * as echarts from 'echarts';
// 引入jQuery、bootstrap
import $ from 'jquery'
import 'bootstrap'
// 引入bootstrap样式
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
// 引入element-ui 需要在下面use一下
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// 引入懒加载 需要在下面注册
import FrImage from '@/components/fr-image/fr-image.vue'
// 引入 swiper 版本不能太高
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
import 'swiper/js/swiper.min.js'
// 全局注册 $
Vue.prototype.$ = $

Vue.config.productionTip = false

Vue.prototype.Swiper = Swiper
Vue.prototype.WOW = WOW
Vue.prototype._CONFIG = _CONFIG
Vue.prototype._UTIL = util
Vue.prototype._SERVICE = service
Vue.prototype.$echarts = echarts
Vue.prototype.layer = layui.layer;
Vue.use(ElementUI)
Vue.component('FrImage',FrImage)

// 路由判断登录 根据路由配置文件的参数
router.beforeEach((to, from, next) => {
  // 路由发生变化修改页面title
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.matched.some(record => record.meta.requireAuth)) { // 判断该路由是否需要登录权限
    if (getToken()) { // 判断当前的token是否存在 ； 登录存入的token
      if (to.path === '/login') {
        removeToken()
        window.location.reload()
      } else {
        next()
      }
    } else {
      next({
        path: '/login',
        query: {} // 将跳转的路由path作为参数，登录成功后跳转到该路由query: { redirect: 'to.fullPath' }
      })
    }
  } else {
    next()
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
