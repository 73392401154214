const _COACH = {
  lpf_image_bili:1920/725,//图片比例
  lpf_image_bili1:384/286,//图片比例
  lpf_base_biliT:384/283,//店馆比例
  lpf_image_bili2:177/272,//图片比例
  lpf_image_bili3:565/380,//图片比例
  lpf_image_bili4:650/500,//图片比例
  lpf_window_onresize: function (paramater) {
    let str = ".lpf-coach-banner > .lpf-coach-banner-content>.lpf-link-a>.lpf-image";
    let height = $(str).width()/this.lpf_image_bili;
    $(str).css({ height: height + "px" });
    let str1 = ".lpf-coach-class>.lpf-coach-class-content >.lpf-li>.lpf-link-a>.lpf-image";
    let height1 = $(str1).width()/this.lpf_image_bili1;
    $(str1).css({ height: height1 + "px" });
    let baseT = ".lpf-coach-base > .lpf-coach-base-content>.lpf-li>.lpf-link-a>.lpf-top";
    let heightT = $(baseT).width()/this.lpf_base_biliT;
    $(baseT).css({ height: heightT + "px" });
    let str2 = ".lpf-coach-base > .lpf-coach-base-content>.lpf-li>.lpf-link-a>.lpf-top>.lpf-right>.lpf-image";
    let heightI = $(str2).width()/this.lpf_image_bili2;
    $(str2).css({ height: heightI + "px" });
    let str3 = ".lpf-coach-train > .lpf-coach-train-content >.lpf-train-intro>.lpf-right>.lpf-image";
    let height3 = $(str3).width()/this.lpf_image_bili3;
    $(str3).css({ height: height3 + "px" });
    let str4 = ".lpf-coach-jxtd > .lpf-coach-jxtd-content>.lpf-right>.lpf-image";
    let height4 = $(str4).width()/this.lpf_image_bili4;
    $(str4).css({ height: height4 + "px" });
    

  },
}
export { _COACH }
