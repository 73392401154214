const _TEACH = {
  initSwiper: function (Swiper,number) {
    let effect = 1;
    let slidesPerView = number;
    var swiper = new Swiper(".lpf-swiper-container-teach", {
      loop: true, //开启循环
      speed: 500, //动画时间
      grabCursor: true, //鼠标变成手形状
      centeredSlides: true,
      slidesPerView: slidesPerView, //显示数量
      spaceBetween: 20,
      watchSlidesProgress: true,
      // autoplay: {
      //   disableOnInteraction: false, //用户操作swiper之后，是否禁止autoplay
      // },
      // 如果需要分页器
      pagination: {
        el: '.lpf-swiper-pagination-teach',
        clickable: true,
        renderBullet: function (index, className) {
          let text = '';
          switch(index){
            case 0:text='全部舞种';break;
            case 1:text='爵士舞';break;
            case 2:text='肚皮舞';break;
            case 3:text='拉丁舞';break;
            case 4:text='中国舞';break;
            case 5:text='街舞';break;
            case 6:text='瑜伽';break;
          }
          return '<span class="' + className + '">' + text + '<i></i></span>';
        },
  },
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  },
}
export { _TEACH };
