<template>
  <div class="block">
            <div class="lpf-list-1 lpf-education-intro">
              <div class="row lpf-list-1-title lpf-education-title">
                <div class="wow bounceIn lpf-title">
                  <h1>蓝风铃舞蹈教育学校</h1>
                  <p>
                    中国大陆地区专业从事成人舞蹈培训、少儿舞蹈培训、职业教练培训的舞蹈连锁机构
                  </p>
                </div>
              </div>
              <div class="row lpf-list-1-content lpf-education-content">
                <div
                  class="
                    wow
                    slideInLeft
                    col-xs-12 col-sm-6 col-md-4
                    lpf-edu-left
                  "
                >
                  <router-link class="lpf-link-a" to="">
                    <h1><span class="lpf-teachers">800</span></h1>
                  <p class="lpf-teachers-p">
                    <span class="lpf-teachers-s">800</span>位专业舞蹈老师
                  </p>
                  </router-link>
                </div>
                <div
                  class="
                    wow
                    bounceIn
                    col-xs-12 col-sm-6 col-md-4
                    lpf-edu-center
                  "
                >
                  <router-link class="lpf-link-a" to="">
                    <h1><span class="lpf-teachers">16</span></h1>
                  <p class="lpf-teachers-p">
                    <span class="lpf-teachers-s">16</span>年教学历史
                  </p>
                  </router-link>
                </div>
                <div
                  class="
                    wow
                    slideInRight
                    col-xs-12 col-sm-6 col-md-4
                    lpf-edu-right
                  "
                >
                  <router-link class="lpf-link-a" to="">
                    <h1><span class="lpf-teachers">28</span></h1>
                  <p class="lpf-teachers-p">
                    <span class="lpf-teachers-s">28</span>所校区分布全国
                  </p>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
</template>
<script>
export default {
  name: "EducationIntro",
  data: function () {
    return {
    };
  },
  mounted: function () {
  },
  methods: {
    lpf_window_onresize() {
    },
  },
};
</script>
<style lang="scss">
// .lpf-list-1 {
//   background-color: $edu-background-color;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// }
// .lpf-list-1 > .lpf-list-1-title,
// .lpf-list-1 > .lpf-list-1-content {
//   width: 100%;
//   max-width: $body-width-content;
//   text-align: center;
//   overflow: hidden;
// }
// .lpf-list-1 > .lpf-list-1-title {
//   margin-bottom: 1rem;
// }
// .lpf-list-1 > .lpf-list-1-title > .lpf-title > h1 {
//   color: $edu-color-h1;
//   font-size: 4rem;
//   margin-top: 4rem;
// }
.lpf-education-content{
  margin-bottom: 1rem;
}
.lpf-education-content > .lpf-edu-left,
.lpf-education-content > .lpf-edu-center,
.lpf-education-content > .lpf-edu-right {
  text-align: center;
  height: 18.7rem;
}
.lpf-teachers {
  font-size: 8.5rem;
  color: $sidebar-background-color;
}
.lpf-teachers-p {
  font-size: 3.8rem;
  color: $edu-color-span;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lpf-education-intro > .lpf-education-title > .lpf-title > p {
  color: $edu-color;
  font-size: 1.8rem;
  margin-top: 1rem;
}
</style>
