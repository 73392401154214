import Vue from 'vue'
const _SIDEBAR = {
  lpf_select: "#33ccff",
  lpf_unselect: "#ffffff",
  lpf_select_a: "#000000",
  lpf_unselect_a: "#0bb5ee",
  lpf_select_li: function (color) {//有子标题的时候滑动出来
    let that = this;
    that.lpf_select = Vue.prototype._CONFIG.colorNavcObject[color];
    that.lpf_unselect_a = Vue.prototype._CONFIG.colorNavcSubObject[color];
    $(".lpf-sidebar").css("background-color", that.lpf_select);//设置导航背景色
    $(".lpf-navc>li>.lpf_about_view").css("background-color", that.lpf_unselect_a);//设置子导航背景色
    $(".lpf-navc>li").hover(
      function () {
        $(this).css("background-color", that.lpf_unselect);
        $(this).children(".lpf-link-a").css("color", that.lpf_select);
        if ($(this).children(".lpf_about_view").length > 0) {
          let x = $(this).position().left;
          $(".lpf_about_view").css({ left: x });
          $(".lpf_about_view").slideToggle();
        }
      },
      function () {
        $(this).css("background-color", that.lpf_select);
        $(this).children(".lpf-link-a").css("color", that.lpf_unselect);
        if ($(this).children(".lpf_about_view").length > 0) {
          $(".lpf_about_view").slideToggle();
        }
      }
    );
    $(".lpf_about_view>.lpf-link-a").hover(
      function () {
        $(this).parent().parent().css("background-color", that.lpf_select);
        $(this).parent().parent().children(".lpf-link-a").css("color", that.lpf_unselect);
        $(this).css("background-color", that.lpf_select_a);
      },
      function () {
        $(this).css("background-color", that.lpf_unselect_a);
      }
    );
  },
  lpf_scrollview: function () {//滚动到一定位置静止导航条
    //这个是监听class=page的元素滚动
    $(".page").scroll(function () {
      var scrollTop = this.scrollTop;
      var height = $(".lpf-header").height();
      var height_sidebar = $(".lpf-sidebar").height();
      if (height < scrollTop) {
        $(".lpf-sidebar").css({ position: 'fixed', top: '0px', left: '0px', width: '100%' });
        $(".lpf-sidebar-backview").css({ 'height': height_sidebar + 'px' });
      } else {
        $(".lpf-sidebar").css({ position: '', top: '', left: '', width: '' });
      }
    });
    //这个是监听body的滚动
    $(window).scroll(function () {
      //为了保证兼容性，这里取两个值，哪个有值取哪一个
      //scrollTop就是触发滚轮事件时滚轮的高度
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      var height = $(".lpf-header").height();
      var height_sidebar = $(".lpf-sidebar").height();
      if (height < scrollTop) {
        $(".lpf-sidebar").css({ position: 'fixed', top: '0px', left: '0px', width: '100%' });
        $(".lpf-sidebar-backview").css({ 'height': height_sidebar + 'px' });
      } else {
        $(".lpf-sidebar").css({ position: '', top: '', left: '', width: '' });
      }
      // console.log("头的高度" + height);
      // console.log("滚动距离" + scrollTop);

      //这个是回到顶部按钮的显示豫隐藏
      if (scrollTop > 200) {
        $(".back-top").css({ display: 'block' })
      } else {
        $(".back-top").css({ display: 'none' })
      }
    })
  },
  lpf_window_onresize: function (callBack) {//监听窗口变化方法
    //窗口缩放触发的函数
    window.onresize = function () {
      var height_sidebar = $(".lpf-sidebar").height();
      $(".lpf-sidebar-backview").css({ 'height': height_sidebar + 'px' });
      if (callBack) {
        var wid = $(window).width() + 17;
        let number = 5;//教师
        let numberCer = 3;//证书
        if (wid <= 1700) {
          number = 4;
        }
        if (wid <= 1300) {
          number = 3;
        }
        if (wid <= 1000) {
          number = 2;
        }
        if (wid <= 870) {
          numberCer = 1;
        }
        if (wid <= 630) {
          number = 1;
        }
        callBack({number:number,wid:wid,numberCer:numberCer});
      }
    };
    //窗口第一次加载完成触发的函数
    $(document).ready(function () {
      var height_sidebar = $(".lpf-sidebar").height();
      $(".lpf-sidebar-backview").css({ 'height': height_sidebar + 'px' });//设置首页轮播图的高度
      if (callBack) {
        var wid = $(window).width() + 17;
        let number = 5;//教师
        let numberCer = 3;//证书
        if (wid <= 1700) {
          number = 4;
        }
        if (wid <= 1300) {
          number = 3;
        }
        if (wid <= 1000) {
          number = 2;
        }
        if (wid <= 870) {
          numberCer = 1;
        }
        if (wid <= 630) {
          number = 1;
        }
        callBack({number:number,wid:wid,numberCer:numberCer});
      }
    });
  }
}
export { _SIDEBAR }
