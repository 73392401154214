const debug_map = {
    local:'http://192.168.3.171:8080',
    development:'http://192.168.3.32:97',
    production:'http://47.104.24.19:3322',
};

const debug = 'local';
const _CONFIG = {
    "apiPath":debug_map[debug],
    "siteUrl":'http://cloud.keji01.com',
    "rootPath":"",
    "siteName":"耶贝念信息技术",
    "siteTitle":"耶贝念信息技术",
    "replyText":"客服将在您提交后20分钟内给您回复",
    "colorNavc":"color",
    "colorNavcObject":{color:'#33ccff',jazz:'#e4007f',bell:'#b7272d',
    latin:'#4044a3',china:'#225c6a',hop:'#2d4a57',yoga:'#b37db3',
    children:'#ff7f84',coach:'#f90081'},
    "colorNavcSubObject":{color:'#0bb5ee',jazz:'#b9126f',bell:'#ad2d33',
    latin:'#2d3184',china:'#174a56',hop:'#405e6b',yoga:'#845584',
    children:'#c3686c',coach:'#e00b79'}
};
// $sidebar-background-color:#33ccff;//主颜色
// $sidebar-background-jazz:#e4007f;//爵士舞
// $sidebar-background-bell:#b7272d;//肚皮舞
// $sidebar-background-latin:#4044a3;//拉丁舞
// $sidebar-background-china:#225c6a;//中国舞
// $sidebar-background-hop:#2d4a57;//街舞
// $sidebar-background-yoga:#b37db3;//瑜伽
// $sidebar-background-children:#ff7f84;//少儿舞
// $sidebar-background-coach:#f90081;//教练班

export { _CONFIG }
