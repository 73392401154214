<template>
    <div class="site-footer-nav">
        site-footer-nav
    </div>
</template>
<script>
    export default {
        name: "Footer",
        props: {},
        data(){
           return {

           }
        },
        methods:{

        }
    };
</script>
<style scoped>

</style>
