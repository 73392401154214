<template>
  <div class="lpf-sidebar-box">
    <div class="lpf-system_title_view" @click="lpf_logoBtn">
      <h1 class="lpf-system_title" v-if="!isCollapse">{{lpf_config.siteTitle}}</h1>
      <img
        class="lpf-logo"
        v-if="isCollapse"
        src="../../../static/imgs/admin/logo.png"
      />
    </div>
    <el-menu
      :default-active="lpf_active"
      class="el-menu-vertical-demo lpf-menu"
      @open="handleOpen"
      @close="handleClose"
      @select="handSelect"
      :collapse="isCollapse"
    >
      <el-menu-item index="1" route="/admin">
        <i class="el-icon-eleme"></i>
        <span slot="title">首页</span>
      </el-menu-item>
      <el-submenu index="2">
        <template slot="title">
          <i class="el-icon-user-solid"></i>
          <span slot="title">人员信息</span>
        </template>
        <el-menu-item-group>
          <span slot="title">学生组</span>
        </el-menu-item-group>
        <el-submenu index="2-1">
          <span slot="title">学生信息</span>
          <el-menu-item index="2-1-1" route="/students?type=1">在职</el-menu-item>
          <el-menu-item index="2-1-2" route="/students?type=2">离职</el-menu-item>
        </el-submenu>
        <el-menu-item-group>
          <span slot="title">教师组</span>
        </el-menu-item-group>
        <el-submenu index="2-2">
          <span slot="title">教师信息</span>
          <el-menu-item index="2-2-1" route="/teachers?type=1">在职</el-menu-item>
          <el-menu-item index="2-2-2" route="/teachers?type=2">离职</el-menu-item>
        </el-submenu>
      </el-submenu>
      <el-submenu index="3">
        <template slot="title">
          <i class="el-icon-share"></i>
          <span slot="title">超级链接</span>
        </template>
        <el-menu-item index="3-1" route="/links?type=1">付费</el-menu-item>
        <el-menu-item index="3-2" route="/links?type=2">免费</el-menu-item>
      </el-submenu>
      <el-menu-item index="4" route="/system">
        <i class="el-icon-s-tools"></i>
        <span slot="title">系统设置</span>
      </el-menu-item>
    </el-menu>
    <div class="lfp-icon-view" @click="lpf_select_sidebar">
      <i
        :class="[
          isCollapse ? 'el-icon-caret-right' : 'el-icon-caret-left',
          'lpf-icon-i',
        ]"
      ></i>
    </div>
  </div>
</template>
<script>
import { _ADMIN_CONFIG } from "../config/config";
import { _data,_methods} from "../../../common/public";

export default {
  name: "Sidebar",
  data: function () {
    return {
      ..._data,
      lpf_config:_ADMIN_CONFIG,
      isCollapse: false,
      lpf_active:'1'
    };
  },
  mounted: function () {
    this.lpf_active = this.isEmpty(localStorage.getItem('lpf-active'))?'1':localStorage.getItem('lpf-active');
  },
  methods: {
    ..._methods,
    /*sub-menu 展开的回调*/
    handleOpen(key, keyPath) {
      console.log("handleOpen");
      console.log(key, keyPath);
    },
    /*sub-menu 收起的回调*/
    handleClose(key, keyPath) {
      console.log("handleClose");
      console.log(key, keyPath);
    },
    /*菜单激活回调*/
    handSelect(key, keyPath, route){
      console.log('handSelect');
      console.log(key, keyPath, route);
      localStorage.setItem('lpf-active',key);
      setTimeout(() => {
        console.log(localStorage.getItem('lpf-active'));
      }, 2000);
      let current_path = this.$route.fullPath;
      let to_path = route.route;
      if (current_path != to_path) {
        // 不同跳转，相同不进行操作，不然报错
        this.$router.push(to_path);
      }
    },
    /*选择导航隐藏显示*/
    lpf_select_sidebar() {
      this.isCollapse = !this.isCollapse;
      let that = this;
    },
    /*点击logo名字回到主界面*/
    lpf_logoBtn() {
      let current_path = this.$route.path;
      if (current_path != _ADMIN_CONFIG["admin_root_path"]) {
        // 不同跳转，相同不进行操作，不然报错
        this.$router.push("/admin");
      }
    },
  },
};
</script>
<style lang="scss">
$header-view-height: 61px;
.lpf-system_title_view {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: $header-view-height;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
}
.lpf-system_title {
  font-size: 2.2rem;
}
.lpf-logo {
  width: 35px;
  height: 35px;
}
.lpf-menu {
  padding-top: $header-view-height;
  height: 100vh;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  height: 100vh;
}
.lfp-icon-view {
  position: absolute;
  right: 0rem;
  top: 50%;
  height: 3rem;
  cursor: pointer;

  display: flex;
  align-items: center;
}
.lpf-icon-i {
  font-size: 2rem;
}
</style>
