<template>
  <div class="page">
    <div class="lpf-content">
      <div class="container-fluid lpf-container-fluid">
        <vHeader></vHeader>
        <vSidebar @lpf_window_onresize="lpf_window_onresize"></vSidebar>
        <div class="lpf-content-main lpf-jazz-list">
          <div class="block">
            <div class="lpf-jazz-banner">
              <div class="row lpf-jazz-banner-content">
                <router-link class="lpf-link-a" to="">
                  <fr-image
                    class="lpf-image"
                    src="https://www.dansewudao.com/skin/img/wz/jazz/banner.jpg"
                    mode=""
                    loading-ing-img="two-balls"
                    :loading-error-img="
                      require('@/static/imgs/home/loading_error.png')
                    "
                  />
                </router-link>
              </div>
            </div>
          </div>
          <div class="block">
            <div class="lpf-jazz-video">
              <div class="row lpf-jazz-video-title">
                <div class="col-xs-12 lpf">
                  <div class="lpf-title-left">专业的课程研发</div>
                  <router-link to="/video" class="lpf-link-a"
                    >更多视频 ></router-link
                  >
                </div>
              </div>
              <div class="row lpf-jazz-video-content">
                <div class="col-xs-12 lpf">
                  <ul class="lpf-video">
                    <li class="lpf-li">
                      <video
                        width="100%"
                        controls="controls"
                        poster="https://m.dansewudao.com/uploadImg/1611385941412.jpg"
                        src="https://p.dansewudao.com/74d7369136e546e9a59a74da5d55eca3/9c4021df998d4159b80da7a935908262-c2b860f2d17dc9d0ead703e6cd0d3558-sd.mp4"
                        type="video/mp4"
                      ></video>
                      <p><span>爵士舞</span>爵士舞教练班学员练习室作品</p>
                    </li>
                    <li class="lpf-li">
                      <video
                        width="100%"
                        controls="controls"
                        poster="https://m.dansewudao.com/uploadImg/16152691280981.jpg"
                        src="https://p.dansewudao.com/0483937fbd4740fca2955569930df05b/405f942fcea64f778389018b30d8a768-0982b1a728a9532777672df03a39ab35-sd.mp4"
                        type="video/mp4"
                      ></video>
                      <p><span>爵士舞</span>《Rewards》爵士舞教学视频</p>
                    </li>
                    <li class="lpf-li">
                      <video
                        width="100%"
                        controls="controls"
                        poster="https://m.dansewudao.com/uploadImg/16112091594251.jpg"
                        src="https://p.dansewudao.com/b9e0176b0f724961934ef6d1a51443cb/26a1b7adb92445cd8b173d97583e8489-f5cb0256c934f377f283a34483aa5efc-sd.mp4"
                        type="video/mp4"
                      ></video>
                      <p><span>爵士舞</span>《maria》女团多人爵士舞教学</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row lpf-jazz-video-dh">
                <div class="col-xs-12 lpf">
                  <ul class="lpf-dh">
                    <li class="lpf-li">百人资深<br />教研团队</li>
                    <li class="lpf-li">全国统一<br />教学大纲</li>
                    <li class="lpf-li">国际教学<br />体系认证</li>
                    <li class="lpf-li">教研全程<br />课程督导</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="block">
            <div class="lpf-jazz-teach">
              <div class="row lpf-jazz-teach-title">
                <div class="col-xs-12 lpf">
                  <div class="lpf-title-left">丰富的教学内容</div>
                  <router-link to="/jazz/coach" class="lpf-link-a"
                    >查看完整课程 ></router-link
                  >
                </div>
              </div>
              <div class="row lpf-jazz-teach-content">
                <div class="col-xs-12 lpf">
                  <ul class="lpf-ul">
                    <li class="lpf-li">
                      <div class="lpf-img">
                        <fr-image
                          class="lpf-image"
                          src="https://www.dansewudao.com//skin/img/wz/jazz/jx1.jpg"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                        />
                      </div>
                      <div class="lpf-txt">
                        <h3>第一阶段</h3>
                        <p>
                          舞者素质培训／舞蹈理论知识<br />
                          教室基本职业素养培训<br />
                          初级自主扣舞技能训练／初级自主编舞能力培训<br />
                          初级教学模拟<br />
                          8个HIPHOP固定元素教学<br />
                          HIPHOP基本律动训练<br />
                          ............
                        </p>
                        <router-link class="lpf-link-a" to="/jazz/coach"
                          >阅读更多</router-link
                        >
                      </div>
                    </li>
                    <li class="lpf-li">
                      <div class="lpf-img">
                        <fr-image
                          class="lpf-image"
                          src="https://www.dansewudao.com//skin/img/wz/jazz/jx2.jpg"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                        />
                      </div>
                      <div class="lpf-txt">
                        <h3>第二阶段</h3>
                        <p>
                          舞蹈文化理论知识教学<br />
                          课堂设计理论知识模拟演练<br />
                          深入教学方法演练／教学模拟<br />
                          深入精细自主扣舞方法总结<br />
                          编舞思维发散引导<br />
                          流行舞形体训练／软开度要求／强化学员身体素质／基本功运用<br />
                          ............
                        </p>
                        <router-link class="lpf-link-a" to="/jazz/coach"
                          >阅读更多</router-link
                        >
                      </div>
                    </li>
                    <li class="lpf-li">
                      <div class="lpf-img">
                        <fr-image
                          class="lpf-image"
                          src="https://www.dansewudao.com//skin/img/wz/jazz/jx3.jpg"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                        />
                      </div>
                      <div class="lpf-txt">
                        <h3>第三阶段</h3>
                        <p>
                          舞蹈文化知识扩散训练<br />
                          HIPHOP／JAZZ基本功高阶强化<br />
                          软开度要求<br />
                          身体素质及身体质感挖掘开发<br />
                          力量转换及融合训练／<br />
                          复合基本功组合混合训练<br />
                          设定主题／音乐／情节的编舞训练<br />
                          ............
                        </p>
                        <router-link class="lpf-link-a" to="/jazz/coach"
                          >阅读更多</router-link
                        >
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="block">
            <div class="lpf-jazz-condition">
              <div class="row lpf-jazz-condition-title">
                <div class="col-xs-12 lpf">
                  <div class="lpf-title-left">零基础可学·无限制条件</div>
                </div>
              </div>
              <div class="row lpf-jazz-condition-image">
                <div class="col-xs-12 lpf">
                  <fr-image
                    class="lpf-image"
                    src="https://www.dansewudao.com/skin/img/wz/jazz/ljc.jpg"
                    mode=""
                    loading-ing-img="two-balls"
                    :loading-error-img="
                      require('@/static/imgs/home/loading_error.png')
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="block">
            <div class="lpf-jazz-tutor">
              <div class="row lpf-jazz-tutor-title">
                <div class="col-xs-12 lpf">
                  <div class="lpf-title-left">资深的导师团队</div>
                  <router-link to="/jazz/coach" class="lpf-link-a"
                    >更多导师 ></router-link
                  >
                </div>
              </div>
              <div class="row lpf-jazz-tutor-content">
                <div class="col-xs-12 lpf">
                  <ul class="lpf-ul">
                    <li class="lpf-li">
                      <fr-image
                        class="lpf-image"
                        src="https://m.dansewudao.com//uploadImg/1624264811365%E4%B8%A5%E9%91%AB%E9%9B%A8-2021.png"
                        mode=""
                        loading-ing-img="two-balls"
                        :loading-error-img="
                          require('@/static/imgs/home/loading_error.png')
                        "
                      />
                      <p>朴爱泽</p>
                      <div class="lpf-black"></div>
                      <div class="lpf-txt">
                        <h5>朴爱泽</h5>
                        <p>
                          简介：湖北联通广告宣传片特邀舞蹈演员
                          2017年武汉斗鱼嘉年华特邀舞蹈演员
                        </p>
                      </div>
                    </li>
                    <li class="lpf-li">
                      <fr-image
                        class="lpf-image"
                        src="https://m.dansewudao.com//uploadImg/1624264811365%E4%B8%A5%E9%91%AB%E9%9B%A8-2021.png"
                        mode=""
                        loading-ing-img="two-balls"
                        :loading-error-img="
                          require('@/static/imgs/home/loading_error.png')
                        "
                      />
                      <p>陈俊儒</p>
                      <div class="lpf-black"></div>
                      <div class="lpf-txt">
                        <h5>陈俊儒</h5>
                        <p>
                          简介： 2015年参加世界IRO中国赛区总决赛开幕式表演
                          2017年荣获WOD华南赛区齐舞最佳人气奖 2016年参加韩国1M
                          Sori Na大师课学习进修 2017年参加俄罗斯SASHA
                          SHERMAN大师课学习进修
                        </p>
                      </div>
                    </li>
                    <li class="lpf-li">
                      <fr-image
                        class="lpf-image"
                        src="https://m.dansewudao.com//uploadImg/1624264811365%E4%B8%A5%E9%91%AB%E9%9B%A8-2021.png"
                        mode=""
                        loading-ing-img="two-balls"
                        :loading-error-img="
                          require('@/static/imgs/home/loading_error.png')
                        "
                      />
                      <p>杨诗光</p>
                      <div class="lpf-black"></div>
                      <div class="lpf-txt">
                        <h5>杨诗光</h5>
                        <p>
                          简介： 2009年参加荆州舞林大会获得一等奖
                          2016年参加韩国1M Sori Na大师课学习进修
                          2017年参加俄罗斯SASHA SHERMAN大师课学习进修
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="block">
            <div class="lpf-jazz-certificate">
              <div class="row lpf-jazz-certificate-title">
                <div class="col-xs-12 lpf">
                  <div class="lpf-title-left">权威的证书认证</div>
                  <router-link to="/jazz/coach" class="lpf-link-a"
                    >咨询更多 ></router-link
                  >
                </div>
              </div>
              <div class="row lpf-jazz-certificate-content">
                <div class="col-xs-12 lpf">
                  <div
                    class="swiper-container lpf-swiper-container-certificate"
                  >
                    <div class="swiper-wrapper">
                      <div
                        class="swiper-slide lpf-swiper-slide"
                        v-for="(item, index) in lpf_certificates"
                        :key="index"
                      >
                        <router-link
                          class="lpf-link-a"
                          to="http://www.baidu.com"
                        >
                          <img class="lpf-image" :src="item.image_url" />
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <!-- 如果需要导航按钮 -->
                  <div class="swiper-button-prev lpf-swiper-button-prev"></div>
                  <div class="swiper-button-next lpf-swiper-button-next"></div>
                </div>
              </div>
              <div class="row lpf-jazz-certificate-remark">
                <div class="col-xs-12 lpf">
                  <div class="lpf-txt">
                    含金量高·社会认可·唯一编码·全网可查<br />
                    所有学员成绩合格者可获得注册舞蹈教师证书，<br />
                    该证书隶属于中国舞蹈家协会，经国家获批。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <vFooter></vFooter>
      </div>
    </div>
  </div>
</template>
<script>
import { vHeader, vFooter, vSidebar } from "../layout/index.js";
import { _JAZZ } from "../src/js/jazz/index.js";
export default {
  name: "JAZZ",
  components: {
    vHeader,
    vFooter,
    vSidebar,
  },
  data: function () {
    return {
      lpf_number: 0, //展示滑动块的个数
      lpf_certificates: [
        {
          name: "严鑫雨",
          dance_type: "中国舞",
          image_url: "	https://www.dansewudao.com/skin/img/wz/wz_cer1.jpg",
        },
        {
          name: "潘婷",
          dance_type: "流行舞",
          image_url: "	https://www.dansewudao.com/skin/img/wz/wz_cer1.jpg",
        },
        {
          name: "沈鑫",
          dance_type: "流行舞",
          image_url: "	https://www.dansewudao.com/skin/img/wz/wz_cer1.jpg",
        },
        {
          name: "诗然",
          dance_type: "流行舞",
          image_url: "	https://www.dansewudao.com/skin/img/wz/wz_cer1.jpg",
        },
        {
          name: "孙艺",
          dance_type: "流行舞",
          image_url: "	https://www.dansewudao.com/skin/img/wz/wz_cer1.jpg",
        },
      ],
    };
  },
  created() {
    this._CONFIG.colorNavc = "jazz";
  },
  mounted: function () {
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new this.WOW({});
      wow.init();
      _JAZZ.lpf_li_hover();
    });
  },
  methods: {
    lpf_window_onresize(paramater) {
      _JAZZ.lpf_window_onresize(paramater);
      if (paramater.numberCer != this.lpf_number) {
        this.lpf_number = paramater.numberCer;
        _JAZZ.initSwiper(this.Swiper, this.lpf_number);
      }
    },
  },
};
</script>
<style lang="scss">
.lpf-jazz-banner {
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-jazz-banner > .lpf-jazz-banner-content {
  width: 100%;
  height: auto;
  margin: 0;
  overflow: hidden;
}
.lpf-jazz-banner > .lpf-jazz-banner-content > .lpf-link-a > .lpf-image {
  width: 100%;
}
.lpf-jazz-video {
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-jazz-video > .lpf-jazz-video-title {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 3rem auto 2.5rem;
  overflow: hidden;
}
.lpf-jazz-video > .lpf-jazz-video-title > .lpf > .lpf-title-left {
  font-size: 4rem;
  color: $footer-background-color;
  float: left;
  text-align: center;
}
.lpf-jazz-video > .lpf-jazz-video-title > .lpf > .lpf-link-a {
  font-size: 3rem;
  color: $edu-color-a;
  float: right;
}
.lpf-jazz-video > .lpf-jazz-video-content {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 0 auto 1rem;
  overflow: hidden;
}
.lpf-jazz-video > .lpf-jazz-video-content > .lpf > .lpf-video {
  width: 100%;
}
.lpf-jazz-video > .lpf-jazz-video-content > .lpf > .lpf-video > .lpf-li {
  width: 32%;
  float: left;
  margin-right: 2%;
  padding-bottom: 0.5rem;
}
.lpf-jazz-video
  > .lpf-jazz-video-content
  > .lpf
  > .lpf-video
  > .lpf-li:nth-child(3n) {
  margin-right: 0;
}
.lpf-jazz-video
  > .lpf-jazz-video-content
  > .lpf
  > .lpf-video
  > .lpf-li
  > video {
  width: 100%;
  height: 100%;
  object-fit: fill;
  outline: none;
}
.lpf-jazz-video > .lpf-jazz-video-content > .lpf > .lpf-video > .lpf-li > p {
  font-size: 1.5rem;
  color: $footer-background-color;
  line-height: 5rem;
  margin-top: -0.5rem;
  box-shadow: 0 0 0.6rem 0 #a9a3a3;
  border-radius: 0 0 1rem 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lpf-jazz-video
  > .lpf-jazz-video-content
  > .lpf
  > .lpf-video
  > .lpf-li
  > p
  > span {
  width: 4rem;
  height: 2rem;
  padding: 0.2rem 0.5rem;
  line-height: 3rem;
  background: #d5d6e7;
  color: $sidebar-background-color;
  text-align: center;
  font-size: 1.2rem;
  margin: 0 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lpf-jazz-video > .lpf-jazz-video-dh {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 1rem auto 2.5rem;
  overflow: hidden;
}
.lpf-jazz-video > .lpf-jazz-video-dh > .lpf > .lpf-dh {
  padding: 0.6rem;
  overflow: hidden;
}
.lpf-jazz-video > .lpf-jazz-video-dh > .lpf > .lpf-dh > .lpf-li {
  width: 23.5%;
  float: left;
  box-shadow: 0 0 0.6rem 0 #a9a3a3;
  border-radius: 1rem;
  font-size: 3rem;
  color: $edu-color-span;
  font-weight: bold;
  text-align: center;
  padding: 2rem 0;
  margin-right: 2%;
  margin-bottom: 1rem;
}
.lpf-jazz-video > .lpf-jazz-video-dh > .lpf > .lpf-dh > .lpf-li:nth-child(4n) {
  margin-right: 0;
}
.lpf-jazz-teach {
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-jazz-teach > .lpf-jazz-teach-title {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 3rem auto 2.5rem;
  overflow: hidden;
}
.lpf-jazz-teach > .lpf-jazz-teach-title > .lpf > .lpf-title-left {
  font-size: 4rem;
  color: $footer-background-color;
  float: left;
  text-align: center;
}
.lpf-jazz-teach > .lpf-jazz-teach-title > .lpf > .lpf-link-a {
  font-size: 3rem;
  color: $edu-color-a;
  float: right;
}
.lpf-jazz-teach > .lpf-jazz-teach-content {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 0 auto 2.5rem;
  overflow: hidden;
}
.lpf-jazz-teach > .lpf-jazz-teach-content > .lpf > .lpf-ul {
  overflow: hidden;
}
.lpf-jazz-teach > .lpf-jazz-teach-content > .lpf > .lpf-ul > .lpf-li {
  width: 32%;
  float: left;
  margin-right: 2%;
  padding-bottom: 0.5rem;
}
.lpf-jazz-teach
  > .lpf-jazz-teach-content
  > .lpf
  > .lpf-ul
  > .lpf-li:nth-child(3n) {
  margin-right: 0;
}
.lpf-jazz-teach
  > .lpf-jazz-teach-content
  > .lpf
  > .lpf-ul
  > .lpf-li
  > .lpf-img {
  width: 100%;
}
.lpf-jazz-teach
  > .lpf-jazz-teach-content
  > .lpf
  > .lpf-ul
  > .lpf-li
  > .lpf-img
  > .lpf-image {
  width: 100%;
  border: none;
  vertical-align: middle;
}
.lpf-jazz-teach
  > .lpf-jazz-teach-content
  > .lpf
  > .lpf-ul
  > .lpf-li
  > .lpf-txt {
  width: 90%;
  margin: 1.5rem auto;
}
.lpf-jazz-teach
  > .lpf-jazz-teach-content
  > .lpf
  > .lpf-ul
  > .lpf-li
  > .lpf-txt
  > h3 {
  font-size: 2.4rem;
  color: $sidebar-background-jazz;
}
.lpf-jazz-teach
  > .lpf-jazz-teach-content
  > .lpf
  > .lpf-ul
  > .lpf-li
  > .lpf-txt
  > p {
  font-size: 2rem;
  color: $edu-color-h6;
  line-height: 3.5rem;
  margin-top: 1rem;
}
.lpf-jazz-teach
  > .lpf-jazz-teach-content
  > .lpf
  > .lpf-ul
  > .lpf-li
  > .lpf-txt
  > .lpf-link-a {
  display: block;
  width: 13.3rem;
  height: 3.6rem;
  line-height: 3.6rem;
  background: $sidebar-background-color;
  color: $white-color;
  font-size: 1.8rem;
  border-radius: 2rem;
  text-align: center;
  margin-top: 2.5rem;
}
.lpf-jazz-condition {
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-jazz-condition > .lpf-jazz-condition-title {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 3rem auto 2.5rem;
  overflow: hidden;
}
.lpf-jazz-condition > .lpf-jazz-condition-title > .lpf > .lpf-title-left {
  font-size: 4rem;
  color: $footer-background-color;
}
.lpf-jazz-condition > .lpf-jazz-condition-image {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 0 auto 2.5rem;
  overflow: hidden;
}
.lpf-jazz-condition > .lpf-jazz-condition-image > .lpf > .lpf-image {
  width: 100%;
}
.lpf-jazz-tutor {
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-jazz-tutor > .lpf-jazz-tutor-title {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 3rem auto 2.5rem;
  overflow: hidden;
}
.lpf-jazz-tutor > .lpf-jazz-tutor-title > .lpf > .lpf-title-left {
  font-size: 4rem;
  color: $footer-background-color;
  float: left;
  text-align: center;
}
.lpf-jazz-tutor > .lpf-jazz-tutor-title > .lpf > .lpf-link-a {
  font-size: 3rem;
  color: $edu-color-a;
  float: right;
}
.lpf-jazz-tutor > .lpf-jazz-tutor-content {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 0 auto 1rem;
  overflow: hidden;
}
.lpf-jazz-tutor > .lpf-jazz-tutor-content > .lpf > .lpf-ul {
  overflow: hidden;
}
.lpf-jazz-tutor > .lpf-jazz-tutor-content > .lpf > .lpf-ul > .lpf-li {
  width: 32%;
  float: left;
  margin-right: 2%;
  padding-bottom: 0.5rem;
  position: relative;
}
.lpf-jazz-tutor
  > .lpf-jazz-tutor-content
  > .lpf
  > .lpf-ul
  > .lpf-li:nth-child(3n) {
  margin-right: 0;
}
.lpf-jazz-tutor
  > .lpf-jazz-tutor-content
  > .lpf
  > .lpf-ul
  > .lpf-li
  > .lpf-image {
  width: 100%;
}
.lpf-jazz-tutor > .lpf-jazz-tutor-content > .lpf > .lpf-ul > .lpf-li > p {
  font-size: 2.4rem;
  color: $footer-background-color;
  line-height: 5rem;
  text-indent: 3rem;
  letter-spacing: 1rem;
}
.lpf-jazz-tutor
  > .lpf-jazz-tutor-content
  > .lpf
  > .lpf-ul
  > .lpf-li
  > .lpf-black {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: $white-color;
  opacity: 0.8;
  display: none;
}
.lpf-jazz-tutor
  > .lpf-jazz-tutor-content
  > .lpf
  > .lpf-ul
  > .lpf-li
  > .lpf-txt {
  width: 80%;
  height: 100%;
  margin: 3rem auto 0;
  position: absolute;
  top: 0;
  left: 10%;
  display: none;
}
.lpf-jazz-tutor
  > .lpf-jazz-tutor-content
  > .lpf
  > .lpf-ul
  > .lpf-li
  > .lpf-txt
  > h5 {
  font-size: 2.4rem;
  color: $sidebar-background-jazz;
  letter-spacing: 1rem;
}
.lpf-jazz-tutor
  > .lpf-jazz-tutor-content
  > .lpf
  > .lpf-ul
  > .lpf-li
  > .lpf-txt
  > p {
  font-size: 1.5rem;
  color: $black-color;
  line-height: 2.5rem;
  text-indent: 0;
  letter-spacing: 0;
  margin-top: 1.5rem;
}
.lpf-jazz-certificate {
  background-color: $brand-background-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-jazz-certificate > .lpf-jazz-certificate-title {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 3rem auto 2.5rem;
  overflow: hidden;
}
.lpf-jazz-certificate > .lpf-jazz-certificate-title > .lpf > .lpf-title-left {
  font-size: 4rem;
  color: $footer-background-color;
  float: left;
  text-align: center;
}
.lpf-jazz-certificate > .lpf-jazz-certificate-title > .lpf > .lpf-link-a {
  font-size: 3rem;
  color: $edu-color-a;
  float: right;
}
.lpf-jazz-certificate > .lpf-jazz-certificate-content {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 0 auto 2.5rem;
  overflow: hidden;
}
.lpf-jazz-certificate
  > .lpf-jazz-certificate-content
  > .lpf
  > .swiper-container {
  height: 44.2rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 5rem;
}
.lpf-swiper-container-certificate > .swiper-wrapper > .lpf-swiper-slide {
  background: $brand-background-color;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
  transform: scale(0.8);
  opacity: 0.7;
}
.lpf-swiper-container-certificate>.swiper-wrapper>.swiper-slide-active,
.lpf-swiper-container-certificate>.swiper-wrapper>.swiper-slide-duplicate-active {
  transform: scale(1.5) !important;
  opacity: 1 !important;
  z-index: 999;
}
.lpf-swiper-container-tutor
  > .swiper-wrapper
  > .lpf-swiper-slide
  > .lpf-link-a {
  width: 100%;
  height: 100%;
}
.lpf-swiper-container-certificate
  > .swiper-wrapper
  > .lpf-swiper-slide
  > .lpf-link-a
  > .lpf-image {
  width: 100%;
  height: auto;
}
.lpf-jazz-certificate
  > .lpf-jazz-certificate-content
  > .lpf
  > .lpf-swiper-button-prev,
.lpf-jazz-certificate
  > .lpf-jazz-certificate-content
  > .lpf
  > .lpf-swiper-button-next {
  background-color: $background-opacity;
  width: 3rem !important;
  height: 3rem !important;
  border-radius: 1.5rem !important;
}
.lpf-jazz-certificate
  > .lpf-jazz-certificate-content
  > .lpf
  > .lpf-swiper-button-prev:after,
.lpf-jazz-certificate
  > .lpf-jazz-certificate-content
  > .lpf
  > .lpf-swiper-button-next:after {
  font-size: 1.5rem !important;
  color: $sidebar-background-color;
}
.lpf-jazz-certificate > .lpf-jazz-certificate-remark {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 0 auto 2.5rem;
  overflow: hidden;
}
.lpf-jazz-certificate > .lpf-jazz-certificate-remark > .lpf > .lpf-txt {
  color: $edu-color-span;
  font-size: 2.8rem;
  line-height: 5rem;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 4rem;
}
@media (max-width: 1090px) {
  .lpf-jazz-teach > .lpf-jazz-teach-content > .lpf > .lpf-ul > .lpf-li {
    width: 49%;
    margin-right: 2%;
  }
  .lpf-jazz-teach
    > .lpf-jazz-teach-content
    > .lpf
    > .lpf-ul
    > .lpf-li:nth-child(2n) {
    margin-right: 0;
  }
}
@media (max-width: 760px) {
  .lpf-jazz-teach > .lpf-jazz-teach-content > .lpf > .lpf-ul > .lpf-li {
    width: 100%;
    margin-right: 2%;
  }
}
@media (max-width: 890px) {
  .lpf-jazz-video > .lpf-jazz-video-content > .lpf > .lpf-video > .lpf-li {
    width: 49%;
    margin-right: 2%;
  }
  .lpf-jazz-video
    > .lpf-jazz-video-content
    > .lpf
    > .lpf-video
    > .lpf-li:nth-child(2n) {
    margin-right: 0;
  }
  .lpf-jazz-video > .lpf-jazz-video-dh > .lpf > .lpf-dh > .lpf-li {
    width: 49%;
    margin-right: 2%;
  }
  .lpf-jazz-video
    > .lpf-jazz-video-dh
    > .lpf
    > .lpf-dh
    > .lpf-li:nth-child(2n) {
    margin-right: 0;
  }
  .lpf-jazz-tutor > .lpf-jazz-tutor-content > .lpf > .lpf-ul > .lpf-li {
    width: 49%;
    margin-right: 2%;
  }
  .lpf-jazz-tutor
    > .lpf-jazz-tutor-content
    > .lpf
    > .lpf-ul
    > .lpf-li:nth-child(2n) {
    margin-right: 0;
  }
}
@media (max-width: 870px) {
.lpf-swiper-container-certificate>.swiper-wrapper>.swiper-slide-active,
.lpf-swiper-container-certificate>.swiper-wrapper>.swiper-slide-duplicate-active  {
  transform: scale(1) !important;
}
}
@media (max-width: 590px) {
  .lpf-jazz-video > .lpf-jazz-video-content > .lpf > .lpf-video > .lpf-li {
    width: 100%;
    margin-right: 0;
  }
  .lpf-jazz-video > .lpf-jazz-video-dh > .lpf > .lpf-dh > .lpf-li {
    width: 100%;
    margin-right: 0;
  }
  .lpf-jazz-tutor > .lpf-jazz-tutor-content > .lpf > .lpf-ul > .lpf-li {
    width: 100%;
    margin-right: 0;
  }
}
</style>
