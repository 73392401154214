const _COOPERA = {
  lpf_image_bili:650/500,//图片比例
  lpf_image_bili1:306/49,//图片比例
  lpf_image_bili2:290/198,//图片比例
  lpf_window_onresize: function (paramater) {
    let str = ".lpf-coopera-jxtd > .lpf-coopera-jxtd-content>.lpf-right>.lpf-image";
    let height = $(str).width()/this.lpf_image_bili;
    $(str).css({ height: height + "px" });
    let str1 = ".lpf-coopera-video > .lpf-coopera-video-content>.lpf-coopera-title>.lpf-image";
    let height1 = $(str1).width()/this.lpf_image_bili1;
    $(str1).css({ height: height1 + "px" });
    let str2 = ".lpf-coopera-video > .lpf-coopera-video-content>.lpf-coopera-ul>li>a>.lpf-image";
    let height2 = $(str2).width()/this.lpf_image_bili2;
    $(str2).css({ height: height2 + "px" });
  },
  lpf_ul_hover: function (){
    $(".lpf-coopera-video > .lpf-coopera-video-content>.lpf-coopera-ul>li").hover(function(){
      $(this).children("a").children('.lpf-image').css({"border":"2px solid #33ccff"});
      $(this).children("a").children('p').css({"color":"#33ccff"});
    },function(){
      $(this).children("a").children('.lpf-image').css({"border":"2px solid #ffffff"});
      $(this).children("a").children('p').css({"color":"#4d4d4d"});
    });
  },
  
}
export { _COOPERA }
