const _CHINA = {
  lpf_image_bili:1920/725,//图片比例
  lpf_window_onresize: function (paramater) {
    let str = ".lpf-china-banner > .lpf-china-banner-content>.lpf-link-a>.lpf-image";
    let height = $(str).width()/this.lpf_image_bili;
    $(str).css({ height: height + "px" });

  },
}
export { _CHINA }
