<template>
  <div class="block">
    <div class="lpf-list-1 lpf-tutor-qualification">
      <div class="row lpf-list-1-title lpf-tutor-title">
        <div class="wow bounceIn lpf-title">
          <h1>导师资质</h1>
          <p>丰富的教学经验 过硬的专业背景</p>
        </div>
      </div>
      <div class="row lpf-list-1-content lpf-tutor-content">
        <div class="swiper-container  lpf-swiper-container-tutor">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide lpf-swiper-slide"
              v-for="(item, index) in lpf_tutors"
              :key="index"
            >
              <router-link class="lpf-link-a" to="http://www.baidu.com">
                <img class="lpf-image" :src="item.image_url" />
                <div class="lpf-image-content">
                  <h2>简介</h2>
                  <span>我是一个工作五年的教学工作者</span>
                </div>
              </router-link >
            </div>
          </div>
          <!-- 如果需要分页器 -->
          <div class="swiper-pagination lpf-swiper-pagination lpf-swiper-pagination-tutor"></div>

          <!-- 如果需要导航按钮 -->
          <div class="swiper-button-prev lpf-swiper-button-prev"></div>
          <div class="swiper-button-next lpf-swiper-button-next"></div>
        </div>
        <div class="lpf-tutor-more">
          <router-link class="lpf-link-a" to="">查看更多专业老师</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { _TUTOR } from "../src/js/index/tutor_qualification.js";
export default {
  name: "TutorQualification",
  data: function () {
    return {
      lpf_number: 0, //展示滑动块的个数
      lpf_tutors: [
        {
          name: "严鑫雨",
          dance_type: "中国舞",
          image_url:
            "https://m.dansewudao.com//uploadImg/1624264811365%E4%B8%A5%E9%91%AB%E9%9B%A8-2021.png",
        },
        {
          name: "潘婷",
          dance_type: "流行舞",
          image_url:
            "https://m.dansewudao.com//uploadImg/1624264821483%E6%BD%98%E5%A9%B72021.jpg",
        },
        {
          name: "沈鑫",
          dance_type: "流行舞",
          image_url:
            "https://m.dansewudao.com//uploadImg/1624264821503%E6%B2%88%E9%91%AB2021.jpg",
        },
        {
          name: "诗然",
          dance_type: "流行舞",
          image_url:
            "https://m.dansewudao.com//uploadImg/1624264821520%E8%AF%97%E7%84%B62021.jpg",
        },
        {
          name: "孙艺",
          dance_type: "流行舞",
          image_url:
            "https://m.dansewudao.com//uploadImg/1624264821539%E5%AD%99%E8%89%BA%EF%BC%88%E5%B0%8F%E8%89%BA%EF%BC%892021.jpg",
        },
        {
          name: "汤晨",
          dance_type: "流行舞",
          image_url:
            "https://m.dansewudao.com//uploadImg/1624264821556%E6%B1%A4%E6%99%A82021.jpg",
        },
        {
          name: "腿腿",
          dance_type: "流行舞",
          image_url:
            "https://m.dansewudao.com//uploadImg/1624264821575%E8%85%BF%E8%85%BF2021.jpeg",
        },
        {
          name: "刘郑媛",
          dance_type: "中国舞",
          image_url:
            "https://m.dansewudao.com//uploadImg/1624264811295%E5%88%98%E9%83%91%E5%AA%9B.jpg",
        },
        {
          name: "任德茂",
          dance_type: "中国舞",
          image_url:
            "https://m.dansewudao.com//uploadImg/1624264811313%E4%BB%BB%E5%BE%B7%E8%8C%822021.jpg",
        },
      ],
    };
  },
  mounted: function () {
    this.$nextTick(() => {
      _TUTOR.imageBig();
    });
  },
  methods: {
    lpf_window_onresize(paramater) {
      console.log(paramater);
      if (paramater.number != this.lpf_number) {
        this.lpf_number = paramater.number;
        _TUTOR.initSwiper(this.Swiper, this.lpf_number);
      }
    },
  },
};
</script>
<style lang="scss">
// .lpf-list-1 {
//   background-color: $edu-background-color;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// }
// .lpf-list-1 > .lpf-list-1-title,
// .lpf-list-1 > .lpf-list-1-content{
//   width: 100%;
//   max-width: $body-width-content;
//   text-align: center;
//   overflow: hidden;
// }
// .lpf-list-1 > .lpf-list-1-title{
//   margin-bottom: 1rem;
// }
// .lpf-list-1 > .lpf-list-1-title > .lpf-title > h1 {
//   color: $edu-color-h1;
//   font-size: 4rem;
//   margin-top: 4rem;
// }
.lpf-tutor-content {
  max-width: 100% !important;
}
.lpf-swiper-container-tutor {
  width: 100%;
  height: 40rem;
  margin: 2rem auto;
  --swiper-theme-color: #33ccff; //修改下面分页器导航的颜色
}
.lpf-swiper-container-tutor>.swiper-wrapper>.lpf-swiper-slide {
  background-color: $white-color;
  display: flex;
}
.lpf-swiper-container-tutor>.swiper-wrapper>.lpf-swiper-slide > .lpf-link-a {
  position: relative;
  width: 100%;
  height: 100%;
}
.lpf-swiper-container-tutor>.swiper-wrapper>.lpf-swiper-slide > .lpf-link-a > .lpf-image {
  width: 100%;
  height: auto;
}
.lpf-swiper-container-tutor>.swiper-wrapper>.lpf-swiper-slide > .lpf-link-a > .lpf-image-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  background-color: $background-opacity;
  display: flex;
  flex-direction: column;
}
.lpf-swiper-container-tutor>.swiper-wrapper>.lpf-swiper-slide > .lpf-link-a > .lpf-image-content > h2 {
  color: $white-color;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  font-size: 2.2rem;
  width: 100%;
  line-height: 2.4rem;
}
.lpf-swiper-container-tutor>.swiper-wrapper>.lpf-swiper-slide > .lpf-link-a > .lpf-image-content > span {
  box-sizing: border-box;
  line-height: 1.4em;
  margin: 2rem;
  font-size: 1.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 15;
  -webkit-box-orient: vertical;
  color: $white-color;
}
.lpf-swiper-container-tutor>.lpf-swiper-pagination {
  bottom: 0 !important;
  top: auto !important;
}
.lpf-swiper-container-tutor>.lpf-swiper-button-prev,
.lpf-swiper-container-tutor>.lpf-swiper-button-next {
  background-color: $background-opacity;
  width: 3rem !important;
  height: 3rem !important;
  border-radius: 1.5rem !important;
}
.lpf-swiper-container-tutor>.lpf-swiper-button-prev:after,
.lpf-swiper-container-tutor>.lpf-swiper-button-next:after {
  font-size: 1.5rem !important;
  color: $sidebar-background-color;
}
.lpf-tutor-content > .lpf-tutor-more {
  float: left; /* 往左浮动 */
  display: block;
  width: 100%;
  margin-bottom: 2rem;
}
.lpf-tutor-content > .lpf-tutor-more > .lpf-link-a {
  display: block;
  color: $white-color;
  font-size: 2.5rem;
  font-weight: bold;
  background: $sidebar-background-color;
  width: 32rem;
  margin: 0 auto;
  line-height: 6rem;
  border-radius: 3rem;
  text-align: center;
  margin-top: 3rem;
  text-decoration: none;
}
@media (max-width: 1254px) {
  .lpf-tutor-content {
    padding: 0 2rem;
  }
}
</style>
