<template>
  <div class="block">
    <el-carousel class="lpf-slideshow" :autoplay="false">
      <el-carousel-item v-for="(item, index) in lpf_slideshow" :key="index">
        <fr-image
          :src="item.img_url"
          mode=""
          loading-ing-img="two-balls"
          :loading-error-img="require('@/static/imgs/home/loading_error.png')"
        />
        <div class="lpf-slideshow-title">
          <p>{{ item.title }}</p>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  name: "Slideshow",
  data: function () {
    return {
      lpf_slideshow: [
        {
          title: "教师团队",
          img_url: "https://lanfl.lpf001.com/img/intro-carousel/1.jpg",
        },
        {
          title: "训练场地",
          img_url: "https://lanfl.lpf001.com/img/intro-carousel/2.jpg",
        },
        {
          title: "考试场地",
          img_url: "https://lanfl.lpf001.com/img/intro-carousel/3.jpg",
        },
      ],
      lpf_ratio_slideshow: 1633 / 520, //屏幕的比例
    };
  },
  mounted: function () {},
  methods: {
    lpf_window_onresize() {
      let window_width = $(window).width();
      // console.log(this.lpf_ratio_slideshow);
      let slidershow_height = window_width / this.lpf_ratio_slideshow;
      $(".lpf-slideshow").css({ height: slidershow_height + "px" });
      // console.log(slidershow_height);
    },
  },
};
</script>
<style lang="scss">
.el-carousel__container {
  height: 100% !important;
}
.el-carousel__item {
  background-color: $sidebar-background-color;
  position: relative;
}
.el-carousel__item img {
  width: 100%;
  height: 100%;
}
.el-carousel__item, .el-carousel__mask{
  position: absolute!important;//没有这个到线上会出错
}
.el-carousel__item > .lpf-slideshow-title {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;

  background-color: $background-opacity;
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-carousel__item > .lpf-slideshow-title > p {
  margin: 1rem 0 !important;
  font-size: 1.4rem;
  color: $white-color;
}
.lpf-slideshow {
  height: 52rem;
}
</style>
