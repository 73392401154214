<template>
<div class="block">
            <div class="lpf-jazz-tutor">
              <div class="row lpf-jazz-tutor-title">
                <div class="col-xs-12 lpf">
                  <div class="lpf-title-left">资深的导师团队</div>
                  <router-link to="/jazz/coach" class="lpf-link-a"
                    >更多导师 ></router-link
                  >
                </div>
              </div>
              <div class="row lpf-jazz-tutor-content">
                <div class="col-xs-12 lpf">
                  <ul class="lpf-ul">
                    <li class="lpf-li">
                      <fr-image
                        class="lpf-image"
                        src="https://m.dansewudao.com//uploadImg/1624264811365%E4%B8%A5%E9%91%AB%E9%9B%A8-2021.png"
                        mode=""
                        loading-ing-img="two-balls"
                        :loading-error-img="
                          require('@/static/imgs/home/loading_error.png')
                        "
                      />
                      <p>朴爱泽</p>
                      <div class="lpf-black"></div>
                      <div class="lpf-txt">
                        <h5>朴爱泽</h5>
                        <p>
                          简介：湖北联通广告宣传片特邀舞蹈演员
                          2017年武汉斗鱼嘉年华特邀舞蹈演员
                        </p>
                      </div>
                    </li>
                    <li class="lpf-li">
                      <fr-image
                        class="lpf-image"
                        src="https://m.dansewudao.com//uploadImg/1624264811365%E4%B8%A5%E9%91%AB%E9%9B%A8-2021.png"
                        mode=""
                        loading-ing-img="two-balls"
                        :loading-error-img="
                          require('@/static/imgs/home/loading_error.png')
                        "
                      />
                      <p>陈俊儒</p>
                      <div class="lpf-black"></div>
                      <div class="lpf-txt">
                        <h5>陈俊儒</h5>
                        <p>
                          简介： 2015年参加世界IRO中国赛区总决赛开幕式表演
                          2017年荣获WOD华南赛区齐舞最佳人气奖 2016年参加韩国1M
                          Sori Na大师课学习进修 2017年参加俄罗斯SASHA
                          SHERMAN大师课学习进修
                        </p>
                      </div>
                    </li>
                    <li class="lpf-li">
                      <fr-image
                        class="lpf-image"
                        src="https://m.dansewudao.com//uploadImg/1624264811365%E4%B8%A5%E9%91%AB%E9%9B%A8-2021.png"
                        mode=""
                        loading-ing-img="two-balls"
                        :loading-error-img="
                          require('@/static/imgs/home/loading_error.png')
                        "
                      />
                      <p>杨诗光</p>
                      <div class="lpf-black"></div>
                      <div class="lpf-txt">
                        <h5>杨诗光</h5>
                        <p>
                          简介： 2009年参加荆州舞林大会获得一等奖
                          2016年参加韩国1M Sori Na大师课学习进修
                          2017年参加俄罗斯SASHA SHERMAN大师课学习进修
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
</template>

<script>
export default {
  name: "lpfTutor",
  data() {
    return {
      lpf_image_bili3:385/375,//图片比例
    };
  },
  props: {},
  mounted() {},
  methods: {
    lpf_window_onresize: function (paramater) {
      let str3 = ".lpf-jazz-tutor > .lpf-jazz-tutor-content>.lpf>.lpf-ul>.lpf-li>.lpf-image";
    let height3 = $(str3).width()/this.lpf_image_bili3;
    $(str3).css({ height: height3 + "px" });
    },
  
  lpf_li_hover: function () {
    $(".lpf-jazz-tutor > .lpf-jazz-tutor-content>.lpf>.lpf-ul>.lpf-li").hover(function(){
      $(this).children(".lpf-black").css({"display":"inline"});
      $(this).children(".lpf-txt").css({"display":"inline"});
    },function(){
      $(this).children(".lpf-black").css({"display":"none"});
      $(this).children(".lpf-txt").css({"display":"none"});
    });
  },
  },
};
</script>

<style lang="scss">
.lpf-jazz-tutor {
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-jazz-tutor > .lpf-jazz-tutor-title {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 3rem auto 2.5rem;
  overflow: hidden;
}
.lpf-jazz-tutor > .lpf-jazz-tutor-title > .lpf > .lpf-title-left {
  font-size: 4rem;
  color: $footer-background-color;
  float: left;
  text-align: center;
}
.lpf-jazz-tutor > .lpf-jazz-tutor-title > .lpf > .lpf-link-a {
  font-size: 3rem;
  color: $edu-color-a;
  float: right;
}
.lpf-jazz-tutor > .lpf-jazz-tutor-content {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 0 auto 1rem;
  overflow: hidden;
}
.lpf-jazz-tutor > .lpf-jazz-tutor-content > .lpf > .lpf-ul {
  overflow: hidden;
}
.lpf-jazz-tutor > .lpf-jazz-tutor-content > .lpf > .lpf-ul > .lpf-li {
  width: 32%;
  float: left;
  margin-right: 2%;
  padding-bottom: 0.5rem;
  position: relative;
}
.lpf-jazz-tutor
  > .lpf-jazz-tutor-content
  > .lpf
  > .lpf-ul
  > .lpf-li:nth-child(3n) {
  margin-right: 0;
}
.lpf-jazz-tutor
  > .lpf-jazz-tutor-content
  > .lpf
  > .lpf-ul
  > .lpf-li
  > .lpf-image {
  width: 100%;
}
.lpf-jazz-tutor > .lpf-jazz-tutor-content > .lpf > .lpf-ul > .lpf-li > p {
  font-size: 2.4rem;
  color: $footer-background-color;
  line-height: 5rem;
  text-indent: 3rem;
  letter-spacing: 1rem;
}
.lpf-jazz-tutor
  > .lpf-jazz-tutor-content
  > .lpf
  > .lpf-ul
  > .lpf-li
  > .lpf-black {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: $white-color;
  opacity: 0.8;
  display: none;
}
.lpf-jazz-tutor
  > .lpf-jazz-tutor-content
  > .lpf
  > .lpf-ul
  > .lpf-li
  > .lpf-txt {
  width: 80%;
  height: 100%;
  margin: 3rem auto 0;
  position: absolute;
  top: 0;
  left: 10%;
  display: none;
}
.lpf-jazz-tutor
  > .lpf-jazz-tutor-content
  > .lpf
  > .lpf-ul
  > .lpf-li
  > .lpf-txt
  > h5 {
  font-size: 2.4rem;
  color: $sidebar-background-jazz;
  letter-spacing: 1rem;
}
.lpf-jazz-tutor
  > .lpf-jazz-tutor-content
  > .lpf
  > .lpf-ul
  > .lpf-li
  > .lpf-txt
  > p {
  font-size: 1.5rem;
  color: $black-color;
  line-height: 2.5rem;
  text-indent: 0;
  letter-spacing: 0;
  margin-top: 1.5rem;
}
@media (max-width: 890px) {
  .lpf-jazz-tutor > .lpf-jazz-tutor-content > .lpf > .lpf-ul > .lpf-li {
    width: 49%;
    margin-right: 2%;
  }
  .lpf-jazz-tutor
    > .lpf-jazz-tutor-content
    > .lpf
    > .lpf-ul
    > .lpf-li:nth-child(2n) {
    margin-right: 0;
  }
}
@media (max-width: 590px) {
  .lpf-jazz-tutor > .lpf-jazz-tutor-content > .lpf > .lpf-ul > .lpf-li {
    width: 100%;
    margin-right: 0;
  }
}
</style>