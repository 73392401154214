const _CAMPUS = {
  lpf_image_bili:425/284,//图片比例
  lpf_window_onresize: function (paramater) {
    let str = " .lpf-campus-info> .lpf-campus-info-content > .lpf-guan> .lpf-guan-right>.lpf-guan-img>.lpf-image";
    let height = $(str).width()/this.lpf_image_bili;
    $(str).css({ height: height + "px" });

  },
  //地址点击事件
  lpf_address_link_click:function (){
    $(".lpf-campus-info > .lpf-campus-info-content>.lpf-address>.lpf-link").click(function(){
      $(".lpf-campus-info > .lpf-campus-info-content>.lpf-address>.lpf-link").removeClass("active");
      $(this).addClass("active");
    });
  },
  lpf_address_link_left_click:function (){
    $(".lpf-campus-info >.lpf-campus-info-content>.lpf-guan>.lpf-guan-left>.lpf-link>span").click(function(){
      $(".lpf-campus-info >.lpf-campus-info-content>.lpf-guan>.lpf-guan-left>.lpf-link>span").removeClass("active");
      $(this).addClass("active");
      $(".lpf-campus-info >.lpf-campus-info-content>.lpf-guan>.lpf-guan-left>.lpf-link>span").siblings("#jiantou").removeClass("active");
      $(this).siblings("#jiantou").addClass("active");
    });
  }
  
}
export { _CAMPUS }
