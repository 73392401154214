/*!
 * @description: 工具方法 v0.01
 * @author: https://www.51xuediannao.com/
 * @update: 2020-02-28 18:02
 */

/**
 * @func _formatTime
 * @desc 格式化时间 formatTime('Y-M-D h:i:s',1582882724729)
 * @param {string} format - 'Y-M-D h:i:s'(默认) 非必填
 * @param {string/number} time = '' - 可以是日期字符串如：'2020-02-28 17:56:23'，或者是 13 位时间戳 非必填
 * @returns 返回值为 指定格式的日期
 */
 const _formatTime = function (format = 'Y-M-D h:i:s', time = '') {
  let date = time ? new Date(time) : new Date();

  let year = date.getFullYear(),
    month = date.getMonth() + 1,//月份是从0开始的
    day = date.getDate(),
    hour = date.getHours(),
    min = date.getMinutes(),
    sec = date.getSeconds();

  //开个长度为10的数组 格式为 00 01 02 03
  let preArr = Array.apply(null, Array(10)).map(function (elem, index) {
    return '0' + index;
  });

  let newTime = format.replace(/Y/g, year)
    .replace(/M/g, preArr[month] || month)
    .replace(/D/g, preArr[day] || day)
    .replace(/h/g, preArr[hour] || hour)
    .replace(/i/g, preArr[min] || min)
    .replace(/s/g, preArr[sec] || sec);

  return newTime;
}

/**
 * @func _urlProps2Json
 * @desc 把一个 url 中附带的参数转化为对象
 * @param {string} location_search - url 参数段 id=100&name='张三'
 * @returns 返回JSON对象 如：{"id":"100","name":"张三"}
 */
let _urlProps2Json = function (location_search) {
  let obj = {};
  let str_array = str.split('&');
  for (let i = 0; i < str_array.length; i++) {
    let item = str_array[i].split('=');
    obj[item[0]] = item[1]
  }
  return obj;
}

/**
 * @func _sleep _sleep(1000).then(res=>{...})
 * @desc 休眠指定时间
 * @param {number} delay - 休眠时间 默认 800
 * @returns 返回 Promise 对象
 */
let _sleep = function (delay = 800) {
  return new Promise((resolve, reject) => {
    setTimeout(function () {
      resolve(delay);
    }, delay)
  })
};

/**
* 统一在底部写一个 util = {} 可以方便看到 所有方法名
* 按 ctrl + 鼠标单击方法名可以跳转到 方法位置，但不能跨文件跳转
* 所有自定义方法都以 _ 开头，方便在几百行的代码中一眼看出来是自定义方法
*/
const util = {
  _formatTime,
  _urlProps2Json,
  _sleep,
};

export { util }
