<template>
  <div class="page">
    <vSidebar></vSidebar>
    <!--右侧内容区域-->
    <div class="lpf-content">
      <div class="container-fluid lpf-container-fluid">
        <vHeader></vHeader>
      </div>
    </div>
  </div>
</template>
<script>
import { vHeader, vFooter, vSidebar } from "../layout/index.js";
export default {
  name: "Admin",
  components: {
    vHeader,
    vFooter,
    vSidebar,
  },
  data: function () {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss">
</style>
