<template>
  <div class="page">
    <div class="lpf-content">
      <div class="container-fluid lpf-container-fluid">
        <vHeader></vHeader>
        <vSidebar @lpf_window_onresize="lpf_window_onresize"></vSidebar>
        <div class="lpf-content-main lpf-belly-list">
          <div class="block">
            <div class="lpf-belly-banner">
              <div class="row lpf-belly-banner-content">
                <router-link class="lpf-link-a" to="">
                  <fr-image
                    class="lpf-image"
                    src="https://www.dansewudao.com/skin/img/wz/belly/banner.jpg"
                    mode=""
                    loading-ing-img="two-balls"
                    :loading-error-img="
                      require('@/static/imgs/home/loading_error.png')
                    "
                  />
                </router-link>
              </div>
            </div>
          </div>
          <lpfVideo ref="lpfVideo"></lpfVideo>
          <lpfTeach ref="lpfTeach"></lpfTeach>
          <lpfCondition ref="lpfCondition"></lpfCondition>
          <lpfTutor ref="lpfTutor"></lpfTutor>
          <lpfCertificate ref="lpfCertificate"></lpfCertificate>
        </div>
        <vFooter></vFooter>
      </div>
    </div>
  </div>
</template>
<script>
import { vHeader, vFooter, vSidebar } from "../layout/index.js";
import { _BELLY } from "../src/js/belly/index.js";
import lpfVideo from "@/components/dance/video.vue";
import lpfTeach from "@/components/dance/teach.vue";
import lpfCondition from "@/components/dance/condition.vue";
import lpfTutor from "@/components/dance/tutor.vue";
import lpfCertificate from "@/components/dance/certificate.vue";
export default {
  name: "BELLY",
  components: {
    vHeader,
    vFooter,
    vSidebar,
    lpfVideo,
    lpfTeach,
    lpfCondition,
    lpfTutor,
    lpfCertificate,
  },
  data: function () {
    return {
    };
  },
  created() {
     this._CONFIG.colorNavc = 'bell';
  },
  mounted: function () {
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new this.WOW({});
      wow.init();
    });
  },
  methods: {
    lpf_window_onresize(paramater) {
      _BELLY.lpf_window_onresize(paramater);
      this.$refs.lpfVideo.lpf_window_onresize(paramater);
      this.$refs.lpfTeach.lpf_window_onresize(paramater);
      this.$refs.lpfCondition.lpf_window_onresize(paramater);
      this.$refs.lpfTutor.lpf_window_onresize(paramater);
      this.$refs.lpfTutor.lpf_li_hover();
      if (paramater.numberCer != this.$refs.lpfCertificate.lpf_number) {
        this.$refs.lpfCertificate.lpf_number = paramater.numberCer;
        this.$refs.lpfCertificate.initSwiper(this.Swiper, this.$refs.lpfCertificate.lpf_number);
      }
    },
  },
};
</script>
<style lang="scss">
.lpf-belly-banner {
  background-color: $edu-background-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-belly-banner > .lpf-belly-banner-content {
  width: 100%;
  height: auto;
  margin: 0;
  overflow: hidden;
}
.lpf-belly-banner > .lpf-belly-banner-content > .lpf-link-a > .lpf-image {
  width: 100%;
}
</style>
