<template>
  <div class="block">
    <div class="lpf-list-2 lpf-brand-advantage">
      <div class="row lpf-list-2-title lpf-brand-title">
        <div class="wow bounceIn lpf-title">
          <h1>品牌优势</h1>
          <p></p>
        </div>
      </div>
      <div class="row lpf-list-2-content lpf-brand-content">
        <div class="wow bounceInLeft col-xs-12 col-lg-7 lpf-left">
          <ul class="lpf-ul">
            <div class="row">
              <li
                class="wow shake col-xs-12 col-sm-6 lpf-ul-li"
                v-for="(item, index) in lpf_brand_advantage"
                :key="index"
                :data-wow-delay="index*0.1+'s'"
              >
                <span>{{ item.order }}</span>
                <i :class="item.number" v-if="item.number"></i>
                {{ item.name }}
              </li>
            </div>
          </ul>
        </div>
        <div class="wow bounceInRight col-xs-12 col-lg-5 lpf-right">
          <router-link class="lpf-link-a" to="">
            <fr-image
              class="lpf-image"
              src="https://img.dansewudao.com/skin_pc/img/index/youshi.jpg"
              mode=""
              loading-ing-img="two-balls"
              :loading-error-img="
                require('@/static/imgs/home/loading_error.png')
              "
            />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BrandAdvantage",
  data: function () {
    return {
      lpf_brand_advantage: [
        { order: "01.", name: "全国连锁直营" },
        { order: "02.", name: "0基础学员福音" },
        { order: "03.", name: "家连锁分馆", number: "lpf_fenxiao_num" },
        { order: "04.", name: "免费试学体验" },
        { order: "05.", name: "舞种丰富多元" },
        { order: "06.", name: "学费透明公开" },
        { order: "07.", name: "科班老师任教" },
        { order: "08.", name: "毕业推荐就业" },
        { order: "09.", name: "全新舞蹈教案", number: "lpf_jiaoan" },
        { order: "10.", name: "专业考级指导" },
      ],
    };
  },
  mounted: function () {},
  methods: {
    lpf_window_onresize() {
      let window_width = $(window).width();
      let str_brand =
        ".lpf-brand-content>.lpf-right>a>.lpf-image>.fr_img_content";
      var lpf_image_width_brand = $(str_brand).width();
      lpf_image_width_brand =
        lpf_image_width_brand > 453 ? 453 : lpf_image_width_brand;
      let lpf_image_height_brand = (lpf_image_width_brand * 350) / 469;
      $(str_brand).css({
        height: lpf_image_height_brand + "px",
        width: lpf_image_width_brand + "px",
        "margin-top": -lpf_image_height_brand / 2.0 + "px",
        "margin-left": -lpf_image_width_brand / 2.0 + "px",
      });
      if (window_width > 768) {
        let str_brand_l = ".lpf-brand-content>.lpf-left";
        let lpf_image_height_brand_l = $(str_brand_l).height();
        $(".lpf-brand-content>.lpf-right").css({
          height: lpf_image_height_brand_l + "px",
        });
      } else {
        $(".lpf-brand-content>.lpf-right").css({
          height: lpf_image_height_brand + "px",
        });
      }
    },
  },
};
</script>
<style lang="scss">
// .lpf-list-2 {
//   background-color: $white-color;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// }
// .lpf-list-2 > .lpf-list-2-title,
// .lpf-list-2 > .lpf-list-2-content {
//   width: 100%;
//   max-width: $body-width-content;
//   text-align: center;
//   overflow: hidden;
// }
// .lpf-list-2 > .lpf-list-2-title {
//   margin-bottom: 1rem;
// }
// .lpf-list-2 > .lpf-list-2-title > .lpf-title > h1 {
//   color: $edu-color-h1;
//   font-size: 4rem;
//   margin-top: 4rem;
// }
.lpf-brand-content {
  padding-bottom: 2rem;
}
.lpf-brand-content > .lpf-right > .lpf-link-a > .lpf-image > .fr_img_content {
  left: 50% !important;
  top: 50% !important;
}
.lpf-brand-content > .lpf-left > .lpf-ul {
  padding: 3.6rem;
  background-color: $brand-background-color;
  border-radius: 0.5rem;
  margin-bottom: 0 !important;
}
.lpf-brand-content > .lpf-left,.lpf-brand-content > .lpf-right{
  padding-left: 0!important;
  padding-right: 0!important;
}
.lpf-brand-content > .lpf-left > .lpf-ul > .row > .lpf-ul-li {
  height: 5.7rem;
  line-height: 5.7rem;
  font-size: 2.4rem;
  border-bottom: 0.2rem solid $brand-content-border;
  color: $edu-color-span;
  padding-bottom: 0.73rem;
  text-align: left;
}
.lpf-brand-content > .lpf-left > .lpf-ul > .row > .lpf-ul-li:nth-child(2n + 1) {
  border-right: 0.2rem solid $brand-content-border;
}
.lpf-brand-content > .lpf-left > .lpf-ul > .row > .lpf-ul-li:nth-child(9),
.lpf-brand-content > .lpf-left > .lpf-ul > .row > .lpf-ul-li:nth-child(10) {
  border-bottom: none;
}
.lpf-brand-content > .lpf-left > .lpf-ul > .row > .lpf-ul-li > span {
  color: $sidebar-background-color;
  margin-right: 1rem;
  font-size: 2rem;
  font-style: italic;
  font-weight: bold;
}
@media (max-width: 1254px) {
  .lpf-brand-content {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (max-width: 1200px) {
  .lpf-brand-content > .lpf-right {
    padding-top: 2rem;
  }
}
@media (max-width: 768px) {
  .lpf-brand-content > .lpf-left > .lpf-ul > .row > .lpf-ul-li {
    border-bottom: 0.2rem solid $brand-content-border !important;
    border-right: none !important;
  }
  .lpf-brand-content > .lpf-left > .lpf-ul > .row > .lpf-ul-li:last-child {
    border-bottom: none !important;
  }
}
</style>
