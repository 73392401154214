const _QUALIFICATIONS = {
  lpf_image_bili:230/166,//图片比例
  lpf_window_onresize: function (paramater) {
    let str = ".lpf-about-info > .lpf-about-info-content > .lpf-about-info-content-item>.lpf-ul>li>.lpf-link-a>.lpf-image";
    let height = $(str).width()/this.lpf_image_bili;
    $(str).css({ height: height + "px" });
  },
  
}
export { _QUALIFICATIONS }
