import { $http } from '../common/axios.js'
import { _CONFIG } from '../config/config.js'
const api = _CONFIG.apiPath + '/'
// const apiPublic = _CONFIG.apiPath + '/publics/';

function postParams (obj) {
  var params = new URLSearchParams()
  for (let key in obj) {
    params.append(key, obj[key])
  }
  return params
}

export default {
  // 注册
  register (data) {
    console.log('dd')
    let params = postParams(data)
    return $http.post(`${api}member/o.php?url=login/register`, params)
      .then(function (response) {
        return response.data
      })
  },
  // 获取首页数据库

  getSelfList () {
    return $http.get(`${api}api.php?url=class/getSelf_list`)
      .then(function (response) {
        return response.data
      })
  },

  // 获取三级分类
  getCateGoryThree (data) {
    return $http.get(`${api}api.php?url=class/getList_three`, { params: data })
      .then(function (response) {
        return response.data
      })
  }

}
