<template>
  <div class="block">
    <div class="lpf-list-2 lpf-teaching-results">
      <div class="row lpf-list-2-title lpf-teaching-title">
        <div class="wow bounceIn lpf-title">
          <h1>教学成果</h1>
          <p></p>
        </div>
      </div>
      <div class="row lpf-list-2-content lpf-teaching-content">
        <div class="swiper-container lpf-swiper-container-teach">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide lpf-swiper-slide"
              v-for="(item, index) in lpf_dance_type"
              :key="index"
            >
              <router-link
              class="lpf-link-a"
                to=""
                v-for="(item_c, index_c) in lpf_dance_content"
                :key="index_c"
              >
                <fr-image
                  class="lpf-image"
                  :src="item_c.img_url"
                  mode=""
                  loading-ing-img="two-balls"
                  :loading-error-img="
                    require('@/static/imgs/home/loading_error.png')
                  "
                />
                <p>
                  <span>{{ item_c.name }}</span
                  >{{ item_c.title }}
                </p>
              </router-link>
            </div>
          </div>
          <!-- 如果需要分页器 -->
          <div
            class="
              swiper-pagination
              lpf-swiper-pagination lpf-swiper-pagination-teach
            "
          ></div>

          <!-- 如果需要导航按钮 -->
          <div class="swiper-button-prev lpf-swiper-button-prev"></div>
          <div class="swiper-button-next lpf-swiper-button-next"></div>
        </div>
        <div class="lpf-teach-more">
          <router-link class="lpf-link-a" to="">查看更多精彩视频</router-link >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { _TEACH } from "../src/js/index/teaching_results.js";
export default {
  name: "TeachingResults",
  data: function () {
    return {
      lpf_dance_type: [
        {
          name: "全部舞种",
        },
        {
          name: "爵士舞",
        },
        {
          name: "肚皮舞",
        },
        {
          name: "拉丁舞",
        },
        {
          name: "中国舞",
        },
        {
          name: "街舞",
        },
        {
          name: "瑜伽",
        },
      ],
      lpf_dance_content: [
        {
          name: "街舞",
          img_url: "https://m.dansewudao.com/uploadImg/16152691398375.jpg",
          title: "武汉流行舞导师团体编舞作品",
        },
        {
          name: "街舞",
          img_url: "https://m.dansewudao.com/uploadImg/16152691398274.jpg",
          title: "流行舞导师团体《float》教学",
        },
        {
          name: "街舞",
          img_url: "https://m.dansewudao.com/uploadImg/16152691398183.jpg",
          title: "流行舞教练班学员作品",
        },
        {
          name: "爵士舞",
          img_url: "https://m.dansewudao.com/uploadImg/1611385941412.jpg",
          title: "爵士舞教练班学员练习室作品",
        },
        {
          name: "爵士舞",
          img_url: "https://m.dansewudao.com/uploadImg/16152691280981.jpg",
          title: "《Rewards》爵士舞教学视频",
        },
        {
          name: "中国舞",
          img_url: "https://m.dansewudao.com/uploadImg/16152684840041.jpg",
          title: "春熙路馆中国舞学员作品",
        },
      ],
    };
  },
  mounted: function () {
    this.$nextTick(() => {
      _TEACH.initSwiper(this.Swiper, 1);
    });
  },
  methods: {
    lpf_window_onresize() {},
  },
};
</script>
<style lang="scss">
// .lpf-list-2 {
//   background-color: $white-color;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// }
// .lpf-list-2 > .lpf-list-2-title,
// .lpf-list-2 > .lpf-list-2-content {
//   width: 100%;
//   max-width: $body-width-content;
//   text-align: center;
//   overflow: hidden;
// }
// .lpf-list-2 > .lpf-list-2-title {
//   margin-bottom: 1rem;
// }
// .lpf-list-2 > .lpf-list-2-title > .lpf-title > h1 {
//   color: $edu-color-h1;
//   font-size: 4rem;
//   margin-top: 4rem;
// }
.lpf-swiper-container-teach {
  width: 100%;
  margin: 2rem auto;
  --swiper-theme-color: #33ccff; //修改下面分页器导航的颜色
}
.lpf-swiper-container-teach > .swiper-wrapper {
  margin-top: 7rem;
}
.lpf-swiper-container-teach > .swiper-wrapper > .lpf-swiper-slide {
  background-color: $white-color;
}
.lpf-swiper-container-teach > .swiper-wrapper > .lpf-swiper-slide > .lpf-link-a {
  display: block;
  width: 31%;
  background: #efeeee;
  margin-right: 3.5%;
  margin-bottom: 15px;
  border-radius: 10px;
  float: left;
  overflow: hidden;
  text-decoration: none;
}
.lpf-swiper-container-teach
  > .swiper-wrapper
  > .lpf-swiper-slide
  > .lpf-link-a:nth-child(3n) {
  margin-right: 0!important;
}
.lpf-swiper-pagination-teach {
  top: 0 !important;
}
.lpf-swiper-container-teach
  > .swiper-wrapper
  > .lpf-swiper-slide
  > .lpf-link-a
  > .lpf-image {
  width: 100%;
  height: 24.8rem;
  vertical-align: middle;
}
.lpf-swiper-container-teach
  > .swiper-wrapper
  > .lpf-swiper-slide
  > .lpf-link-a
  > .lpf-image
  > .fr_img_content {
  left: 0;
  top: 0;
}
.lpf-swiper-container-teach > .swiper-wrapper > .lpf-swiper-slide > .lpf-link-a > p {
  color: $edu-color-h1;
  line-height: 6rem;
  text-align: left;
  font-size: 1.6rem;
  margin: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lpf-swiper-container-teach > .swiper-wrapper > .lpf-swiper-slide > .lpf-link-a > p:hover{
  background-color: $sidebar-background-color;
}
.lpf-swiper-container-teach
  > .swiper-wrapper
  > .lpf-swiper-slide
  > .lpf-link-a
  > p
  > span {
  width: 4rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  background: #d5d6e7;
  color: $sidebar-background-color;
  font-size: 1.2rem;
  padding: 0.6rem 1rem;
  margin: 0.5rem 0.5rem 0.5rem 1.5rem;
  border-radius: 0.3rem;
}
.lpf-swiper-container-teach
  > .lpf-swiper-pagination
  > .swiper-pagination-bullet-active {
  background: $sidebar-background-color;
  border: 0.1rem solid $sidebar-background-color;
  position: relative;
  color: $white-color;
}
.lpf-swiper-container-teach > .lpf-swiper-pagination {
  top: 0;
  height: 3.5rem;
}
.lpf-swiper-container-teach > .lpf-swiper-pagination > span {
  display: inline-block;
  opacity: 1;
  background: $white-color;
  width: 8rem;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  border: 0.1rem solid $edu-color-h1;
  color: $edu-color-h1;
  margin: 1rem 1rem !important;
  font-size: 1.6rem;
  border-radius: 4.6rem;
  font-weight: bold;
}
.lpf-swiper-container-teach > .lpf-swiper-pagination > span > i {
  display: none;
  width: 0;
  height: 0;
  border-width: 0.6rem;
  border-style: solid;
  border-color: $sidebar-background-color transparent transparent transparent;
  position: absolute;
  left: 3.2rem;
  top: 2.8rem;
}
.lpf-swiper-container-teach
  > .lpf-swiper-pagination
  > span.swiper-pagination-bullet-active
  i {
  display: block;
}
.lpf-swiper-container-teach > .lpf-swiper-button-prev,
.lpf-swiper-container-teach > .lpf-swiper-button-next {
  background-color: $background-opacity;
  width: 3rem !important;
  height: 3rem !important;
  border-radius: 1.5rem !important;
}
.lpf-swiper-container-teach > .lpf-swiper-button-prev:after,
.lpf-swiper-container-teach > .lpf-swiper-button-next:after {
  font-size: 1.5rem !important;
  color: $sidebar-background-color;
}
.lpf-teaching-content > .lpf-teach-more {
  float: left; /* 往左浮动 */
  display: block;
  width: 100%;
  margin-bottom: 2rem;
}
.lpf-teaching-content > .lpf-teach-more > .lpf-link-a {
  display: block;
  color: $white-color;
  font-size: 2.5rem;
  font-weight: bold;
  background: $sidebar-background-color;
  width: 32rem;
  margin: 0 auto;
  line-height: 6rem;
  border-radius: 3rem;
  text-align: center;
  margin-top: 3rem;
  text-decoration: none;
}
@media (max-width: 1254px) {
  .lpf-teaching-content {
    padding: 0 2rem;
  }
}
@media (max-width: 1000px) {
  .lpf-swiper-container-teach > .swiper-wrapper > .lpf-swiper-slide > .lpf-link-a {
  width: 48%;
  margin-right: 4%!important;
}
.lpf-swiper-container-teach
  > .swiper-wrapper
  > .lpf-swiper-slide
  > .lpf-link-a:nth-child(3n) {
  margin-right: 4%!important;
}
.lpf-swiper-container-teach
  > .swiper-wrapper
  > .lpf-swiper-slide
  > .lpf-link-a:nth-child(2n) {
  margin-right: 0!important;
}
}
@media (max-width: 716px) {
  .lpf-swiper-container-teach > .swiper-wrapper {
  margin-top: 11rem;
}
}
@media (max-width: 630px) {
  .lpf-swiper-container-teach > .swiper-wrapper > .lpf-swiper-slide > .lpf-link-a {
  width: 100%;
  margin-right: 0!important;
}
}
</style>
