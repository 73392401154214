<template>
  <div class="back-top" @click="backTop">
        <span class="iconfont">&#xe733;</span>
      </div>
</template>
<script>
export default {
  name: "BackTop",
  props: {},
  data() {
    return {};
  },
  mounted: function () {},
  methods: {
    backTop(){
      $(".back-top").css({display:'none'});
       $('body,html').animate({scrollTop:0},500);
    }
  },
};
</script>
<style lang="scss" scoped>
.back-top {
  // pointer-events: none;//不能点击
  z-index:1000;
  height: 4rem;
  width: 4rem;
  background-color: #f2f5f6;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  text-align: center;
  line-height: 40px;
  color: #33ccff;
  border-radius: 50%;
  position: fixed;
  right: 3rem;
  bottom: 6rem;
  display: none;

}
.back-top>span{
  font-size: 2.5rem;
}
</style>
