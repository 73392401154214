<template>
  <div class="block">
            <div class="lpf-list-2 lpf-dance-type">
              <div class="row lpf-list-2-title lpf-dance-title">
                <div class="wow bounceIn lpf-title">
                  <h1>开始舞种</h1>
                  <p></p>
                </div>
              </div>
              <div class="row lpf-list-2-content lpf-dance-content">
                <ul class="lpf-ul">
                  <li
                    class="wow bounceInUp lpf-ul-li"
                    v-for="(item, index) in lpf_dance_type"
                    :key="index"
                    :data-wow-delay="index*0.1+'s'"
                  >
                    <router-link to="" class="lpf-link-a">
                      <fr-image
                        class="lpf-image"
                        :src="item.img_url"
                        mode=""
                        loading-ing-img="two-balls"
                        :loading-error-img="
                          require('@/static/imgs/home/loading_error.png')
                        "
                      />
                      <p class="lpf-item-title">
                        <span
                          class="lpf-item-type"
                          :style="{ background: item.color }"
                          >{{ item.type }}</span
                        >{{ item.name }}
                      </p>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
</template>
<script>
export default {
  name: "DanceType",
  data: function () {
    return {
      lpf_dance_type: [
        {
          name: "爵士舞",
          img_url: "https://img.dansewudao.com/skin_pc/img/index/wz1.jpg",
          type: "热门",
          color: "#33ccff",
        },
        {
          name: "肚皮舞",
          img_url: "https://img.dansewudao.com/skin_pc/img/index/wz2.jpg",
          type: "热门",
          color: "#33ccff",
        },
        {
          name: "拉丁舞",
          img_url: "https://img.dansewudao.com/skin_pc/img/index/wz3.jpg",
          type: "热门",
          color: "#33ccff",
        },
        {
          name: "中国舞",
          img_url: "https://img.dansewudao.com/skin_pc/img/index/wz4.jpg",
          type: "热门",
          color: "#33ccff",
        },
        {
          name: "街舞",
          img_url: "https://img.dansewudao.com/skin_pc/img/index/wz5.jpg",
          type: "热门",
          color: "#33ccff",
        },
        {
          name: "瑜伽",
          img_url: "https://img.dansewudao.com/skin_pc/img/index/wz6.jpg",
          type: "热门",
          color: "#33ccff",
        },
        {
          name: "少儿舞",
          img_url: "https://img.dansewudao.com/skin_pc/img/index/wz7.jpg",
          type: "少儿",
          color: "#80800d",
        },
        {
          name: "教练班",
          img_url: "https://img.dansewudao.com/skin_pc/img/index/wz8.jpg",
          type: "高级",
          color: "#7f47b2",
        },
      ],
    };
  },
  mounted: function () {
  },
  methods: {
    lpf_window_onresize() {
      let str = ".lpf-dance-content>.lpf-ul>.lpf-ul-li>a>.lpf-image";
      let lpf_image_width = $(str).width();
      $(str).css({ height: lpf_image_width + "px" });
    },
  },
};
</script>
<style lang="scss">
// .lpf-list-2 {
//   background-color: $white-color;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// }
// .lpf-list-2 > .lpf-list-2-title,
// .lpf-list-2 > .lpf-list-2-content {
//   width: 100%;
//   max-width: $body-width-content;
//   text-align: center;
//   overflow: hidden;
// }
// .lpf-list-2 > .lpf-list-2-title {
//   margin-bottom: 1rem;
// }
// .lpf-list-2 > .lpf-list-2-title > .lpf-title > h1 {
//   color: $edu-color-h1;
//   font-size: 4rem;
//   margin-top: 4rem;
// }
.lpf-dance-content > .lpf-ul {
  list-style: none; /* 将默认的列表符号去掉 */
}
.lpf-dance-content > .lpf-ul > .lpf-ul-li {
  float: left; /* 往左浮动 */
  display: block;
  width: 24%;
  margin-right: 1.3333333333%;
  margin-bottom: 10px;
}
.lpf-dance-content > .lpf-ul > .lpf-ul-li:nth-child(4n) {
  margin-right: 0;
}
.lpf-dance-content > .lpf-ul > .lpf-ul-li > .lpf-link-a > .lpf-image {
  width: 100%;
  height: auto;
  vertical-align: middle;
  border-radius: 1.2rem;
  overflow: hidden;
}
.lpf-dance-content > .lpf-ul > .lpf-ul-li > .lpf-link-a > .lpf-image > .fr_img_content {
  left: 0 !important;
  top: 0 !important;
}
.lpf-dance-content > .lpf-ul > .lpf-ul-li > .lpf-link-a > .lpf-item-title {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 3rem;
  color: $edu-color-h1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
.lpf-dance-content > .lpf-ul > .lpf-ul-li > .lpf-link-a {
  cursor: pointer;
  text-decoration: none;
}
.lpf-dance-content > .lpf-ul > .lpf-ul-li > .lpf-link-a > .lpf-item-title > span {
  display: inline-block;
  width: 76px;
  height: 36px;
  line-height: 36px;
  background: $sidebar-background-color;
  color: $white-color;
  text-align: center;
  font-size: 2.3rem;
  border-radius: 0.5rem;
  margin-right: 1rem;
  vertical-align: text-bottom;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (max-width: 992px) {
  .lpf-dance-content > .lpf-ul > .lpf-ul-li {
    width: 32%;
    margin-right: 2%;
  }
  .lpf-dance-content > .lpf-ul > .lpf-ul-li:nth-child(4n) {
    margin-right: 2%;
  }
  .lpf-dance-content > .lpf-ul > .lpf-ul-li:nth-child(2n) {
    margin-right: 2%;
  }
  .lpf-dance-content > .lpf-ul > .lpf-ul-li:nth-child(3n) {
    margin-right: 0;
  }
}
@media (max-width: 768px) {
  .lpf-dance-content > .lpf-ul > .lpf-ul-li {
    width: 47%;
    margin-right: 6%;
  }
  .lpf-dance-content > .lpf-ul > .lpf-ul-li:nth-child(3n) {
    margin-right: 6%;
  }
  .lpf-dance-content > .lpf-ul > .lpf-ul-li:nth-child(4n) {
    margin-right: 6%;
  }
  .lpf-dance-content > .lpf-ul > .lpf-ul-li:nth-child(2n) {
    margin-right: 0;
  }
}
@media (max-width: 1254px) {
  .lpf-dance-content {
    padding: 0 2rem;
  }
}
</style>
