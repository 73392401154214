<template>
  <div class="page">
    <div class="lpf-content">
      <div class="container-fluid lpf-container-fluid">
        <vHeader></vHeader>
        <vSidebar @lpf_window_onresize="lpf_window_onresize"></vSidebar>
        <div class="lpf-content-main lpf-china-list">
          <div class="block">
            <div class="lpf-china-banner">
              <div class="row lpf-china-banner-content">
                <router-link class="lpf-link-a" to="">
                  <fr-image
                    class="lpf-image"
                    src="https://www.dansewudao.com/skin/img/wz/china/banner.jpg"
                    mode=""
                    loading-ing-img="two-balls"
                    :loading-error-img="
                      require('@/static/imgs/home/loading_error.png')
                    "
                  />
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <vFooter></vFooter>
      </div>
    </div>
  </div>
</template>
<script>
import { vHeader, vFooter, vSidebar } from "../layout/index.js";
import { _CHINA } from "../src/js/china/index.js";
export default {
  name: "CHINA",
  components: {
    vHeader,
    vFooter,
    vSidebar,
  },
  data: function () {
    return {};
  },
  created() {
     this._CONFIG.colorNavc = 'china';
  },
  mounted: function () {
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new this.WOW({});
      wow.init();
    });
  },
  methods: {
    lpf_window_onresize(paramater) {
      _CHINA.lpf_window_onresize(paramater);
    },
  },
};
</script>
<style lang="scss">
.lpf-china-banner {
  background-color: $edu-background-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-china-banner > .lpf-china-banner-content {
  width: 100%;
  height: auto;
  margin: 0;
  overflow: hidden;
}
.lpf-china-banner > .lpf-china-banner-content > .lpf-link-a > .lpf-image {
  width: 100%;
}
</style>
