<template>
  <div class="page">
    <div class="lpf-content">
      <div class="container-fluid lpf-container-fluid">
        <vHeader></vHeader>
        <vSidebar @lpf_window_onresize="lpf_window_onresize"></vSidebar>
        <div class="lpf-content-main lpf-coopera">
          <div class="block">
            <div class="lpf-coopera-info">
              <div class="row lpf-coopera-info-content">
                <div
                  class="
                    col-md-12 col-lg-7
                    lpf-coopera-info-left
                    wow
                    slideInLeft
                  "
                >
                  <h3>企业排舞</h3>
                  <div class="lpf-txt">
                    <p>
                      <span id="lpf-company">蓝风铃舞蹈</span
                      >不仅仅能为你编排舞蹈，独唱的身体状态，合唱的队形变换，魔术的肢体表达，朗诵的形体状态等等，我们都可以帮你完成。
                    </p>
                    <p>
                      单色编排的成品舞不仅能够教会你跳舞，同时也能够与你一同达到最完美的现场效果。
                    </p>
                    <p>
                      蓝风铃舞蹈教育学校是舞蹈文化推广、舞蹈教练培训、演出经纪、艺人包装等产业于一体的综合性文化集团公司；是“中华人民共和国劳动和社会保障部”定点培训机构；也是武汉市最早从事舞蹈教学的专业连锁培训机构。
                    </p>
                    <p>
                      公司成立至今，已经培养出数万名职业舞者和舞蹈高手，其中包括职业从事舞蹈教练工作的教练员<span
                        class="lpf-jiaoliannum"
                      ></span
                      >余名；并且为各大舞蹈俱乐部、大学等单位输送了一批高素质的舞蹈人才。
                    </p>
                  </div>
                </div>
                <div
                  class="
                    col-md-12 col-lg-5
                    lpf-coopera-info-right
                    wow
                    slideInRight
                  "
                >
                  <video
                    class="lpf-video"
                    preload="none"
                    width="100%"
                    controls="controls"
                    poster="https://m.dansewudao.com/uploads/allimg/200119/8-2001191435560-L.jpg"
                    src="https://p.dansewudao.com/baa8d499cfc64d8f82fa9ee93ca1014b/8c18aba286b14e0a8b70a997e808e51c-ddf5320205a04fed18ae8ce43ac76f49-sd.mp4"
                  ></video>
                </div>
              </div>
            </div>
          </div>
          <div class="block">
            <div class="lpf-coopera-dh">
              <div class="row lpf-coopera-dh-content">
                <div class="col-xs-12 lpf-ul">
                  <li class="lpf-li">
                    <h1>公司年会时</h1>
                    <p
                      title="你的部门想要让其他员工眼前一亮，展现工作能力之外的独特风采么？"
                    >
                      你的部门想要让其他员工眼前一亮，展现工作能力之外的独特风采么？
                    </p>
                  </li>
                  <li class="lpf-li">
                    <h1>周年庆典时</h1>
                    <p title="你想通过精彩的舞蹈恭贺公司的成长么？">
                      你想通过精彩的舞蹈恭贺公司的成长么？
                    </p>
                  </li>
                  <li class="lpf-li">
                    <h1>某个重要场合</h1>
                    <p title="你想在朋友面前展现你出众的舞姿么？">
                      你想在朋友面前展现你出众的舞姿么？
                    </p>
                  </li>
                </div>
              </div>
            </div>
          </div>
          <div class="block">
            <div class="lpf-coopera-program">
              <div class="row lpf-coopera-program-content">
                <div class="lpf-image-content col-xs-12">
                  <fr-image class="lpf-image"
          :src="require('@/static/imgs/home/coopera/wudao.png')"
          mode=""
          loading-ing-img="two-balls"
          :loading-error-img="require('@/static/imgs/home/loading_error.png')"
        />
                </div>
                <div class="lpf-title-content col-xs-12">
                  <p><b>企业排舞项目：</b>（还有其他舞种没有一一罗列，具体内容请咨询蓝风铃舞蹈顾问）</p>
                </div>
                <div class="lpf-table-content col-xs-12">
                  <table class="lpf-table" border="0">
	  	<tbody>
			<tr class="lpf-table-title">
				<td>舞种</td>
				<td>风格</td>
				<td>人员建议</td>
				<td>特点</td>
				<td>难度等级</td>
			</tr>
			<tr class="lpf-table-title1">
				<td class="lpf-table-dance">爵士舞</td>
				<td>韩舞风格</td>
				<td>女生跳</td>
				<td>性感 清新</td>
				<td class="lpf-table-type">一般</td>
			</tr>
			<tr class="lpf-table-title1">
				<td class="lpf-table-dance">&nbsp;</td>
				<td>欧美风格</td>
				<td>可男女同时参与或女生跳</td>
				<td>火辣 动感</td>
				<td class="lpf-table-type">一般</td>
			</tr>
			<tr class="lpf-table-title1">
				<td class="lpf-table-dance">中国舞</td>
				<td>古典舞</td>
				<td>女生跳</td>
				<td>优美</td>
				<td class="lpf-table-type">较难</td>
			</tr>
			<tr class="lpf-table-title1">
				<td class="lpf-table-dance">&nbsp;</td>
				<td>现代舞</td>
				<td>女生跳</td>
				<td>时尚 抒情</td>
				<td class="lpf-table-type">较难</td>
			</tr>
			<tr class="lpf-table-title1">
				<td class="lpf-table-dance">&nbsp;</td>
				<td>傣族舞</td>
				<td>可男女同时参与或女生跳</td>
				<td>抒情</td>
				<td class="lpf-table-type">一般</td>
			</tr>
			<tr class="lpf-table-title1">
				<td class="lpf-table-dance">&nbsp;</td>
				<td>新疆舞</td>
				<td>可男女同时参与或女生跳</td>
				<td>动感</td>
				<td class="lpf-table-type">一般</td>
			</tr>
			<tr class="lpf-table-title1">
				<td class="lpf-table-dance">&nbsp;</td>
				<td>藏族舞</td>
				<td>可男女同时参与或女生跳</td>
				<td>热情</td>
				<td class="lpf-table-type">一般</td>
			</tr>
			<tr class="lpf-table-title1">
				<td class="lpf-table-dance">肚皮舞</td>
				<td>肚皮舞</td>
				<td>女生跳</td>
				<td>性感 火辣</td>
				<td class="lpf-table-type">一般</td>
			</tr>
			<tr class="lpf-table-title1">
				<td class="lpf-table-dance">&nbsp;</td>
				<td>长扇舞</td>
				<td>女生跳</td>
				<td>适合大舞台</td>
				<td class="lpf-table-type">较难</td>
			</tr>
			<tr class="lpf-table-title1">
				<td class="lpf-table-dance">&nbsp;</td>
				<td>金翅舞</td>
				<td>女生跳</td>
				<td>适合大舞台</td>
				<td class="lpf-table-type">较难</td>
			</tr>
			<tr class="lpf-table-title1">
				<td class="lpf-table-dance">&nbsp;</td>
				<td>草裙舞</td>
				<td>女生跳</td>
				<td>民族风俗</td>
				<td class="lpf-table-type">一般</td>
			</tr>
			<tr class="lpf-table-title1">
				<td class="lpf-table-dance">&nbsp;</td>
				<td>纱巾舞</td>
				<td>女生跳</td>
				<td>适合大舞台</td>
				<td class="lpf-table-type">较难</td>
			</tr>
			<tr class="lpf-table-title1">
				<td class="lpf-table-dance">拉丁舞</td>
				<td>恰恰</td>
				<td>可男女同时参与或女生跳</td>
				<td>欢快</td>
				<td class="lpf-table-type">一般</td>
			</tr>
			<tr class="lpf-table-title1">
				<td class="lpf-table-dance">&nbsp;</td>
				<td>伦巴</td>
				<td>可男女同时参与或女生跳</td>
				<td>浪漫 </td>
				<td class="lpf-table-type">一般</td>
			</tr>
			<tr class="lpf-table-title1">
				<td class="lpf-table-dance">&nbsp;</td>
				<td>牛仔</td>
				<td>可男女同时参与或女生跳</td>
				<td>动感 </td>
				<td class="lpf-table-type">较难</td>
			</tr>
			<tr class="lpf-table-title1">
				<td class="lpf-table-dance">&nbsp;</td>
				<td>桑巴</td>
				<td>可男女同时参与或女生跳</td>
				<td>激情 </td>
				<td class="lpf-table-type">较难</td>
			</tr>
			<tr class="lpf-table-title1">
				<td class="lpf-table-dance">摩登舞</td>
				<td>华尔兹</td>
				<td>可男女同时参与或女生跳</td>
				<td>端庄</td>
				<td class="lpf-table-type">较难</td>
			</tr>
			<tr class="lpf-table-title1">
				<td class="lpf-table-dance">&nbsp;</td>
				<td>宫廷舞</td>
				<td>可男女同时参与或女生跳</td>
				<td>端庄</td>
				<td class="lpf-table-type">一般</td>
			</tr>
			<tr class="lpf-table-title1">
				<td class="lpf-table-dance">&nbsp;</td>
				<td>探戈</td>
				<td>可男女同时参与或女生跳</td>
				<td>端庄</td>
				<td class="lpf-table-type">较难</td>
			</tr>
		</tbody>
	</table>
                </div>
              </div>
            </div>
          </div>
           <div class="block">
            <div class="lpf-coopera-jxtd">
              <div class="row lpf-coopera-jxtd-content">
<div class="col-md-12 col-lg-6 lpf-left">
            <h1>蓝风铃舞蹈能提供什么？</h1>
            <p>你们是否已经很努力的练习了，可在现场表演时却达不到期待的舞台效果。好的现场效果和表现力，化妆造型，服装有很大的关系。</p>
            <p>在我们的课程中包含有提升舞蹈演员的表现力的内容，适合所有表演舞蹈风格的舞台化妆造型建议，专属风格的舞台服装搭配建议，帮助无基础的学员演绎出精彩的节目。</p>
            <router-link class="lpf-link-a" to="">了解更多>></router-link>
        </div>
        <div class="col-md-12 col-lg-6 lpf-right">
          <fr-image
                      class="lpf-image"
                      src="https://www.dansewudao.com/skin/img/coach/index/td.png"
                      mode=""
                      loading-ing-img="two-balls"
                      :loading-error-img="
                        require('@/static/imgs/home/loading_error.png')
                      "
                    />
        </div>
              </div>
            </div>
          </div>
          <div class="block">
            <div class="lpf-coopera-video">
              <div class="row lpf-coopera-video-content">
                <div class="col-md-12 lpf-coopera-title">
                  <fr-image
                      class="lpf-image"
                      src="https://www.dansewudao.com/skin/img/about/coopera/xgsp.png"
                      mode=""
                      loading-ing-img="two-balls"
                      :loading-error-img="
                        require('@/static/imgs/home/loading_error.png')
                      "
                    />
                  <a href="/video">&gt;&gt; 更多视频...</a>
                  <fr-image
                      class="lpf-image1"
                      src="https://www.dansewudao.com/skin/img/about/coopera/ct.png"
                      mode=""
                      loading-ing-img="two-balls"
                      :loading-error-img="
                        require('@/static/imgs/home/loading_error.png')
                      "
                    />
                </div>
                <div class="col-md-12 lpf-coopera-ul">
		<li>
      <router-link class="lpf-link-a" to="">
       <fr-image
                      class="lpf-image"
                      src="https://m.dansewudao.com/uploadImg/16152691398375.jpg"
                      mode=""
                      loading-ing-img="two-balls"
                      :loading-error-img="
                        require('@/static/imgs/home/loading_error.png')
                      "
                    />
				<p>武汉流行舞导师团体编舞作品</p>
			</router-link>
		</li>
		<li>
			 <router-link class="lpf-link-a" to="">
       <fr-image
                      class="lpf-image"
                      src="https://m.dansewudao.com/uploadImg/16152691398274.jpg"
                      mode=""
                      loading-ing-img="two-balls"
                      :loading-error-img="
                        require('@/static/imgs/home/loading_error.png')
                      "
                    />
				<p>流行舞导师团体《float》教</p>
       </router-link>
		</li>
		<li>
			 <router-link class="lpf-link-a" to="">
       <fr-image
                      class="lpf-image"
                      src="https://m.dansewudao.com/uploadImg/16152691398183.jpg"
                      mode=""
                      loading-ing-img="two-balls"
                      :loading-error-img="
                        require('@/static/imgs/home/loading_error.png')
                      "
                    />
				<p>流行舞教练班学员作品</p>
       </router-link>
		</li>
		<li>
			 <router-link class="lpf-link-a" to="">
       <fr-image
                      class="lpf-image"
                      src="https://m.dansewudao.com/uploadImg/1611385941412.jpg"
                      mode=""
                      loading-ing-img="two-balls"
                      :loading-error-img="
                        require('@/static/imgs/home/loading_error.png')
                      "
                    />
				<p>爵士舞教练班学员练习室作品</p>
       </router-link>
		</li>
                </div>
              </div>
            </div>
          </div>
        </div>
        <vFooter></vFooter>
      </div>
    </div>
  </div>
</template>
<script>
import { vHeader, vFooter, vSidebar } from "../layout/index.js";
import { _COOPERA } from "../src/js/coopera/index.js";
export default {
  name: "COOPERA",
  components: {
    vHeader,
    vFooter,
    vSidebar,
  },
  data: function () {
    return {};
  },
  created() {},
  mounted: function () {
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new this.WOW({});
      wow.init();
      _COOPERA.lpf_ul_hover();
    });
  },
  methods: {
    lpf_window_onresize(paramater) {
      _COOPERA.lpf_window_onresize(paramater);
    },
  },
};
</script>
<style lang="scss">
.lpf-coopera-info {
  //   margin-top: 4rem !important;
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-coopera-info > .lpf-coopera-info-content {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 3rem auto;
  overflow: hidden;
}
.lpf-coopera-info-content > .lpf-coopera-info-left > h3 {
  color: $edu-color-h4;
  font-size: 2.4rem;
  margin-top: 1rem;
}
.lpf-coopera-info-content > .lpf-coopera-info-left > .lpf-txt {
  margin-top: 2rem;
}
.lpf-coopera-info-content > .lpf-coopera-info-left > .lpf-txt > p {
  color: $edu-color-span1;
  line-height: 3rem;
  font-size: 1.3rem;
  text-indent: 2.7rem;
}
.lpf-coopera-info-content
  > .lpf-coopera-info-left
  > .lpf-txt
  > p
  > .lpf-jiaoliannum::before {
  content: "800";
}
.lpf-coopera-info-content > .lpf-coopera-info-right > .lpf-video {
  width: 100%;
  height: 100%;
  object-fit: fill;
  outline: none;
}
.lpf-coopera-dh {
  //   margin-top: 4rem !important;
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-coopera-dh > .lpf-coopera-dh-content {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 3rem auto;
  overflow: hidden;
}
.lpf-coopera-dh > .lpf-coopera-dh-content > .lpf-ul > .lpf-li {
  width: 31.333%;
  height: 16.5rem;
  padding: 1.5rem 3%;
  margin-right: 3%;
  margin-bottom: 1.5rem;
  float: left;
  background: $edu-background-color1;
}
.lpf-coopera-dh > .lpf-coopera-dh-content > .lpf-ul > .lpf-li:last-child {
  margin-right: 0;
}
.lpf-coopera-dh > .lpf-coopera-dh-content > .lpf-ul > .lpf-li > h1 {
  color: $sidebar-background-color;
  font-size: 3.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lpf-coopera-dh > .lpf-coopera-dh-content > .lpf-ul > .lpf-li > p {
  color: $black-color;
  margin-top: 1rem;
  line-height: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.lpf-coopera-program {
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-coopera-program > .lpf-coopera-program-content {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 0 auto 3rem;
  overflow: hidden;
}
.lpf-coopera-program > .lpf-coopera-program-content>.lpf-image-content{
  display: flex;
  align-items: center;
  justify-content: center;
}
.lpf-coopera-program > .lpf-coopera-program-content>.lpf-image-content>.lpf-image{
  width: 46rem;
  height: 7.5rem;
}
.lpf-coopera-program > .lpf-coopera-program-content>.lpf-title-content{
display: flex;
align-items: center;
justify-content: center;
}
.lpf-coopera-program > .lpf-coopera-program-content>.lpf-title-content>p{
text-align: center;
    font-size: 2.3rem;
    color: $black-color;
}
.lpf-coopera-program > .lpf-coopera-program-content>.lpf-title-content>p>b{
  font-weight: bold;
  color: $sidebar-background-color;
  
}
.lpf-coopera-program > .lpf-coopera-program-content>.lpf-table-content>.lpf-table{
  width: 100%;
  margin-top: 2rem;
   border-collapse: separate;
         border-spacing: 0.2rem;
}
.lpf-coopera-program > .lpf-coopera-program-content>.lpf-table-content>.lpf-table>tbody>.lpf-table-title{
background: $sidebar-background-color;
    height: 4.9rem;
    font-size: 1.6rem;
    color: $white-color;
    font-weight: bold;
    text-align: center;
}
.lpf-coopera-program > .lpf-coopera-program-content>.lpf-table-content>.lpf-table>tbody>.lpf-table-title>td{
border-bottom: 1px solid $footer-line-color1;
}
.lpf-coopera-program > .lpf-coopera-program-content>.lpf-table-content>.lpf-table>tbody>.lpf-table-title1{
  height: 3.9rem;
    font-size: 1.4rem;
    color: $title-color1;
}
.lpf-coopera-program > .lpf-coopera-program-content>.lpf-table-content>.lpf-table>tbody>.lpf-table-title1>.lpf-table-dance{
    border-color: $white-color !important;
    text-align: center;
    color: $black-color;
    font-weight: bold;
    padding: 0 !important;
}
.lpf-coopera-program > .lpf-coopera-program-content>.lpf-table-content>.lpf-table>tbody>.lpf-table-title1>.lpf-table-type{
text-align: center;
    padding: 0 !important;
}
.lpf-coopera-program > .lpf-coopera-program-content>.lpf-table-content>.lpf-table>tbody>.lpf-table-title1>td{
  padding: 0 2.6rem;
      border-bottom: 1px solid $footer-line-color1;
}
.lpf-coopera-jxtd {
  background-color: $edu-color-h5;
  display: flex;
  flex-direction: column;
  align-items: center;
      margin-top: 2rem;
    margin-bottom: 10rem;
}
.lpf-coopera-jxtd > .lpf-coopera-jxtd-content {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 0;
  overflow: hidden;
}
.lpf-coopera-jxtd > .lpf-coopera-jxtd-content>.lpf-left{
  color: $black-color;
}
.lpf-coopera-jxtd > .lpf-coopera-jxtd-content>.lpf-left>h1{
      font-size: 5rem;
    margin-top: 7rem;
    margin-bottom: 4rem;
}
.lpf-coopera-jxtd > .lpf-coopera-jxtd-content>.lpf-left>p{
  font-size: 1.8rem;
    line-height: 3rem;
    margin-bottom: 2rem;
}
.lpf-coopera-jxtd > .lpf-coopera-jxtd-content>.lpf-left>.lpf-link-a{
      display: block;
    width: 15rem;
    line-height: 4rem;
    background: $sidebar-background-color;
    font-size: 1.5rem;
    text-align: center;
    color: $white-color;
    border-radius: 2rem;
    font-weight: bold;
    margin-top: 5rem;
}
.lpf-coopera-jxtd > .lpf-coopera-jxtd-content>.lpf-right>.lpf-image{
  width: 100%;
  margin-top: 3rem;
}
.lpf-coopera-video {
  //   margin-top: 4rem !important;
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-coopera-video > .lpf-coopera-video-content {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 0 auto 2rem;
  overflow: hidden;
}
.lpf-coopera-video > .lpf-coopera-video-content>.lpf-coopera-title{
    height: 7.4rem;
 }
.lpf-coopera-video > .lpf-coopera-video-content>.lpf-coopera-title>.lpf-image{
width: 30.6rem;
float: left;
}
.lpf-coopera-video > .lpf-coopera-video-content>.lpf-coopera-title>.lpf-image1{
  width: 100%;
  height: 2.3rem;
      float: left;
}
.lpf-coopera-video > .lpf-coopera-video-content>.lpf-coopera-title>a{
  display: block;
    float: right;
    color: #F80000;
    font-size: 1.4rem;
    line-height: 5rem;
}
.lpf-coopera-video > .lpf-coopera-video-content>.lpf-coopera-ul>li{
width: 23.5%;
    margin-right: 2%;
    float: left;
}
.lpf-coopera-video > .lpf-coopera-video-content>.lpf-coopera-ul>li:last-child{
  margin-right: 0;
}
.lpf-coopera-video > .lpf-coopera-video-content>.lpf-coopera-ul>li>a{
      color: $edu-color-h1;
    text-decoration: none;
}
.lpf-coopera-video > .lpf-coopera-video-content>.lpf-coopera-ul>li>a>.lpf-image{
      border: 0.2rem solid $white-color;
      box-sizing: border-box;
      width: 100%;
}
.lpf-coopera-video > .lpf-coopera-video-content>.lpf-coopera-ul>li>a>p{
      height: 5rem;
    line-height: 2.4rem;
    font-size: 1.5rem;
    color: $edu-color-span;
    font-weight: bold;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
@media (max-width: 1199px){
.lpf-coopera-jxtd > .lpf-coopera-jxtd-content>.lpf-left>.lpf-link-a{
  margin-left: auto;
    margin-right: auto;
}
}

@media (max-width: 900px) {
.lpf-coopera-video > .lpf-coopera-video-content>.lpf-coopera-ul>li{
  width: 49%;
  margin-right: 2%;
}
.lpf-coopera-video > .lpf-coopera-video-content>.lpf-coopera-ul>li:nth-child(2n){
   margin-right: 0;
}
}
@media (max-width: 835px) {
  .lpf-coopera-dh > .lpf-coopera-dh-content > .lpf-ul > .lpf-li {
    width: 48.5%;
    margin-right: 0;
  }
  .lpf-coopera-dh > .lpf-coopera-dh-content > .lpf-ul > .lpf-li:first-child {
    margin-right: 3%;
  }
}
@media (max-width: 510px) {
  .lpf-coopera-dh > .lpf-coopera-dh-content > .lpf-ul > .lpf-li {
    width: 100%;
    margin-right: 0;
  }
}
@media (max-width: 500px) {
.lpf-coopera-video > .lpf-coopera-video-content>.lpf-coopera-ul>li{
  width: 100%;
  margin-right: 0;
}
}
</style>
