<template>
  <div class="page">
    <div class="lpf-content">
      <div class="container-fluid lpf-container-fluid">
        <vHeader></vHeader>
        <vSidebar @lpf_window_onresize="lpf_window_onresize"></vSidebar>
        <div class="lpf-content-main lpf-coach-list">
          <div class="block">
            <div class="lpf-coach-banner">
              <div class="row lpf-coach-banner-content">
                <router-link class="lpf-link-a" to="">
                  <fr-image
                    class="lpf-image"
                    src="https://www.dansewudao.com/skin/img/coach/index/banner.jpg"
                    mode=""
                    loading-ing-img="two-balls"
                    :loading-error-img="
                      require('@/static/imgs/home/loading_error.png')
                    "
                  />
                </router-link>
              </div>
            </div>
          </div>
          <div class="block">
            <div class="lpf-coach-class">
              <div class="row lpf-coach-class-content">
                <div class="col-xs-12 lpf-coach-class-title">开设教练班</div>
                <div class="col-xs-12 lpf-li">
                  <router-link class="lpf-link-a" to="/jazz/coach">
                    <fr-image
                      class="lpf-image"
                      src="https://www.dansewudao.com/skin/img/coach/index/wz1.jpg"
                      mode=""
                      loading-ing-img="two-balls"
                      :loading-error-img="
                        require('@/static/imgs/home/loading_error.png')
                      "
                      alt="爵士舞"
                    />
                    <p><span>热门</span>爵士舞教练班</p>
                  </router-link>
                  <router-link class="lpf-link-a" to="/jazz/coach">
                    <fr-image
                      class="lpf-image"
                      src="https://www.dansewudao.com/skin/img/coach/index/wz1.jpg"
                      mode=""
                      loading-ing-img="two-balls"
                      :loading-error-img="
                        require('@/static/imgs/home/loading_error.png')
                      "
                      alt="爵士舞"
                    />
                    <p><span>热门</span>爵士舞教练班</p>
                  </router-link>
                  <router-link class="lpf-link-a" to="/jazz/coach">
                    <fr-image
                      class="lpf-image"
                      src="https://www.dansewudao.com/skin/img/coach/index/wz1.jpg"
                      mode=""
                      loading-ing-img="two-balls"
                      :loading-error-img="
                        require('@/static/imgs/home/loading_error.png')
                      "
                      alt="爵士舞"
                    />
                    <p><span>热门</span>爵士舞教练班</p>
                  </router-link>
                  <router-link class="lpf-link-a" to="/jazz/coach">
                    <fr-image
                      class="lpf-image"
                      src="https://www.dansewudao.com/skin/img/coach/index/wz1.jpg"
                      mode=""
                      loading-ing-img="two-balls"
                      :loading-error-img="
                        require('@/static/imgs/home/loading_error.png')
                      "
                      alt="爵士舞"
                    />
                    <p><span>热门</span>爵士舞教练班</p>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="block">
            <div class="lpf-coach-base">
              <div class="row lpf-coach-base-content">
                <div class="col-xs-12 lpf-li">
                  <router-link class="lpf-link-a" to="/jazz/coach">
                    <div class="lpf-top">
                      <div class="lpf-left">
                        <h1><span class="lpf-fenguannum"></span></h1>
                        <h2>全国连锁</h2>
                        <p>National chain</p>
                      </div>
                      <div class="lpf-right">
                        <fr-image
                          class="lpf-image"
                          src="https://www.dansewudao.com/skin/img/coach/index/jd1.png"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                        />
                      </div>
                    </div>
                    <div class="lpf-bottom">
                      <span class="lpf-fenguannum"></span>家直营连锁舞蹈培训场馆
                    </div>
                  </router-link>
                  <router-link class="lpf-link-a" to="/jazz/coach">
                    <div class="lpf-top">
                      <div class="lpf-left">
                        <h1><span class="lpf-fenguannum"></span></h1>
                        <h2>全国连锁</h2>
                        <p>National chain</p>
                      </div>
                      <div class="lpf-right">
                        <fr-image
                          class="lpf-image"
                          src="https://www.dansewudao.com/skin/img/coach/index/jd1.png"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                        />
                      </div>
                    </div>
                    <div class="lpf-bottom">
                      <span class="lpf-fenguannum"></span>家直营连锁舞蹈培训场馆
                    </div>
                  </router-link>
                  <router-link class="lpf-link-a" to="/jazz/coach">
                    <div class="lpf-top">
                      <div class="lpf-left">
                        <h1><span class="lpf-fenguannum"></span></h1>
                        <h2>全国连锁</h2>
                        <p>National chain</p>
                      </div>
                      <div class="lpf-right">
                        <fr-image
                          class="lpf-image"
                          src="https://www.dansewudao.com/skin/img/coach/index/jd1.png"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                        />
                      </div>
                    </div>
                    <div class="lpf-bottom">
                      <span class="lpf-fenguannum"></span>家直营连锁舞蹈培训场馆
                    </div>
                  </router-link>
                  <router-link class="lpf-link-a" to="/jazz/coach">
                    <div class="lpf-top">
                      <div class="lpf-left">
                        <h1><span class="lpf-fenguannum"></span></h1>
                        <h2>全国连锁</h2>
                        <p>National chain</p>
                      </div>
                      <div class="lpf-right">
                        <fr-image
                          class="lpf-image"
                          src="https://www.dansewudao.com/skin/img/coach/index/jd1.png"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                        />
                      </div>
                    </div>
                    <div class="lpf-bottom">
                      <span class="lpf-fenguannum"></span>家直营连锁舞蹈培训场馆
                    </div>
                  </router-link>
                  <router-link class="lpf-link-a" to="/jazz/coach">
                    <div class="lpf-top">
                      <div class="lpf-left">
                        <h1><span class="lpf-fenguannum"></span></h1>
                        <h2>全国连锁</h2>
                        <p>National chain</p>
                      </div>
                      <div class="lpf-right">
                        <fr-image
                          class="lpf-image"
                          src="https://www.dansewudao.com/skin/img/coach/index/jd1.png"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                        />
                      </div>
                    </div>
                    <div class="lpf-bottom">
                      <span class="lpf-fenguannum"></span>家直营连锁舞蹈培训场馆
                    </div>
                  </router-link>
                  <router-link class="lpf-link-a" to="/jazz/coach">
                    <div class="lpf-top">
                      <div class="lpf-left">
                        <h1><span class="lpf-fenguannum"></span></h1>
                        <h2>全国连锁</h2>
                        <p>National chain</p>
                      </div>
                      <div class="lpf-right">
                        <fr-image
                          class="lpf-image"
                          src="https://www.dansewudao.com/skin/img/coach/index/jd1.png"
                          mode=""
                          loading-ing-img="two-balls"
                          :loading-error-img="
                            require('@/static/imgs/home/loading_error.png')
                          "
                        />
                      </div>
                    </div>
                    <div class="lpf-bottom">
                      <span class="lpf-fenguannum"></span>家直营连锁舞蹈培训场馆
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="block">
            <div class="lpf-coach-train">
              <div class="row lpf-coach-train-content">
                <div class="col-xs-12 lpf-train-title">教练培训</div>
                <div class="col-xs-12 lpf-train-intro">
                  <div class="col-xs-12 col-md-7 lpf-left">
                    <h3>
                      单色舞蹈教练培训体系<br />是中国舞蹈培训行业的一个标志性课程
                    </h3>
                    <i></i>
                    <p>
                      通过数十年专业教练培训经验研发出OTS教学体系，让加入单色舞蹈教练班的学员，除了在较短时间内学习到专业的舞蹈技能之外，同时还能够学到作为舞蹈教练应该具备的专业理论知识、授课技巧、编舞技巧等。开设一阶零基础教练培训班、二阶提高教练培训班、三阶进修教练培训班，满足不同舞蹈基础的人群需求；每一步都能打牢基础、循序渐进；教学过程中采用多种教学法如镜面教学法、CP教学法、分组教学法、模拟教学法等，让学员在学习和练习的过程中能充分吸收，具备更高的专业技巧和相对全面的教学能力。
                    </p>
                  </div>
                  <div class="col-xs-12 col-md-5 lpf-right">
                    <fr-image
                      class="lpf-image"
                      src="https://www.dansewudao.com/skin/img/coach/index/js.jpg"
                      mode=""
                      loading-ing-img="two-balls"
                      :loading-error-img="
                        require('@/static/imgs/home/loading_error.png')
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="block">
            <div class="lpf-coach-comparison">
              <div class="row lpf-coach-comparison-content">
                <div class="col-md-12 col-lg-6 lpf-left">
                  <div class="lpf-content">
                    <h3>单色国际舞蹈培训</h3>
                  <div class="row lpf-ul">
                    <li class="col-xs-12">
                      <div class="lpf-num">01</div>
                      <div class="lpf-tt">
                        <a>专业资质</a>
                        <p>
                          单色舞蹈被认证“中国舞蹈家协会5星级指定考级单位、CEFA中国艺术职业教育学会指定考级考证单位”等国家承认资质
                        </p>
                      </div>
                    </li>
                    <li class="col-xs-12">
                      <div class="lpf-num">02</div>
                      <div class="lpf-tt">
                        <a>全国连锁 口碑真实</a>
                        <p>
                          单色舞蹈全国开设有27家直营连锁分馆（无加盟），学员可以就近选择；70%的新学员由老学员介绍
                        </p>
                      </div>
                    </li>
                    <li class="col-xs-12">
                      <div class="lpf-num">03</div>
                      <div class="lpf-tt">
                        <a>优秀师资团队</a>
                        <p>
                          单色依托品牌优势和高薪资，吸引科班出身的舞者亲自任教；舞龄平均6年以上，教龄平均3年以上；每年需跟随国际舞蹈大师学习进修3次以上
                        </p>
                      </div>
                    </li>
                    <li class="col-xs-12">
                      <div class="lpf-num">04</div>
                      <div class="lpf-tt">
                        <a>专业课程体系</a>
                        <p>
                          单色舞蹈通过数十年专业教练培训积累的经验并结合国际舞蹈教学标准，研发出4.0教练培训体系，让学员从零基础一步步变身舞蹈高手和职业舞蹈老师。课程内容不仅包含国内外流行元素，还会根据学员情况酌情加入各类特色课程
                        </p>
                      </div>
                    </li>
                    <li class="col-xs-12">
                      <div class="lpf-num">05</div>
                      <div class="lpf-tt">
                        <a>一对一服务</a>
                        <p>
                          单色每位学员都拥有专属课程顾问一对一服务，为您解决课程咨询、续卡、请假、舞蹈服装购买、推荐福利登记等各种服务
                        </p>
                      </div>
                    </li>
                    <li class="col-xs-12">
                      <div class="lpf-num">06</div>
                      <div class="lpf-tt">
                        <a>演出实践平台</a>
                        <p>
                          单色每年自主创办多次大型舞蹈展演，为学员提供展示舞蹈才艺和锻炼自信心的良好平台，并且拥有商业演出、大型比赛及师资储备团队
                        </p>
                      </div>
                    </li>
                    <li class="col-xs-12">
                      <div class="lpf-num">07</div>
                      <div class="lpf-tt">
                        <a>舞蹈MV拍摄</a>
                        <p>
                          单色拥有专业的舞蹈视频拍摄制作团队，精心为学员量身打造属于自己的舞蹈MV，留下美好的青春回忆！
                        </p>
                      </div>
                    </li>
                  </div>
                  </div>
                </div>
                <div class="col-md-12 col-lg-6 lpf-right">
                  <div class="lpf-content">
                    <h3>其他舞蹈培训机构</h3>
                  <div class="row lpf-ul">
                    <li class="col-xs-12">
                      <img src="https://www.dansewudao.com/skin/img/coach/index/pk_cw.png" />
                      <p>
                        大多无国家认证资质；学员想考级需要去别的指定地点参加考试，耗费时间和精力
                      </p>
                    </li>
                    <li class="col-xs-12">
                      <img src="https://www.dansewudao.com/skin/img/coach/index/pk_cw.png" />
                      <p>
                        基本都是一个工作室，依靠自己拉人招生，后续教学质量无法保障
                      </p>
                    </li>
                    <li class="col-xs-12">
                      <img src="https://www.dansewudao.com/skin/img/coach/index/pk_cw.png" />
                      <p>
                        非科班出身、大多是兼职；即使有专业教师开班的培训机构，学员人数也较多，一人难以顾暇；而且老师如果不定期进修，很难跟上时代潮流，教学新知识
                      </p>
                    </li>
                    <li class="col-xs-12">
                      <img src="https://www.dansewudao.com/skin/img/coach/index/pk_cw.png" />
                      <p>模仿市场上的教学模式，只知表面不知根本</p>
                    </li>
                    <li class="col-xs-12">
                      <img src="https://www.dansewudao.com/skin/img/coach/index/pk_cw.png" />
                      <p>
                        报名后无后续专业服务，没有连锁的门店作为依托，售后服务无法保障
                      </p>
                    </li>
                    <li class="col-xs-12">
                      <img src="https://www.dansewudao.com/skin/img/coach/index/pk_cw.png" />
                      <p>
                        一般的机构是无法承担大型汇演成本的，没有大的宣传力度，学员想参加演出、比赛，均需自己寻找
                      </p>
                    </li>
                    <li class="col-xs-12">
                      <img src="https://www.dansewudao.com/skin/img/coach/index/pk_cw.png" />
                      <p>
                        大部分机构并没有配备专业拍摄团队；即使拍摄了视频也没有强大的市场部去宣传推广
                      </p>
                    </li>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="block">
            <div class="lpf-coach-jxtd">
              <div class="row lpf-coach-jxtd-content">
<div class="col-md-12 col-lg-6 lpf-left">
            <h1>教研团队</h1>
            <p>单色舞蹈目前有课程研发管理团队均具备多年的一线教学经验。此外，单色舞蹈与国内外知名的舞蹈学者、机构展开广泛合作与交流，定期邀请来自美国、日本、韩国、新加坡、乌克兰、印度等地的世界知名舞蹈大师前来针对内部学员交流授课。</p>
            <p>单色舞蹈的任课教室均需要经过层层筛选和历练，从初次申请到代课任教，需经过初试、复试试课、储备团、助教、专业培训、试用6道程序才可上岗。目前，<span>80%</span>的老师毕业于专业舞蹈院校，绝大多数拥有本科及以上学历；<span>85%</span>的老师拥有5年及以上教龄，单人培养超<span>1000</span>名舞蹈学员。</p>
        </div>
        <div class="col-md-12 col-lg-6 lpf-right">
          <fr-image
                      class="lpf-image"
                      src="https://www.dansewudao.com/skin/img/coach/index/td.png"
                      mode=""
                      loading-ing-img="two-balls"
                      :loading-error-img="
                        require('@/static/imgs/home/loading_error.png')
                      "
                    />
        </div>
              </div>
            </div>
          </div>
        </div>
        <vFooter></vFooter>
      </div>
    </div>
  </div>
</template>
<script>
import { vHeader, vFooter, vSidebar } from "../layout/index.js";
import { _COACH } from "../src/js/coach/index.js";
export default {
  name: "COACH",
  components: {
    vHeader,
    vFooter,
    vSidebar,
  },
  data: function () {
    return {};
  },
  created() {
     this._CONFIG.colorNavc = 'coach';
  },
  mounted: function () {
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new this.WOW({});
      wow.init();
    });
  },
  methods: {
    lpf_window_onresize(paramater) {
      _COACH.lpf_window_onresize(paramater);
    },
  },
};
</script>
<style lang="scss">
.lpf-coach-banner {
  background-color: $edu-background-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-coach-banner > .lpf-coach-banner-content {
  width: 100%;
  height: auto;
  margin: 0;
  overflow: hidden;
}
.lpf-coach-banner > .lpf-coach-banner-content > .lpf-link-a > .lpf-image {
  width: 100%;
}
.lpf-coach-class {
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-coach-class > .lpf-coach-class-content {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 0;
  overflow: hidden;
}
.lpf-coach-class > .lpf-coach-class-content > .lpf-coach-class-title {
  margin-top: 7rem;
  margin-bottom: 2rem;
  font-size: 4rem;
  color: $footer-background-color;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lpf-coach-class > .lpf-coach-class-content > .lpf-li {
  overflow: hidden;
  margin-bottom: 3rem;
}
.lpf-coach-class
  > .lpf-coach-class-content
  > .lpf-li
  > .lpf-link-a:nth-child(2n) {
  margin-right: 2% !important;
}
.lpf-coach-class > .lpf-coach-class-content > .lpf-li > .lpf-link-a {
  display: block;
  float: left;
  width: 32% !important;
  margin-right: 2% !important;
  margin-bottom: 1rem;
  color: $edu-color-h1;
  text-decoration: none;
}
.lpf-coach-class
  > .lpf-coach-class-content
  > .lpf-li
  > .lpf-link-a:nth-child(3n) {
  margin-right: 0 !important;
}
.lpf-coach-class
  > .lpf-coach-class-content
  > .lpf-li
  > .lpf-link-a
  > .lpf-image {
  border: none;
  vertical-align: middle;
  width: 100%;
  border-radius: 1.3rem;
}
.lpf-coach-class > .lpf-coach-class-content > .lpf-li > .lpf-link-a > p {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lpf-coach-class > .lpf-coach-class-content > .lpf-li > .lpf-link-a > p > span {
  display: inline-block;
  width: 7.6rem;
  height: 3.6rem;
  line-height: 3.6rem;
  background: $sidebar-background-color;
  color: $white-color;
  text-align: center;
  font-size: 2.3rem;
  border-radius: 0.5rem;
  margin-right: 1rem;
  vertical-align: text-bottom;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lpf-coach-base {
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-coach-base > .lpf-coach-base-content {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 0;
  overflow: hidden;
}
.lpf-coach-base
  > .lpf-coach-base-content
  > .lpf-li
  > .lpf-link-a:nth-child(2n) {
  margin-right: 2% !important;
}
.lpf-coach-base > .lpf-coach-base-content > .lpf-li > .lpf-link-a {
  float: left;
  width: 32% !important;
  margin-right: 2% !important;
  margin-bottom: 3rem;
  text-decoration: none;
}
.lpf-coach-base
  > .lpf-coach-base-content
  > .lpf-li
  > .lpf-link-a:nth-child(3n) {
  margin-right: 0 !important;
}
.lpf-coach-base > .lpf-coach-base-content > .lpf-li > .lpf-link-a > .lpf-top {
  background: $edu-color-h5;
  height: 253px;
  border-radius: 1rem;
  color: $sidebar-background-color;
  overflow: hidden;
  display: flex;
}
.lpf-coach-base
  > .lpf-coach-base-content
  > .lpf-li
  > .lpf-link-a
  > .lpf-top
  > .lpf-left {
  width: 43%;
  margin-left: 7%;
  margin-top: 2.8rem;
  height: calc(100% - 2.8rem);
  display: flex;
  flex-direction: column;
}
.lpf-coach-base
  > .lpf-coach-base-content
  > .lpf-li
  > .lpf-link-a
  > .lpf-top
  > .lpf-left
  > h1 {
  font-size: 6rem;
}
.lpf-coach-base
  > .lpf-coach-base-content
  > .lpf-li
  > .lpf-link-a
  > .lpf-top
  > .lpf-left
  > h1
  > .lpf-fenguannum::before {
  content: "28";
}
.lpf-coach-base
  > .lpf-coach-base-content
  > .lpf-li
  > .lpf-link-a
  > .lpf-top
  > .lpf-left
  > h2 {
  font-size: 2rem;
  margin-top: 0.5rem;
}
.lpf-coach-base
  > .lpf-coach-base-content
  > .lpf-li
  > .lpf-link-a
  > .lpf-top
  > .lpf-left
  > p {
  font-size: 1.3rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin-top: auto;
}
.lpf-coach-base
  > .lpf-coach-base-content
  > .lpf-li
  > .lpf-link-a
  > .lpf-top
  > .lpf-right {
  width: 50%;
  margin-top: 1rem;
  margin-right: 1.5rem;
}
.lpf-coach-base
  > .lpf-coach-base-content
  > .lpf-li
  > .lpf-link-a
  > .lpf-top
  > .lpf-right
  > .lpf-image {
  width: 100%;
}
.lpf-coach-base
  > .lpf-coach-base-content
  > .lpf-li
  > .lpf-link-a
  > .lpf-bottom {
  font-size: 2.7rem;
  color: #333333;
  font-weight: bold;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lpf-coach-base
  > .lpf-coach-base-content
  > .lpf-li
  > .lpf-link-a
  > .lpf-bottom
  > .lpf-fenguannum::before {
  content: "28";
}
.lpf-coach-train {
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-coach-train > .lpf-coach-train-content {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 0;
  overflow: hidden;
}
.lpf-coach-train > .lpf-coach-train-content > .lpf-train-title {
  margin-top: 7rem;
  margin-bottom: 2rem;
  font-size: 4rem;
  color: $footer-background-color;
}
.lpf-coach-train > .lpf-coach-train-content > .lpf-train-intro {
  border: 0.5rem solid $edu-background-color1;
  border-radius: 1rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: calc(100% - 3rem);
}
.lpf-coach-train > .lpf-coach-train-content > .lpf-train-intro > .lpf-left {
  color: $edu-color-h1;
}
.lpf-coach-train
  > .lpf-coach-train-content
  > .lpf-train-intro
  > .lpf-left
  > h3 {
  font-size: 2.6rem;
  font-weight: normal;
}
.lpf-coach-train > .lpf-coach-train-content > .lpf-train-intro > .lpf-left > i {
  display: block;
  width: 6rem;
  height: 0.2rem;
  background: #000;
  margin: 2rem 0 2rem 0;
}
.lpf-coach-train > .lpf-coach-train-content > .lpf-train-intro > .lpf-left > p {
  font-size: 1.5rem;
  margin-top: 2rem;
  line-height: 2.5rem;
}
.lpf-coach-train > .lpf-coach-train-content > .lpf-train-intro > .lpf-right {
  padding-right: 0 !important;
  padding-left: 0 !important;
  position: relative;
}
.lpf-coach-train
  > .lpf-coach-train-content
  > .lpf-train-intro
  > .lpf-right
  > .lpf-image {
  width: 95%;
  position: absolute;
  top: -3.4rem;
  right: -0.5rem;
  bottom: 0;
  border-radius: 0 1rem 1rem 0;
  overflow: hidden;
}
.lpf-coach-comparison {
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpf-coach-comparison > .lpf-coach-comparison-content {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin-top: 5rem;
  overflow: hidden;
}
.lpf-coach-comparison > .lpf-coach-comparison-content>.lpf-left{
  padding-right: 0!important;
}
.lpf-coach-comparison > .lpf-coach-comparison-content>.lpf-left>.lpf-content{
  background: $sidebar-background-color;
  padding: 0 1rem;
}
.lpf-coach-comparison > .lpf-coach-comparison-content>.lpf-left>.lpf-content>h3{
      font-size: 3rem;
    color: $white-color;
    line-height: 10rem;
    text-align: center;
}
.lpf-coach-comparison > .lpf-coach-comparison-content>.lpf-left>.lpf-content>.lpf-ul{
    margin: 3rem auto 0rem;
}
.lpf-coach-comparison > .lpf-coach-comparison-content>.lpf-left>.lpf-content>.lpf-ul>li{
  margin-bottom: 2rem;
  display: flex;
}
.lpf-coach-comparison > .lpf-coach-comparison-content>.lpf-left>.lpf-content>.lpf-ul>li>.lpf-num{
      width: 6rem;
    height: 6rem;
    border-radius: 50%;
    display: block;
    text-align: center;
    line-height: 6rem;
    font-style: normal;
    font-size: 4rem;
    color: $sidebar-background-color;
    background: $white-color;
}
.lpf-coach-comparison > .lpf-coach-comparison-content>.lpf-left>.lpf-content>.lpf-ul>li>.lpf-tt{
    width: calc(100% - 6rem);
    padding-left: 2rem;
}
.lpf-coach-comparison > .lpf-coach-comparison-content>.lpf-left>.lpf-content>.lpf-ul>li>.lpf-tt>a{
    background: url(https://www.dansewudao.com/skin/img/coach/index/pk_ttitle3.png) no-repeat;
    text-align: center;
    font-size: 1.8rem;
    color: $sidebar-background-color;
    line-height: 3rem;
        padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 4rem;
    padding-right: 4rem;
    height: 3rem;
}
.lpf-coach-comparison > .lpf-coach-comparison-content>.lpf-left>.lpf-content>.lpf-ul>li:nth-child(1)>.lpf-tt>a{
    background: url(https://www.dansewudao.com/skin/img/coach/index/pk_ttitle1.png) no-repeat;
}
.lpf-coach-comparison > .lpf-coach-comparison-content>.lpf-left>.lpf-content>.lpf-ul>li:nth-child(2)>.lpf-tt>a{
    background: url(https://www.dansewudao.com/skin/img/coach/index/pk_ttitle2.png) no-repeat;
}
.lpf-coach-comparison > .lpf-coach-comparison-content>.lpf-left>.lpf-content>.lpf-ul>li:nth-child(5)>.lpf-tt>a{
    background: url(https://www.dansewudao.com/skin/img/coach/index/pk_ttitle4.png) no-repeat;
}
.lpf-coach-comparison > .lpf-coach-comparison-content>.lpf-left>.lpf-content>.lpf-ul>li:nth-child(7)>.lpf-tt>a{
    background: url(https://www.dansewudao.com/skin/img/coach/index/pk_ttitle4.png) no-repeat;
}
.lpf-coach-comparison > .lpf-coach-comparison-content>.lpf-left>.lpf-content>.lpf-ul>li>.lpf-tt>p{
      font-size: 1.2rem;
    line-height: 2rem;
    color: $white-color;
    margin-top: 1rem;
}
.lpf-coach-comparison > .lpf-coach-comparison-content>.lpf-right{
  padding-left: 0!important;
}
.lpf-coach-comparison > .lpf-coach-comparison-content>.lpf-right>.lpf-content{
      background: $edu-background-color2;
      padding: 0 1rem;
}
.lpf-coach-comparison > .lpf-coach-comparison-content>.lpf-right>.lpf-content>h3{
    font-size: 3rem;
    color: $black-color;
    line-height: 10rem;
    text-align: center;
}
.lpf-coach-comparison > .lpf-coach-comparison-content>.lpf-right>.lpf-content>.lpf-ul{
 margin: 4.9rem auto 0;
}
.lpf-coach-comparison > .lpf-coach-comparison-content>.lpf-right>.lpf-content>.lpf-ul>li{
  margin-bottom: 5.8rem;
}
.lpf-coach-comparison > .lpf-coach-comparison-content>.lpf-right>.lpf-content>.lpf-ul>li:last-child{
  margin-bottom: 1rem;
}
.lpf-coach-comparison > .lpf-coach-comparison-content>.lpf-right>.lpf-content>.lpf-ul>li>img{
  margin-right: 1.5rem;
}
.lpf-coach-comparison > .lpf-coach-comparison-content>.lpf-right>.lpf-content>.lpf-ul>li>p{
    font-size: 1.3rem;
    line-height: 4rem;
    display: inline;
}
.lpf-coach-jxtd {
  background-color: $edu-color-h5;
  display: flex;
  flex-direction: column;
  align-items: center;
      margin-top: 10rem;
    margin-bottom: 10rem;
}
.lpf-coach-jxtd > .lpf-coach-jxtd-content {
  width: 100%;
  max-width: $body-width-content;
  height: auto;
  margin: 0;
  overflow: hidden;
}
.lpf-coach-jxtd > .lpf-coach-jxtd-content>.lpf-left{
  color: $black-color;
}
.lpf-coach-jxtd > .lpf-coach-jxtd-content>.lpf-left>h1{
      font-size: 5rem;
    margin-top: 7rem;
    margin-bottom: 4rem;
}
.lpf-coach-jxtd > .lpf-coach-jxtd-content>.lpf-left>p{
  font-size: 1.8rem;
    line-height: 3rem;
    margin-bottom: 2rem;
}
.lpf-coach-jxtd > .lpf-coach-jxtd-content>.lpf-right>.lpf-image{
  width: 100%;
  margin-top: 3rem;
}
@media (max-width: 1239px) {
  .lpf-coach-train
    > .lpf-coach-train-content
    > .lpf-train-intro
    > .lpf-right
    > .lpf-image {
    width: 100%;
    border-radius: 0;
    overflow: hidden;
    position: static;
  }
}
@media (max-width: 992px) {
  .lpf-coach-class > .lpf-coach-class-content > .lpf-li > .lpf-link-a {
    margin-top: 2rem;
    width: 49% !important;
    margin-right: 2% !important;
  }
  .lpf-coach-class
    > .lpf-coach-class-content
    > .lpf-li
    > .lpf-link-a:nth-child(3n) {
    margin-right: 2% !important;
  }
  .lpf-coach-class
    > .lpf-coach-class-content
    > .lpf-li
    > .lpf-link-a:nth-child(2n) {
    margin-right: 0 !important;
  }
  .lpf-coach-base > .lpf-coach-base-content > .lpf-li > .lpf-link-a {
    margin-top: 2rem;
    width: 49% !important;
    margin-right: 2% !important;
  }
  .lpf-coach-base
    > .lpf-coach-base-content
    > .lpf-li
    > .lpf-link-a:nth-child(3n) {
    margin-right: 2% !important;
  }
  .lpf-coach-base
    > .lpf-coach-base-content
    > .lpf-li
    > .lpf-link-a:nth-child(2n) {
    margin-right: 0 !important;
  }
}
@media (max-width: 767px) {
  .lpf-coach-comparison > .lpf-coach-comparison-content>.lpf-left{
  padding-right: 1.5!important;
}
.lpf-coach-comparison > .lpf-coach-comparison-content>.lpf-right{
  padding-left: 1.5!important;
}
}
@media (max-width: 650px) {
  .lpf-coach-base
    > .lpf-coach-base-content
    > .lpf-li
    > .lpf-link-a
    > .lpf-top
    > .lpf-left {
    margin-top: 1.5rem;
  }
}
@media (max-width: 560px) {
  .lpf-coach-base > .lpf-coach-base-content > .lpf-li > .lpf-link-a {
    margin-top: 2rem;
    width: 100% !important;
    margin-right: 0 !important;
  }
  .lpf-coach-class > .lpf-coach-class-content > .lpf-li > .lpf-link-a {
    margin-top: 2rem;
    width: 100% !important;
    margin-right: 0 !important;
  }
}
</style>
